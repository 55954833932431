import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Tooltip } from '@bloobirds-it/flamingo-ui';
import { copyHtmlToClipboard } from '@bloobirds-it/utils';
import clsx from 'clsx';

import styles from './copyButton.module.css';

interface ICopyButton {
  textToCopy: string;
  htmlFormat?: boolean;
  alwaysDisplay?: boolean;
}

export const CopyButton = ({
  htmlFormat = false,
  textToCopy,
  alwaysDisplay = false,
}: ICopyButton) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'copyText' });
  const [tooltipText, setTooltipText] = useState(t('copyToClipboard'));

  function handleOnClick() {
    setTooltipText(t('copied'));
    if (htmlFormat) {
      copyHtmlToClipboard(textToCopy);
    } else {
      const clipboardElement = new ClipboardItem({
        'text/plain': new Blob([`${textToCopy}`], {
          type: 'text/plain',
        }),
      });
      navigator.clipboard.write([clipboardElement]);
    }
  }

  return (
    <div
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => {
        setIsVisible(false);
        setTooltipText(t('copyToClipboard'));
      }}
      className={styles._copy_component}
    >
      <Tooltip title={tooltipText} position="top">
        <IconButton
          name="copy"
          className={clsx(styles._copy_icon, { [styles._show_icon]: isVisible || alwaysDisplay })}
          onClick={handleOnClick}
          size={16}
        />
      </Tooltip>
    </div>
  );
};
