export enum SentryTags {
  EmailError = 'sendEmailError',
  BBCallError = 'BBCallError',
  BBDialerError = 'BBDialerError',
  MeetingError = 'meetingError',
  WABusinessError = 'WABusinessError',
  SMSError = 'SMSError',
  LinkedInSyncError = 'linkedInSyncError',
  WhatsappWebSyncError = 'whatsappWebSyncError',
  UpdateTranscriptError = 'updateTranscriptError',
  CreateMessagingError = 'createMessagingError',
  UpdateMessagingError = 'updateMessagingError',
  DeleteMessagingError = 'deleteMessagingError',
}

export enum SentryMessages {
  EmailError = 'Failed to send email',
  BBCallError = 'Error while ongoing call',
  BBDialerError = 'Error while creating dialer device',
  MeetingError = 'Error while creating a meeting',
  WABusinessError = 'Failed to send WA Business message',
  SMSError = 'Failed to send SMS message',
  LinkedInSyncError = 'Failed to sync LinkedIn messages',
  WhatsappWebSyncError = 'Failed to sync Whatsapp Web messages',
  UpdateTranscriptError = 'Failed to update AI transcript',
  CreateMessagingError = 'Failed to create Messaging Template',
  UpdateMessagingError = 'Failed to update Messaging Template',
  DeleteMessagingError = 'Failed to delete Messaging Template',
}
