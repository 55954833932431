import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, Icon, Spinner, Tooltip, useVisible } from '@bloobirds-it/flamingo-ui';
import {
  useActiveUserId,
  useActiveUserSettings,
  useDataModel,
  useGetExtensionLeadById,
} from '@bloobirds-it/hooks';
import { BobjectId, Environment, UserPermission, UserRole } from '@bloobirds-it/types';
import {
  insertTextWhatsApp,
  handleAddWhatsAppTemplate,
  forgeIdFieldsFromIdValue,
} from '@bloobirds-it/utils';
import clsx from 'clsx';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';

import { useActiveMessagingNameFilter } from '../../hooks/useMessagingTemplates';
import { HandleTemplateModal } from '../handleTemplateModal/handleTemplateModal';
import { TemplateSelector } from './templateSelector';
import styles from './templatesSelector.module.css';

const SMSTemplateSelectorContent = ({
  leadId,
  companyId,
  setMessage,
  anchorProps,
}: SMSTemplateSelectorProps) => {
  const userId = useActiveUserId();
  const dataModel = useDataModel();
  const { settings } = useActiveUserSettings();
  const { visible, setVisible, ref } = useVisible(false);
  const { t, ready } = useTranslation('translation', { keyPrefix: 'templateSelector' });

  const [, setMessagingTemplateName] = useActiveMessagingNameFilter();
  const [editModal, setEditModal] = useState({ template: null, open: false });

  const bobjectIdObject: BobjectId = forgeIdFieldsFromIdValue(leadId);
  const { lead, isLoading } = useGetExtensionLeadById(bobjectIdObject);
  const stage = dataModel?.findValueById(lead?.stage);

  useEffect(() => {
    setMessagingTemplateName(null);
  }, []);

  const userName = settings?.user?.name;
  const userRoles = settings?.user?.roles;
  const userPermissions = settings?.user?.permissions;
  const isAdminUser =
    userRoles?.includes(UserRole.GLOBAL_ADMIN) || userRoles?.includes(UserRole.ACCOUNT_ADMIN);
  const hasEditAllPermissions = userPermissions?.includes(UserPermission.EDIT_ALL);

  const actionsDisabled =
    !isAdminUser && !hasEditAllPermissions && (userId !== lead?.assignedTo || !lead?.assignedTo);

  const handleAdd = ({
    id,
    fallbackContent,
    closeDropdown = true,
  }: {
    id: string;
    fallbackContent: any;
    closeDropdown?: boolean;
  }) =>
    handleAddWhatsAppTemplate({
      id,
      fallbackContent,
      lead,
      companyId,
      userName,
      callback: () => closeDropdown && setVisible(false),
    }).then(data => {
      if (data) {
        const openNewPage = false;
        if (setMessage) {
          setMessage(data?.data);
        } else {
          insertTextWhatsApp(
            openNewPage,
            '#main .copyable-area [contenteditable="true"][role="textbox"]',
            //@ts-ignore
            lead.phoneNumbers?.[0],
            data?.data,
          );
        }
        setVisible(false);
      }
    });

  const classnames = clsx(styles.whatsAppContainer, {
    [styles.whatsAppContainerDisabled]: actionsDisabled,
  });

  const anchorClassName = anchorProps?.className || classnames;

  const whatsappData = {
    phoneNumber: lead?.phoneNumbers?.[0],
    isSameActiveLead: true,
    userName: settings?.user?.name,
    lead,
  };

  useEffect(() => {
    if (editModal?.open) setVisible(false);
  }, [editModal?.open]);

  // Close dropdown when escape key is pressed
  useEffect(() => {
    const onKeyDown = event => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        setVisible(false);
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  if (isLoading || !lead) {
    return <Spinner name="loadingCircle" size={18} />;
  }

  return ready ? (
    <div className={styles.templateSelectorDropdown}>
      <Dropdown
        width={424}
        visible={visible}
        zIndex={20000}
        style={{ height: 620, padding: 0 }}
        portal
        anchor={
          <div
            id="badgeGroup"
            className={anchorClassName}
            onClick={() => !actionsDisabled && setVisible(!visible)}
          >
            {isLoading || !lead ? (
              <Spinner name="loadingCircle" color="softPeanut" size={12} />
            ) : (
              <Tooltip title={actionsDisabled && t('permissions')} position="top">
                {/* @ts-ignore This takes the styles of whatsapp */}
                <Icon name="bBTemplate" color={anchorProps?.color || 'icon'} />
              </Tooltip>
            )}
          </div>
        }
      >
        <div className={styles.templateSelectorWrapper} ref={ref}>
          <TemplateSelector
            environment={Environment.SMS_TEMPLATE_SELECTOR}
            lead={lead}
            handleAdd={handleAdd}
            setEditModal={setEditModal}
            whatsappData={whatsappData}
            closeDropdown={() => setVisible(false)}
          />
        </div>
      </Dropdown>
      {editModal?.open && (
        <HandleTemplateModal
          template={editModal?.template}
          handleClose={() => {
            setVisible(true);
            setEditModal({ template: null, open: false });
          }}
          contextValues={{
            onSaveCallback: () => {
              setVisible(true);
            },
            onDeleteCallback: () => {
              setVisible(true);
              setEditModal({ template: null, open: false });
            },
            ...(stage ? { stage } : {}),
          }}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

interface SMSTemplateSelectorProps {
  leadId: BobjectId['value'];
  companyId: BobjectId['value'];
  setMessage?: (message: string) => void;
  anchorProps?: {
    color: string;
    className;
  };
}

export const SMSTemplateSelector = (props: SMSTemplateSelectorProps) => {
  if (!props.leadId) {
    return null;
  }

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
      }}
    >
      <RecoilRoot key="bb-whatsapp-template-selector">
        <Suspense fallback={<Spinner name="loadingCircle" size={18} />}>
          <SMSTemplateSelectorContent {...props} />
        </Suspense>
      </RecoilRoot>
    </SWRConfig>
  );
};
