import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconType, Label } from '@bloobirds-it/flamingo-ui';
import { MetaValidationStatus } from '@bloobirds-it/types';

export const MetaValidationLabel = ({
  metaValidationStatus,
}: {
  metaValidationStatus: MetaValidationStatus;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'playbook.metaValidationStatus' });
  const labelSharedProps = {
    icon: 'meta' as IconType,
    size: 'small' as 'small' | 'medium',
    uppercase: false,
    overrideStyle: { display: 'flex', marginLeft: '4px' },
  };

  const getLabelByStatus = (status: MetaValidationStatus) => {
    switch (status) {
      case MetaValidationStatus.APPROVED:
        return (
          <Label color="verySoftMelon" textColor="melon" iconColor="melon" {...labelSharedProps}>
            {t('approved')}
          </Label>
        );
      case MetaValidationStatus.PENDING:
        return (
          <Label color="lightPeanut" textColor="peanut" iconColor="peanut" {...labelSharedProps}>
            {t('pending')}
          </Label>
        );
      case MetaValidationStatus.REJECTED:
        return (
          <Label
            color="lightestMeeting"
            textColor="extraMeeting"
            iconColor="extraMeeting"
            {...labelSharedProps}
          >
            {t('rejected')}
          </Label>
        );
      default:
        return null;
    }
  };

  if (!metaValidationStatus) return <></>;

  return <div>{getLabelByStatus(metaValidationStatus)}</div>;
};
