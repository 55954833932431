import { BobjectType, PluralBobjectTypes } from '@bloobirds-it/types';
import { api, injectReferencesSearchProcess } from '@bloobirds-it/utils';
import useSWR from 'swr';

const searchQuery = {
  page: 0,
  formFields: true,
  pageSize: 100,
  injectReferences: true,
  sort: [] as Array<string>,
};

export const fetchLeadsByRelatedBobject = (
  bobjectType: BobjectType,
  bobjectId: string,
  accountId: string,
) => {
  return api.post('/bobjects/' + accountId + '/Lead/search', {
    query: { [`LEAD__${bobjectType.toUpperCase()}`]: [bobjectId] },
    ...searchQuery,
  });
};

export const useGetBobjectByTypeAndId = (bobjectId: string, injectReferences = false) => {
  const { data, isValidating } = useSWR(bobjectId ? `/${bobjectId}/form` : null, () =>
    api.get(`/bobjects/${bobjectId}/form?injectReferences=${injectReferences}`),
  );
  return { bobject: data?.data, isValidating };
};

export const useBobject = (bobjectType: BobjectType, accountId: string) => {
  const searchForBobject = (queryParams: { [x: string]: any }) =>
    api.post(`/bobjects/${accountId}/${bobjectType}/search`, {
      query: queryParams,
      ...searchQuery,
    });

  const patchBobject = (bobjectId: string, data: any) =>
    api.patch(`/bobjects/${accountId}/${bobjectType}/${bobjectId}/raw`, data);

  const bulkPatchBobjects = (data: any) =>
    api.patch(`/bobjects/${accountId}/${bobjectType}/bulk`, data);

  return {
    searchForBobject,
    patchBobject,
    bulkPatchBobjects,
  };
};

export function useGetExtensionLeadById(bobjectIdFields) {
  const { data: lead, isLoading } = useSWR(
    bobjectIdFields && `/linkedin/leads/${bobjectIdFields?.objectId}`,
    async () =>
      api
        .get(
          `/linkedin/${PluralBobjectTypes[bobjectIdFields?.typeName]?.toLowerCase()}/${
            bobjectIdFields?.objectId
          }`,
        )
        .then(response => response.data),
  );

  if (!bobjectIdFields) {
    return { lead: null, isLoading: false };
  }
  return { lead, isLoading };
}

export function useGetLeadById(leadId, accountId) {
  const { data: lead } = useSWR(`/Lead/${leadId}/form`, async () =>
    api
      .get(`/bobjects/${accountId}/Lead/${leadId}/form`)
      .then(data => injectReferencesSearchProcess(data?.data)),
  );

  return { lead };
}

export function useGetOpportunityById(opportunityId, accountId) {
  const { data: opportunity } = useSWR(`/Opportunity/${opportunityId}/form`, async () =>
    api
      .get(`/bobjects/${accountId}/Opportunity/${opportunityId}/form`)
      .then(data => injectReferencesSearchProcess(data?.data)),
  );

  return { opportunity };
}
