import React, { Fragment, useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import { useTranslation } from 'react-i18next';

import { useCopilotActivity } from '@bloobirds-it/copilot';
import {
  Button,
  Icon,
  IconButton,
  IconType,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  SearchInput,
  Spinner,
  Text,
  Tooltip,
} from '@bloobirds-it/flamingo-ui';
import {
  useSearchInTranscript,
  useThirdPartyAnalytics,
  useUserSettings,
} from '@bloobirds-it/hooks';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  ActivityTypeNameByLogicRole,
  Bobject,
  MIXPANEL_EVENTS,
  SentryMessages,
  SentryTags,
  TranscriptFragment,
  TranscriptStatus,
  UserType,
} from '@bloobirds-it/types';
import { api, getFieldByLogicRole, getTextFromLogicRole } from '@bloobirds-it/utils';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import mixpanel from 'mixpanel-browser';

import calloutStyles from '../copilotSuggestionsCallout/copilotSuggestionsCallout.module.css';
import { NoResultsFound } from '../noResultsFound/noResultsFound';
import styles from './transcript.module.css';

interface IConversationBlockProps {
  fragment: TranscriptFragment;
  highlighter: (text: string) => string | (string | JSX.Element)[];
}
const ConversationBlock = ({ fragment, highlighter }: IConversationBlockProps) => {
  if (!fragment.text) return null;
  return (
    <div>
      <div className={styles.fragmentHeader}>
        <Text color="purple" size="xs" weight="bold">
          {/* @ts-ignore */}
          <Icon name={fragment.isLead ? 'person' : 'user'} color="purple" size={16} />
          {fragment.speaker} ({fragment.isLead ? 'lead' : 'user'})
        </Text>
      </div>

      <Text size="xxs">
        {highlighter(fragment.text)}
        <br />
      </Text>
    </div>
  );
};

export const EditTranscriptModal = ({
  activity,
  open,
  onClose,
}: {
  activity: Bobject;
  open: boolean;
  onClose: () => void;
}) => {
  const { transcript, reloadTranscript } = useCopilotActivity();
  const { sendThirdPartyError } = useThirdPartyAnalytics();
  const { t } = useTranslation();

  const [transcriptValue, setTranscriptValue] = useState(
    transcript?.transcript ? JSON.stringify(transcript?.transcript, null, 4) : '',
  );

  const updateTranscript = () => {
    api
      .patch(`/copilot/transcript/${activity?.id.objectId}`, {
        transcript: JSON.parse(transcriptValue),
      })
      .then(() => {
        mixpanel.track(MIXPANEL_EVENTS.UPDATE_TRANSCRIPT_SUCCESS);
        return reloadTranscript().then(onClose);
      })
      .catch(error => {
        sendThirdPartyError(
          { key: MIXPANEL_EVENTS.UPDATE_TRANSCRIPT_ERROR },
          {
            sentryTag: SentryTags.UpdateTranscriptError,
            sentryMessage: SentryMessages.UpdateTranscriptError,
          },
          {
            error,
            extra: {
              Activity: activity?.id?.value,
            },
          },
        );
      });
  };

  useEffect(() => {
    if (open && transcript?.transcript) {
      setTranscriptValue(JSON.stringify(transcript?.transcript, null, 4));
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} width={800}>
      <ModalHeader size="small" color="verySoftPurple">
        <ModalTitle variant="primary" size="small" icon="snippet">
          Edit transcription
        </ModalTitle>
        <ModalCloseIcon onClick={onClose} color="purple" size="small" />
      </ModalHeader>
      <ModalContent className={styles.content}>
        <AceEditor
          mode="json"
          width="680px"
          wrapEnabled={true}
          value={transcriptValue}
          onChange={value => setTranscriptValue(value)}
        />
      </ModalContent>
      <ModalFooter className={styles.footer}>
        <Button
          variant="clear"
          onClick={onClose}
          color="extraMeeting"
          uppercase={false}
          size="small"
        >
          {t('crmUpdatesModal.cancel')}
        </Button>
        <Button
          className={styles.accpetButton}
          onClick={updateTranscript}
          uppercase={false}
          variant="primary"
          size="small"
        >
          {t('crmUpdatesModal.accept')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const TranscriptView = ({ activity }: { activity: Bobject }) => {
  const { setOverlay, transcript } = useCopilotActivity();
  const { t } = useTranslation();

  const settings = useUserSettings();
  const canEdit = settings?.user.type === UserType.SUPPORT_USER;
  const [modalOpen, setModalOpen] = useState(false);

  const { searchTerm, filteredTranscript, handleSearchChange, highlightText } =
    useSearchInTranscript(transcript?.transcript);
  return (
    <>
      <div className={styles.backButton} onClick={() => setOverlay(undefined)}>
        <Icon name="arrowLeft" />
        <Text size="xs" color="bloobirds">
          {t('activityTimelineItem.item.transcript.backTo')}
        </Text>
      </div>

      <div className={styles.header}>
        <Icon name="snippet" color="purple" />
        <Text size="s" weight="bold" className={styles.transcriptHeader}>
          {t('activityTimelineItem.item.transcript.transcription')}{' '}
        </Text>
        {canEdit && !searchTerm && (
          <IconButton
            name="edit"
            color="purple"
            size={20}
            onClick={() => {
              setModalOpen(true);
              mixpanel.track(MIXPANEL_EVENTS.OPEN_EDIT_TRANSCRIPT_MODAL);
            }}
          />
        )}
        <SearchInput
          color="purple"
          size="small"
          width="100%"
          placeholder={t('ai.aiAnalysis.searchTranscriptTooltip')}
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <div className={styles.blocks}>
        {filteredTranscript.length === 0 ? (
          <NoResultsFound />
        ) : (
          filteredTranscript.map((fragment, index) => (
            <ConversationBlock key={index} fragment={fragment} highlighter={highlightText} />
          ))
        )}
      </div>

      <EditTranscriptModal
        activity={activity}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export const ShowTranscriptButton = ({
  activity,
  isBubble,
}: {
  activity: Bobject;
  isBubble: boolean;
}) => {
  const { setOverlay, transcript } = useCopilotActivity();
  const { t } = useTranslation();
  const dateTimeString = getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.DATETIME);
  const meetingDate = dateTimeString ? new Date(dateTimeString) : undefined;
  const type = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE)
    ?.valueLogicRole as ACTIVITY_TYPES_VALUES_LOGIC_ROLE;
  const isCall = type === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL;

  const transcriptStatusByMe: TranscriptStatus = 'GENERATED';
  //@ts-ignore
  const disabled = transcriptStatusByMe === 'CANT_GENERATE';
  //@ts-ignore
  const hasError = transcriptStatusByMe === 'ERROR';
  const generating = ['GENERATING', 'NOT_FINISHED'].includes(transcriptStatusByMe);

  const handleClick = () => {
    setOverlay('transcript');
    mixpanel.track(
      MIXPANEL_EVENTS.OPEN_IA_TRANSCRIPT_IN_EXTENDED_SCREEN_FOR_TYPE_ +
        ActivityTypeNameByLogicRole[type],
    );
  };

  if (!transcript || !meetingDate || meetingDate >= new Date()) {
    return <></>;
  }
  return (
    <div
      style={{ cursor: !hasError && !generating && !disabled ? 'pointer' : 'not-allowed' }}
      className={clsx(calloutStyles.calloutButton, {
        [calloutStyles.disabled]: disabled,
        [calloutStyles.smallButton]: isBubble,
        [calloutStyles.hasError]: hasError,
      })}
      onClick={() =>
        !hasError &&
        !disabled &&
        !generating &&
        transcriptStatusByMe === 'GENERATED' &&
        handleClick()
      }
    >
      <Tooltip title={generateTooltip(transcriptStatusByMe, t, isCall)} position="top">
        {generating && <Spinner name="loadingCircle" color="purple" size={20} />}
        {!generating && (
          <Icon
            name={getIcon(transcriptStatusByMe)}
            size={24}
            color={disabled ? 'verySoftPeanut' : hasError ? 'softTomato' : 'purple'}
          />
        )}

        <Text
          size={isBubble ? 'xxs' : 'xs'}
          color={disabled ? 'verySoftPeanut' : hasError ? 'softTomato' : 'peanut'}
          weight="medium"
        >
          {generateText(transcriptStatusByMe, t)}
        </Text>
      </Tooltip>
    </div>
  );
};
const generateText = (
  status: TranscriptStatus | undefined,
  t: TFunction<'translation', undefined>,
) => {
  switch (status) {
    case 'GENERATED':
    case 'NOT_FINISHED':
    case 'CANT_GENERATE':
    case 'ERROR':
      return t('common.transcript');
    case 'NOT_GENERATED':
      return t('activityTimelineItem.item.transcript.generate');
    case 'GENERATING':
      return t('common.generating') + '...';
    default:
      return '';
  }
};

const getIcon = (status: TranscriptStatus): IconType => {
  switch (status) {
    case 'GENERATED':
    case 'CANT_GENERATE':
      return 'snippet';
    case 'NOT_GENERATED':
      return 'play';
    case 'ERROR':
      return 'cancel';
    default:
      return '' as IconType;
  }
};
const generateTooltip = (
  status: TranscriptStatus,
  t: TFunction<'translation', undefined>,
  isCall: boolean,
) => {
  switch (status) {
    case 'NOT_FINISHED':
      return t(
        `activityTimelineItem.item.transcript.meetingProcessed.${isCall ? 'call' : 'meeting'}`,
      );
    case 'CANT_GENERATE':
    case 'ERROR':
      return isCall
        ? t('activityTimelineItem.item.transcript.cantGenerateCall')
        : t('activityTimelineItem.item.transcript.cantGenerateMeeting');
    case 'NOT_GENERATED':
      return t('activityTimelineItem.item.transcript.generate');
    case 'GENERATING':
      return t('activityTimelineItem.item.transcript.generating');
    case 'GENERATED':
      return t(`activityTimelineItem.item.transcript.${isCall ? 'showCall' : 'showMeeting'}`);
    default:
      return '';
  }
};
