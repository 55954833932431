import { deflate, inflate } from 'pako';

function compressData(data) {
  const compressedData = deflate(data, { to: 'string' }); // Comprimir la cadena
  return btoa(compressedData); // Codificar en base64 para almacenamiento
}

function decompressData(compressedData) {
  const decodedData = atob(compressedData); // Decodificar base64
  const numbers = decodedData.split(',').map(Number); // Divide y convierte a enteros
  const uint8Array = new Uint8Array(numbers);
  const decompressedData = inflate(uint8Array, { to: 'string' }); // Descomprimir
  return JSON.parse(decompressedData); // Parsear el JSON original
}

const chunkedWrite = (prefix, key, value) => {
  return new Promise(resolve => {
    if (typeof key !== 'string') key = `${key}`;
    value = compressData(value);
    const len = 6200;
    const num = Math.ceil(value.length / len);
    const obj = {};
    const objNum = {
      id: key,
      num,
    };
    if (prefix) {
      const prefixKey = `${prefix}_${key}`;
      obj[prefixKey] = JSON.stringify(objNum);
    } else {
      obj[key + '#'] = JSON.stringify(objNum);
    }
    for (let i = 0; i < num; i++) {
      obj[key + i] = value.substr(i * len, len);
    }
    chrome.storage?.sync?.set(obj, () => {
      if (
        chrome.runtime.lastError &&
        chrome.runtime.lastError.message === 'QUOTA_BYTES quota exceeded'
      ) {
        // Save in local storage if sync storage is full
        console.log('Sync storage is full, saving in local storage', obj);
        if (localStorage) {
          localStorage.setItem(`${prefix}_${key}`, value);
        }
      }
      return resolve(obj);
    });
  });
};

const chunkedRead = (prefix, key) => {
  return new Promise((resolve, reject) => {
    if (typeof key !== 'string') key = `${key}`;
    let keyNum = key + '#';
    if (prefix) {
      keyNum = `${prefix}_${key}`;
    }
    if (!chrome.storage.sync) {
      return reject('Storage not available');
    }
    chrome.storage.sync.get(keyNum, data => {
      if (chrome.runtime.lastError) {
        return reject(chrome.runtime.lastError);
      }
      const num = JSON.parse(data[keyNum]).num;
      const keys = [];
      for (let i = 0; i < num; i++) {
        keys[i] = key + i;
      }
      chrome.storage.sync.get(keys, data => {
        if (chrome.runtime.lastError) {
          return reject(chrome.runtime.lastError);
        }
        const chunks = [];
        for (let i = 0; i < num; i++) {
          if (!data[key + i]) {
            return reject('Chunk not found');
          } else {
            const value = data[key + i];
            chunks.push(value || '');
          }
        }
        const str = chunks.join('');
        const decompressedData = str ? decompressData(str) : undefined;
        resolve(
          decompressedData
            ? typeof decompressedData === 'object'
              ? decompressedData
              : JSON.parse(decompressedData)
            : undefined,
        );
      });
    });
  });
};

const deleteStorage = (key, keyNum, resolve, reject) => {
  if (!chrome.storage.sync) {
    return reject('Storage not available');
  }
  chrome.storage.sync.get(keyNum, data => {
    if (chrome.runtime.lastError) {
      return reject(chrome.runtime.lastError);
    }
    const num = JSON.parse(data[keyNum]).num;
    const keys = [keyNum];
    for (let i = 0; i < num; i++) {
      keys.push(key + i);
    }
    chrome.storage.sync.remove(keys, () => {
      if (chrome.runtime.lastError) {
        return reject(chrome.runtime.lastError);
      }
      resolve(keys);
    });
  });
};

const chunkedDelete = (prefix, key) => {
  return new Promise((resolve, reject) => {
    if (typeof key !== 'string') key = `${key}`;
    let keyNum = key + '#';
    if (prefix) {
      keyNum = `${prefix}_${key}`;
    }

    if (chrome.storage?.sync) {
      deleteStorage(key, keyNum, resolve, reject);
    }
    if (chrome.storage?.local) {
      deleteStorage(key, keyNum, resolve, reject);
    }
  });
};

export { chunkedWrite, chunkedRead, chunkedDelete };
