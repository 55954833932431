import { ColorType, IconType } from '@bloobirds-it/flamingo-ui';

import { PlaybookTab } from './contactView';
import { MessagingTemplate } from './messaging';
import { TemplateStage } from './templates';
import { TeamType } from './userTeams';

export interface SegmentationFieldValue {
  id: string;
  name: string;
  logicRole: string;
  backgroundColor: ColorType;
  outlineColor: ColorType;
  textColor: ColorType;
  isNurturing: boolean;
  isEnabled: boolean;
  ordering: number;
}

export interface SegmentationEntity {
  id: string;
  name: string;
  logicRole: string;
  reportingColumnName: string;
  fieldType: string;
  layoutIcon: IconType;
  values: [SegmentationFieldValue];
  referencesTo: any;
  required: boolean;
  inboundField: boolean;
  isTemplateVariable: boolean;
  ordering: number;
}

export interface SegmentationData {
  [TemplateStage.Prospecting]: SegmentationEntity[];
  [TemplateStage.Sales]: SegmentationEntity[];
}

export enum Environment {
  SMART_EMAIL = 'SMART_EMAIL',
  EXTENSION = 'EXTENSION',
  DIALER = 'DIALER',
  LINKEDIN_TEMPLATE_SELECTOR = 'LINKEDIN_TEMPLATE_SELECTOR',
  WHATSAPP_TEMPLATE_SELECTOR = 'WHATSAPP_TEMPLATE_SELECTOR',
  META_TEMPLATE_SELECTOR = 'META_TEMPLATE_SELECTOR',
  SMS_TEMPLATE_SELECTOR = 'SMS_TEMPLATE_SELECTOR',
}

export interface UseMessagingTemplatesOptions {
  segmentationValues: any;
  stage?: TemplateStage;
  type: string;
  name: string;
  visibility: string;
  showCadencesTemplates?: boolean;
  onlyMine: boolean;
  onlyOfficials?: boolean;
  onlyBattlecards?: boolean;
  metaApproved?: boolean;
  teams: TeamType[];
}

export interface MessagingResponseV2 {
  teamTemplates: TemplateData;
  myTemplates: TemplateData;
}

export interface TemplateData {
  messagingTemplates: MessagingTemplate[];
  hasNextPage: boolean;
  paginationProps?: PaginationProps;
}

export interface PaginationProps {
  page: number;
  rowsPerPage: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (size: number) => void;
}

export interface UseMessagingReturnType {
  messagingTemplates: MessagingTemplate[];
  teamTemplates?: TemplateData;
  myTemplates?: TemplateData;
  isLoading: boolean;
  isError: boolean;
}

export interface PaginationFetcherType {
  myTemplatesPage: number;
  myTemplatesPageSize: number;
  teamTemplatesPage: number;
  teamTemplatesPageSize: number;
}

type TemplateFunction = (template: MessagingTemplate) => void;

export interface PlaybookButtonProps {
  tooltipText: string;
  buttonText?: string;
  name: IconType;
  color: ColorType;
  onClick: (template: any) => void;
  disabled?: boolean;
}

export interface MessagingCardProps {
  template: MessagingTemplate;
  onClick?: (template: any) => void;
  tabSelected: PlaybookTab;
  isSmartEmail?: boolean;
  buttonProps?: PlaybookButtonProps[];
  templateFunctions?: Record<
    'replaceTemplate' | 'insertTemplate' | 'editTemplate',
    TemplateFunction
  >;
  actionsDisabled?: boolean;
  sidePeekEnabled?: boolean;
}

export interface PlaybookCardProps extends MessagingCardProps {
  onUpdateQQ?: (template: any) => void;
  QQValue?: string | Array<string>;
  refreshActiveBobject?: () => void;
}
