import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';

import { CheckItem, Item, MultiSelect, Select, Spinner, Text } from '@bloobirds-it/flamingo-ui';
import {
  useIsNoStatusPlanAccount,
  usePlaybookSegmentation,
  useUserTeams,
} from '@bloobirds-it/hooks';
import { SendToMetaBlock, DuplicateMetaTemplate } from '@bloobirds-it/playbook';
import { TEMPLATE_FORMS_ROUTES, TemplateStage, UserPermission } from '@bloobirds-it/types';
import { toSentenceCase } from '@bloobirds-it/utils';

import { ControlledSwitch } from '../../../../components/controlledSwitch/controlledSwitch.view';
import {
  MessagingTemplateSidebar,
  MessagingTemplateSidebarSection,
} from '../../../../components/messagingTemplates/messagingTemplateSidebar/messagingTemplateSidebar';
import { useUserSettings } from '../../../../components/userPermissions/hooks';
import { useActiveUser } from '../../../../hooks';
import { useFullSalesEnabled } from '../../../../hooks/useFeatureFlags';
import { useIsAccountAdmin } from '../../../../hooks/usePermissions';
import { LoadingSpinner } from '../../../../pages/homePage/pages/leftContent/utils/utils';
import { TEMPLATE_TYPES } from '../../../../utils/templates.utils';
import styles from '../../messagingSectionLayout.module.css';

const SegmentationMultiSelect = ({ value, label, options, ...props }) => (
  <div style={{ marginTop: 8 }}>
    <MultiSelect
      {...props}
      width="100%"
      sortByChecked={false}
      placeholder={value?.length > 0 ? label : `All ${label}`}
      label={label}
      value={value}
      autocomplete={options?.length > 8}
      selectAllOption
    >
      {options.map(option => (
        <CheckItem hidden={!option.isEnabled} key={option.id} value={option.id} label={option.name}>
          {option.name}
        </CheckItem>
      ))}
    </MultiSelect>
  </div>
);

const MessagingSidebarSettings = ({
  mode,
  templateId,
  templateName,
  templateType,
  templateOwner,
  isMetaSent,
}) => {
  const { control, watch } = useFormContext();
  const { activeUser } = useActiveUser();
  const settings = useUserSettings();
  const isFullSalesEnabled = useFullSalesEnabled();
  const isNoStatusPlanAccount = useIsNoStatusPlanAccount();
  const isAdmin = useIsAccountAdmin();
  const hasSendingMetaPermissions = settings?.user?.permissions?.includes(
    UserPermission.WHATSAPP_BUSINESS_TEMPLATE_ADMIN,
  );

  const { teams, isLoadingTeams, isAdminOrManager } = useUserTeams();

  const selectedStage = watch('stage');
  const selectedVisibility = watch('visibility');
  const selectedTeams = watch('teams');
  const { segmentationFields, isLoading } = usePlaybookSegmentation(selectedStage);
  const isTheOwner = activeUser.id === templateOwner || !templateOwner;
  const isCloning = mode === 'CLONE';
  const canEdit = isTheOwner || isAdmin || isCloning;

  const location = useLocation();

  const duplicateAction = () => {
    const url = `${TEMPLATE_FORMS_ROUTES[templateType]}?mode=CLONE&id=${templateId}&from=${location.pathname}`;
    window.open(url);
  };

  return (
    <MessagingTemplateSidebar>
      {hasSendingMetaPermissions &&
        templateType === TEMPLATE_TYPES.WHATSAPP &&
        (isMetaSent ? (
          <DuplicateMetaTemplate duplicateAction={duplicateAction} templateName={templateName} />
        ) : (
          <SendToMetaBlock />
        ))}
      {!isNoStatusPlanAccount && (
        <MessagingTemplateSidebarSection
          hidden={!isFullSalesEnabled}
          title="Stage"
          description="Stage enables to easily use your templates on the company or opportunity view depending on the sales stage you are working"
        >
          <Controller
            name="stage"
            control={control}
            defaultValue={
              isNoStatusPlanAccount
                ? TemplateStage.All
                : isFullSalesEnabled
                  ? TemplateStage.Sales
                  : TemplateStage.Prospecting
            }
            as={
              <Select>
                <Item value="ALL">Prospect and Sales stage</Item>
                <Item value="PROSPECT">Prospect stage</Item>
                <Item value="SALES">Sales stage</Item>
              </Select>
            }
          />
        </MessagingTemplateSidebarSection>
      )}
      <MessagingTemplateSidebarSection title="Options" description="Can choose more than one">
        <div className={styles.options_container}>
          {templateType === 'QUALIFYING_QUESTION' && (
            <div className={styles.switchQuestions}>
              <ControlledSwitch
                control={control}
                name="isRequiredBeforeMeeting"
                infoButton
                tooltip="When selecting this option, QQs will appear only on meetings with Companies, Leads or Opportunities that match the chosen categorization"
              >
                Required to close the meeting?
              </ControlledSwitch>
              <ControlledSwitch control={control} name="isLayoutReadOnly">
                Visible in forms?
              </ControlledSwitch>
            </div>
          )}
          {templateType !== 'QUALIFYING_QUESTION' && (
            <>
              <ControlledSwitch
                control={control}
                name="visibility"
                disabled={!canEdit}
                tooltip={canEdit ? '' : 'You can’t make private a template not owned by you'}
              >
                Create a public template
              </ControlledSwitch>
              <ControlledSwitch
                control={control}
                name="isOfficial"
                disabled={!isAdmin}
                tooltip={isAdmin ? '' : 'Only the account admins can set the Official Playbook'}
              >
                Official Playbook Template
              </ControlledSwitch>
            </>
          )}
          {[TEMPLATE_TYPES.SNIPPET, TEMPLATE_TYPES.PITCH].includes(templateType) && (
            <ControlledSwitch
              control={control}
              name="isBattlecard"
              disabled={!canEdit}
              tooltip={canEdit ? '' : 'You can’t edit a template not owned by you'}
            >
              Playbook Battlecard
            </ControlledSwitch>
          )}
        </div>
      </MessagingTemplateSidebarSection>
      {isAdminOrManager && (
        <MessagingTemplateSidebarSection
          title="Team"
          description="Assign templates to a team/s enables team members to easily search, filter and use templates"
        >
          <Controller
            name="teams"
            placeholder="All teams"
            disabled={isLoadingTeams || !selectedVisibility}
            control={control}
            as={
              <MultiSelect
                width="100%"
                sortByChecked={false}
                placeholder={'All teams'}
                label="Teams"
                value={selectedTeams}
                autocomplete={teams?.length > 8}
                selectAllOption
              >
                {isLoadingTeams ? (
                  <LoadingSpinner />
                ) : (
                  teams?.map(team => (
                    <CheckItem key={team.id} value={team.id} label={team.name}>
                      {team.name}
                    </CheckItem>
                  ))
                )}
              </MultiSelect>
            }
          />
        </MessagingTemplateSidebarSection>
      )}

      <MessagingTemplateSidebarSection
        title="Categorization"
        description="Categorizing enables you to easily filter your templates when contacting a lead."
      >
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner name="loadingCircle" size={24} />
          </div>
        ) : (
          segmentationFields &&
          Object.entries(segmentationFields).map(([stage, messagingFilters]) => (
            <div key={`segmantation-${stage}`} className={styles.segmentationBlock}>
              {selectedStage === 'ALL' && !isNoStatusPlanAccount && (
                <Text size="xs" color="softPeanut">
                  {toSentenceCase(stage.toLowerCase())}
                </Text>
              )}
              {messagingFilters.map(filter => (
                <Controller
                  control={control}
                  key={filter.id}
                  name={`segmentationValues.${stage}.${filter.id}`}
                  as={<SegmentationMultiSelect options={filter.values} label={filter.name} />}
                />
              ))}
            </div>
          ))
        )}
      </MessagingTemplateSidebarSection>
    </MessagingTemplateSidebar>
  );
};

export default MessagingSidebarSettings;
