import React, { useCallback, useState } from 'react';

import { CopilotSummary } from '@bloobirds-it/copilot';
import { Icon, Text } from '@bloobirds-it/flamingo-ui';
import {
  useActiveAccountId,
  useActivityUserMentionsEnabled,
  useCopilotEnabled,
  useUserMentions,
  useUserSettings,
  useTransformExtensionObjectToMainNotes,
} from '@bloobirds-it/hooks';
import {
  deserialize,
  EditorToolbar,
  EditorToolbarFontStylesSection,
  EditorToolbarListsSection,
  EditorToolbarTextMarksSection,
  MyEditor,
  RichTextEditor,
  useRichTextEditorPlugins,
} from '@bloobirds-it/rich-text-editor';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  ActivityInsights,
} from '@bloobirds-it/types';
import {
  api,
  createParagraph,
  getFieldByLogicRole,
  getMainBobjectIcon,
  getTextFromLogicRole,
  isHtml,
} from '@bloobirds-it/utils';
import { insertElements } from '@udecode/plate';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import useSWR from 'swr';

import { useStatusNoteActionContext } from '../../hooks/useStatusNoteActions';
import { ColumnHeader } from '../common/columnHeader';
import styles from './noteColumn.module.css';

const BOBJECT_NOTE_MODE = 'BOBJECT_NOTE_MODE';
const CALL_NOTE_MODE = 'CALL_NOTE_MODE';

export const NoteColumn = () => {
  const [noteMode, changeNoteMode] = useState(CALL_NOTE_MODE);
  const {
    note,
    setNote,
    loading,
    saveNote,
    hasSaved,
    mainNote,
    setMainNote,
    mainNoteLoading,
    saveMainNote,
    hasSavedMainNote,
    activity,
    t,
    bobject,
    machineContext,
  } = useStatusNoteActionContext();
  const [editor, setEditor] = useState<MyEditor | null>(null);
  const [mainNoteEditor, setMainNoteEditor] = useState<MyEditor | null>(null);
  const settings = useUserSettings();
  const isCopilotEnabled = useCopilotEnabled(settings?.account?.id);
  const copilotAnalysis = getTextFromLogicRole(
    activity,
    ACTIVITY_FIELDS_LOGIC_ROLE.COPILOT_ANALYSIS,
  );
  const hasAi = !!copilotAnalysis;

  const { data: activityInsights } = useSWR(
    hasAi && `/copilot/transcript/insights/${activity?.id?.objectId}`,
    key => api.get<ActivityInsights>(key).then(res => res.data),
  );

  const accountId = useActiveAccountId();
  const mentionsEnabled = useActivityUserMentionsEnabled(accountId);
  const plugins = useRichTextEditorPlugins({
    images: false,
    replaceParagraphs: true,
    userMentions: mentionsEnabled,
    elements: true,
  });
  const mainNotesEnabled = useTransformExtensionObjectToMainNotes(accountId);

  const activityType = getFieldByLogicRole(
    activity,
    ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
  )?.valueLogicRole;
  const isCallActivity = activityType === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL;
  const activityTypeString = isCallActivity ? t('noteColumn.call') : t('noteColumn.meeting');
  const debouncedSaveNote = useCallback(debounce(saveNote, 1000), []);
  const debouncedSaveMainNote = useCallback(debounce(saveMainNote, 1000), []);
  const users = useUserMentions();

  const getDeserialize = (message, plugins) => {
    if (message) {
      return typeof message === 'string'
        ? isHtml(message)
          ? deserialize(message, {
              format: 'HTML',
              plugins: plugins,
            })
          : createParagraph(message)
        : message;
    }

    return null;
  };

  const deserializedNote = getDeserialize(note, plugins);
  const deserializedMainNote = getDeserialize(mainNote, plugins);

  const classes = clsx(styles.noteColumn, {
    [styles.withCopilot]: hasAi,
    [styles.withoutCopilot]: !hasAi,
  });

  return (
    <div className={classes}>
      <ColumnHeader
        icon="noteAction"
        text={
          mainNotesEnabled
            ? t('noteColumn.headerMainNote')
            : t('noteColumn.header', { activityType: activityTypeString })
        }
        loading={loading || mainNoteLoading}
      >
        {(hasSaved || hasSavedMainNote) && (
          <div style={{ display: 'flex' }}>
            <Icon name="check" color="softPeanut" size={16} />
            <Text size="s" color="softPeanut">
              {t('header.saved')}
            </Text>
          </div>
        )}
      </ColumnHeader>
      {isCopilotEnabled && (
        <CopilotSummary
          activityInsights={activityInsights}
          onAddToNote={() => {
            const summaryAsNote = getDeserialize(activityInsights?.summary, plugins);

            if (noteMode === CALL_NOTE_MODE) {
              insertElements(editor, summaryAsNote);
            } else {
              insertElements(mainNoteEditor, summaryAsNote);
            }
            saveNote();
          }}
        />
      )}
      {mainNotesEnabled ? (
        <div className={styles.noteModeSelector}>
          <div
            className={styles.noteMode}
            style={{
              backgroundColor: noteMode === CALL_NOTE_MODE ? 'var(--bloobirds)' : 'var(--white)',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
            }}
            onClick={() => {
              changeNoteMode(CALL_NOTE_MODE);
            }}
          >
            <Icon
              name="phone"
              size={14}
              color={noteMode === CALL_NOTE_MODE ? 'white' : 'bloobirds'}
            />
            <Text size="xs" color={noteMode === CALL_NOTE_MODE ? 'white' : 'bloobirds'}>
              {t('noteColumn.activityNote', { activityType: activityTypeString })}
            </Text>
          </div>
          <div
            className={styles.noteMode}
            style={{
              backgroundColor: noteMode === BOBJECT_NOTE_MODE ? 'var(--bloobirds)' : 'var(--white)',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
            onClick={() => {
              changeNoteMode(BOBJECT_NOTE_MODE);
            }}
          >
            <Icon
              name={getMainBobjectIcon((bobject || machineContext?.referenceBobject)?.id?.typeName)}
              size={14}
              color={noteMode === BOBJECT_NOTE_MODE ? 'white' : 'bloobirds'}
            />
            <Text size="xs" color={noteMode === BOBJECT_NOTE_MODE ? 'white' : 'bloobirds'}>
              {t(`noteColumn.${bobject?.id?.typeName?.toLowerCase()}Note`)}
            </Text>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {noteMode === CALL_NOTE_MODE ? (
        <RichTextEditor
          id={`call-note-${activity.id.objectId}`}
          users={users?.users}
          defaultValue={deserializedNote}
          plugins={plugins}
          value={deserializedNote}
          placeholder={t('noteColumn.placeholder')}
          setEditor={(value: MyEditor) => {
            setEditor(value);
          }}
          onChange={value => {
            setNote(value);
            debouncedSaveNote();
          }}
          style={{
            padding: '0',
            height: '100%',
          }}
        >
          {editor => (
            <>
              <div className={styles.body_wrapper}>{editor}</div>
              <div className={styles.toolbar}>
                {/* @ts-ignore */}
                <EditorToolbar backgroundColor="var(--peanut) !important">
                  <EditorToolbarTextMarksSection color="peanut" />
                  <EditorToolbarListsSection color="peanut" />
                  <EditorToolbarFontStylesSection color="peanut" />
                </EditorToolbar>
              </div>
            </>
          )}
        </RichTextEditor>
      ) : (
        <RichTextEditor
          id={`bobject-note-${bobject?.id?.objectId}`}
          users={users?.users}
          defaultValue={deserializedMainNote}
          plugins={plugins}
          value={deserializedMainNote}
          placeholder={t('noteColumn.placeholder')}
          setEditor={(value: MyEditor) => {
            setMainNoteEditor(value);
          }}
          onChange={value => {
            setMainNote(value);
            debouncedSaveMainNote();
          }}
          style={{
            padding: '0',
            height: '100%',
          }}
        >
          {editor => (
            <>
              <div className={styles.body_wrapper}>{editor}</div>
              <div className={styles.toolbar}>
                {/* @ts-ignore */}
                <EditorToolbar backgroundColor="var(--peanut) !important">
                  <EditorToolbarTextMarksSection color="peanut" />
                  <EditorToolbarListsSection color="peanut" />
                  <EditorToolbarFontStylesSection color="peanut" />
                </EditorToolbar>
              </div>
            </>
          )}
        </RichTextEditor>
      )}
    </div>
  );
};
