import { TeamMember, TeamType, User, UserRole, UserTeamRole } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { AxiosResponse } from 'axios';
import useSWR, { KeyedMutator } from 'swr';

import { useActiveUserSettings } from './useActiveUser';
import { useUserSearch } from './useUserSearch';

type TeamSortGroupType = {
  field: string;
  order: 'ASC' | 'DESC';
};

interface IUseUserTeams {
  getTeamsByUserId: (id: string) => string;
  getTeamsByUserIdComplete: (id: string) => TeamType[];
  isLoadingTeams: boolean;
  isAdminOrManager: boolean;
  managerOfTeams: (id: string) => number;
  mutate: KeyedMutator<AxiosResponse<any>>;
  noTeams: boolean;
  selectAllValue: string[];
  teamUsersAggregation: number;
  teamlessUsers: User[];
  teams: TeamType[];
  teamsAggregation: number;
}

export enum TeamSortValues {
  FromAZ = 'fromAZ',
  FromZA = 'fromZA',
  LastUpdateRecent = 'lastUpdateRecent',
  LastUpdateOldest = 'lastUpdateOldest',
}

export const TeamSortGroups: Record<TeamSortValues, TeamSortGroupType> = {
  [TeamSortValues.FromAZ]: { field: 'name', order: 'ASC' },
  [TeamSortValues.FromZA]: { field: 'name', order: 'DESC' },
  [TeamSortValues.LastUpdateOldest]: { field: 'updateDatetime', order: 'ASC' },
  [TeamSortValues.LastUpdateRecent]: { field: 'updateDatetime', order: 'DESC' },
};
const fetchTeams = ([url]) => {
  return api.get(url);
};
export const useUserTeams = (onlyMine = false, sort = TeamSortValues.FromAZ): IUseUserTeams => {
  const { settings } = useActiveUserSettings();
  const userRoles = settings?.user?.roles;
  const activeUserId = settings?.user?.id;
  const {
    data,
    isLoading: isLoadingTeams,
    mutate,
  } = useSWR(
    [
      '/utils/teamUser/list' +
        '?orderBy=' +
        TeamSortGroups[sort]?.field +
        '&direction=' +
        TeamSortGroups[sort]?.order +
        (onlyMine ? '&onlyMine=true' : ''),
    ],
    fetchTeams,
  );

  const users = useUserSearch();
  const teams: Array<TeamType> = data?.data;

  const managerOfTeams: (id: string) => number = id => {
    return teams?.filter(t =>
      t?.teamUsers?.find(u => u?.userId === id && u?.userRole === UserTeamRole.Manager),
    )?.length;
  };

  const getTeamsByManagerId = (id: string) => {
    const teamsFromManager = teams?.filter(team =>
      team?.teamUsers?.find(
        (user: TeamMember) => user?.userId === id && user?.userRole === UserTeamRole.Manager,
      ),
    );

    return teamsFromManager?.length > 0 ? teamsFromManager : undefined;
  };

  const getTeamsByUserId = (id: string) => {
    const teamsFromUser = teams?.filter(team =>
      team?.teamUsers?.find((user: TeamMember) => user?.userId === id),
    );

    return teamsFromUser?.length > 0 ? teamsFromUser?.map(t => t?.name)?.join(', ') : undefined;
  };

  const getTeamsByUserIdComplete = (id: string) => {
    const teamsResponse = teams?.filter(team =>
      team?.teamUsers?.find((user: TeamMember) => user?.userId === id),
    );
    return teamsResponse?.length > 0 ? teamsResponse : undefined;
  };

  const isManagerById = (id: string) => {
    return getTeamsByManagerId(id)?.length > 0;
  };

  const teamsAggregation = teams?.length;
  const teamUsersAggregation =
    teams?.reduce((acc, team) => {
      return acc + (team?.teamUsers?.length || 0);
    }, 0) + teamsAggregation;

  const selectAllValue: string[] = (teams || [])?.reduce((acc, curr) => {
    acc.push(curr.id);
    curr.teamUsers.forEach(user => {
      acc.push(user.userId);
    });
    return acc;
  }, []);

  const usersInATeamSet = new Set(selectAllValue);
  const teamlessUsers = users?.users?.filter(user => !usersInATeamSet.has(user.id)) || [];
  const isAdminUser =
    userRoles?.includes(UserRole.GLOBAL_ADMIN) || userRoles?.includes(UserRole.ACCOUNT_ADMIN);
  const isManager = isManagerById(activeUserId);
  const managerTeams = getTeamsByManagerId(activeUserId);
  const teamsToDisplay = isAdminUser ? teams : isManager ? managerTeams : [];

  return {
    isLoadingTeams,
    isAdminOrManager: isAdminUser || isManager,
    teams: teamsToDisplay,
    noTeams: teams?.length === 0,
    mutate,
    getTeamsByUserId,
    getTeamsByUserIdComplete,
    managerOfTeams,
    teamsAggregation,
    teamUsersAggregation,
    selectAllValue: [...usersInATeamSet],
    teamlessUsers: isAdminUser ? teamlessUsers : [],
  };
};
