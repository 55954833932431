import { useRef, useState } from 'react';

import { createToast } from '@bloobirds-it/flamingo-ui';
import {
  useActiveAccountId,
  useActivityUserMentionsEnabled,
  useDebouncedCallback,
} from '@bloobirds-it/hooks';
import { getMentionsFromNote } from '@bloobirds-it/notes';
import { deserialize, serialize, useRichTextEditorPlugins } from '@bloobirds-it/rich-text-editor';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_MAIN_NOTE_VALUES_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  BobjectTypes,
  COMPANY_FIELDS_LOGIC_ROLE,
  LEAD_FIELDS_LOGIC_ROLE,
  MainBobjectTypes,
  MessagesEvents,
  OPPORTUNITY_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';
import { api, getTextFromLogicRole, isHtml } from '@bloobirds-it/utils';
import { useWizardContext } from '@bloobirds-it/wizard-modal-context';

const mainNoteField = {
  [BobjectTypes.Company]: COMPANY_FIELDS_LOGIC_ROLE.MAIN_NOTE,
  [BobjectTypes.Lead]: LEAD_FIELDS_LOGIC_ROLE.MAIN_NOTE,
  [BobjectTypes.Opportunity]: OPPORTUNITY_FIELDS_LOGIC_ROLE.MAIN_NOTE,
};
const updateMainNoteInRelated = (related: string, relatedBobjectType: string, value?: string) => {
  if (related && relatedBobjectType) {
    api.patch(`/bobjects/${related}/raw`, {
      contents: {
        [mainNoteField[relatedBobjectType]]: value ? [value] : null,
      },
      params: { duplicateValidation: true },
    });
  }
};
export const useNoteData = ({ t, activity, relatedObject, wizardKey, isMainNote }) => {
  const noteRef = useRef();
  const accountId = useActiveAccountId();
  const { getWizardProperties, addMetaToWizardProperties } = useWizardContext();
  const wizardProperties = getWizardProperties(wizardKey);
  const mentionsEnabled = useActivityUserMentionsEnabled(accountId);

  const plugins = useRichTextEditorPlugins({
    images: false,
    replaceParagraphs: true,
    userMentions: mentionsEnabled,
    elements: true,
  });
  const internalId = useRef(
    isMainNote
      ? relatedObject?.mainNoteId || relatedObject.mainNote || activity?.id?.value
      : activity?.id?.value,
  );
  const relatedId = relatedObject?.id?.value;

  const wizardPropNoteValue = isMainNote
    ? wizardProperties.activityMainNote
    : wizardProperties.activityNote;
  const defaultNoteValue =
    wizardPropNoteValue ||
    (isMainNote
      ? relatedObject.mainNoteText ||
        getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.NOTE)
      : getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.NOTE));
  const serializedNoteValue = isHtml(defaultNoteValue)
    ? deserialize(defaultNoteValue, {
        format: 'HTML',
        plugins,
      })
    : defaultNoteValue;
  noteRef.current = serializedNoteValue;

  const [loading, setLoading] = useState(false);
  const hasSaved = useRef<boolean>();
  const onSubmit = ({ dataToCreate, setHasSaved }) => {
    setLoading(true);

    const params = { duplicateValidation: true };
    if (internalId.current) {
      api
        .patch(`/bobjects/${internalId.current}/raw`, {
          contents: dataToCreate,
          params,
        })
        .then(() => {
          addMetaToWizardProperties(wizardKey, {
            // if main note, save it in activityMainNote, otherwise save it in activityNote
            [isMainNote ? 'activityMainNote' : 'activityNote']:
              dataToCreate?.[ACTIVITY_FIELDS_LOGIC_ROLE.NOTE],
          });
          setHasSaved();
          setLoading(false);
          window.dispatchEvent(
            new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
              detail: { type: BobjectTypes.Activity },
            }),
          );
          if (isMainNote) {
            setTimeout(() => {
              window.dispatchEvent(new CustomEvent(MessagesEvents.UpdateContext));
            }, 500);
          }
        })
        .catch(() => {
          setHasSaved();
          setLoading(false);
          createToast({ message: t('toasts.errorUpdating'), type: 'error' });
        });
    } else {
      const data = {
        ...dataToCreate,
        [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: ACTIVITY_TYPES_VALUES_LOGIC_ROLE.NOTE,
        [ACTIVITY_FIELDS_LOGIC_ROLE.MAIN_NOTE]: isMainNote
          ? ACTIVITY_MAIN_NOTE_VALUES_LOGIC_ROLE.YES
          : ACTIVITY_MAIN_NOTE_VALUES_LOGIC_ROLE.NO,
      };

      let relatedBobjectType: MainBobjectTypes;

      if (relatedId) {
        relatedBobjectType = Object.keys(BobjectTypes).find(type =>
          relatedId?.includes(type),
        ) as MainBobjectTypes;

        const roles = {
          Lead: 'LEAD',
          Company: 'COMPANY',
          Opportunity: 'OPPORTUNITY',
        };
        Object.keys(roles).forEach(role => {
          data[ACTIVITY_FIELDS_LOGIC_ROLE[roles[role]]] = relatedId.includes(role)
            ? relatedId
            : null;
        });
      }

      api
        .post(`/bobjects/${accountId}/Activity`, {
          contents: { ...dataToCreate },
          params,
        })
        .then(response => {
          window.dispatchEvent(
            new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
              detail: { type: BobjectTypes.Activity },
            }),
          );
          if (isMainNote) {
            setTimeout(() => {
              window.dispatchEvent(new CustomEvent(MessagesEvents.UpdateContext));
            }, 500);
          }

          internalId.current = response?.data?.value;
          if (isMainNote) {
            updateMainNoteInRelated(relatedId, relatedBobjectType, response?.data?.value);
          }

          addMetaToWizardProperties(wizardKey, {
            // if main note, save it in activityMainNote, otherwise save it in activityNote
            [isMainNote ? 'activityMainNote' : 'activityNote']:
              dataToCreate?.[ACTIVITY_FIELDS_LOGIC_ROLE.NOTE],
          });
          setHasSaved();
          setLoading(false);
        })
        .catch(() => {
          setHasSaved();
          setLoading(false);
          createToast({ message: t('toasts.errorUpdating'), type: 'error' });
        });
    }
  };

  const saveNote = useDebouncedCallback(
    () => {
      if (
        noteRef?.current &&
        //@ts-ignore
        noteRef?.current?.[0]?.children?.[0].text &&
        (!serializedNoteValue ||
          JSON.stringify(serializedNoteValue) !== JSON.stringify(noteRef?.current))
      ) {
        const mentions = getMentionsFromNote(noteRef?.current);
        const dataToCreate = noteRef?.current
          ? {
              [ACTIVITY_FIELDS_LOGIC_ROLE.NOTE]: serialize(noteRef?.current, {
                format: 'AST',
                plugins,
              }),
              [ACTIVITY_FIELDS_LOGIC_ROLE.MENTIONS]: mentions,
            }
          : null;
        onSubmit({
          dataToCreate,
          setHasSaved: () => {
            hasSaved.current = true;
          },
        });
      }
    },
    500,
    [onSubmit],
  );

  return {
    note: noteRef?.current,
    setNote: value => {
      noteRef.current = value;
    },
    saveNote,
    loading,
    hasSaved: hasSaved.current,
  };
};
