import React, { PropsWithChildren, useEffect } from 'react';

import { CopilotAnalysis, useCopilotActivity } from '@bloobirds-it/copilot';
import { useCopilotEnabled, useUserSettings } from '@bloobirds-it/hooks';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  Bobject,
} from '@bloobirds-it/types';
import { getFieldByLogicRole, getTextFromLogicRole } from '@bloobirds-it/utils';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import { CopilotSuggestionsCallout } from '../copilotSuggestionsCallout/copilotSuggestionsCallout';
import { ExpandedBoxSection } from '../expandedBoxSection/expandedBoxSection';
import { TranscriptView } from '../transcript/transcript';
import styles from './activityWithAIWrapper.module.css';

type IActivityWithIAWrapper = PropsWithChildren<{
  activity: Bobject;
  onEdit?: () => void;
  isBubble?: boolean;
  regenerateAvailable: boolean;
  onOpenEmailModal?: (text: string) => void;
}>;

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

export const ActivityWithIAWrapper = ({
  activity,
  children,
  isBubble,
  regenerateAvailable,
  onEdit,
  onOpenEmailModal,
}: IActivityWithIAWrapper) => {
  const settings = useUserSettings();
  const isCopilotEnabled = useCopilotEnabled(settings?.account?.id);
  const {
    hasOverlay,
    overlay,
    resetOverlay,
    clearAllSubscriptions,
    isTranscriptActivity,
    activityInsights,
  } = useCopilotActivity();

  const copilotAnalysis = getTextFromLogicRole(
    activity,
    ACTIVITY_FIELDS_LOGIC_ROLE.COPILOT_ANALYSIS,
  );
  const activityType = getFieldByLogicRole(
    activity,
    ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
  )?.valueLogicRole;
  const showAi = (!!copilotAnalysis || !!activityInsights?.summary) && isCopilotEnabled;
  const addPadding = [
    ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL,
    ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN,
    //@ts-ignore
  ].includes(activityType);

  useEffect(() => {
    resetOverlay?.();
    clearAllSubscriptions?.();
  }, [activity?.id?.objectId]);

  if (!hasOverlay) {
    // Return the regular component with children (Activity detail)
    return (
      <motion.div
        className={styles.wrapper}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={fadeVariants}
        transition={{ duration: 0.3 }}
      >
        {showAi && (
          <div className={clsx('copilot-suggestion-callout', { [styles.withPadding]: addPadding })}>
            <CopilotSuggestionsCallout isBubble={isBubble} />
          </div>
        )}
        {children}
      </motion.div>
    );
  }

  if (overlay === 'transcript' && isTranscriptActivity) {
    return (
      <ExpandedBoxSection>
        <motion.div
          style={{ padding: addPadding ? '0px 8px' : '0' }}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={fadeVariants}
          transition={{ duration: 0.3 }}
        >
          <TranscriptView activity={activity} />
        </motion.div>
      </ExpandedBoxSection>
    );
  }

  if (overlay === 'analysis') {
    return (
      <ExpandedBoxSection>
        <motion.div
          style={{ padding: addPadding ? '0px 8px' : '0' }}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={fadeVariants}
          transition={{ duration: 0.3 }}
        >
          <CopilotAnalysis
            activity={activity}
            onEdit={onEdit}
            onOpenEmailModal={onOpenEmailModal}
          />
        </motion.div>
      </ExpandedBoxSection>
    );
  }

  return null;
};
