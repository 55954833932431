export enum MIXPANEL_EVENTS {
  // Mixpanel - New Era
  // Contact channels
  // CC - General
  CLICK_ON_ACTION_FROM_BUBBLE_IN_ICON_ = 'CLICK_ON_ACTION_FROM_BUBBLE_IN_ICON_',

  // Call
  CLICK_ON_LOG_CALL_MANUALLY = 'CLICK_ON_LOG_CALL_MANUALLY',
  CLICK_ON_LOG_CALL_BUTTON_ON_MODAL_OTO = 'CLICK_ON_LOG_CALL_BUTTON_ON_MODAL_OTO',
  CLICK_ON_PHONE_NUMBER_TO_OPEN_DIALER = 'CLICK_ON_PHONE_NUMBER_TO_OPEN_DIALER',
  CLICK_ON_CALL_BUTTON_ON_DIALER_OTO = 'CLICK_ON_CALL_BUTTON_ON_DIALER_OTO', // key-event
  CLICK_ON_LOG_CALL_BUTTON_ON_DIALER_OTO = 'CLICK_ON_LOG_CALL_BUTTON_ON_DIALER_OTO',
  CLICK_ON_ACCEPT_INCOMING_CALL_ON_DIALER_OTO = 'CLICK_ON_ACCEPT_INCOMING_CALL_ON_DIALER_OTO', // key-event
  BLOOBIRDS_OTO_DIALER_CALL_STARTED = 'BLOOBIRDS_OTO_DIALER_CALL_STARTED',
  BLOOBIRDS_OTO_DIALER_CALL_ERROR = 'BLOOBIRDS_OTO_DIALER_CALL_ERROR',
  BLOOBIRDS_OTO_DIALER_CALL_FINISHED = 'BLOOBIRDS_OTO_DIALER_CALL_FINISHED',
  CLICK_ON_CALL_BUTTON_IN_INBOX_CALL_CARD = 'CLICK_ON_CALL_BUTTON_IN_INBOX_CALL_CARD',
  CLICK_ON_DUPLICATE_LEADS_FROM_DIALER = 'CLICK_ON_DUPLICATE_LEADS_FROM_DIALER',
  CLICK_ON_CALL_BUTTON_ON_AIRCALL_OTO = 'CLICK_ON_CALL_BUTTON_ON_AIRCALL_OTO',
  CLICK_ON_CALL_BUTTON_ON_NUMINTEC_OTO = 'CLICK_ON_CALL_BUTTON_ON_NUMINTEC_OTO',
  BLOOBIRDS_DIALER_CREATE_DEVICE_ERROR = 'BLOOBIRDS_DIALER_CREATE_DEVICE_ERROR',

  // CC - Email
  CLICK_ON_SEND_EMAIL_ON_EMAIL_MODAL = 'CLICK_ON_SEND_EMAIL_ON_EMAIL_MODAL', // key-event
  EMAIL_SEND_SUCCESS = 'EMAIL_SEND_SUCCESS',
  EMAIL_SENT_GENERAL_FAIL = 'EMAIL_SENT_GENERAL_FAIL',
  TEMPLATE_EMAIL_SENT_GENERAL_FAIL = 'TEMPLATE_EMAIL_SENT_GENERAL_FAIL',
  EMAIL_ERROR_MISSING_VARIABLES = 'EMAIL_ERROR_MISSING_VARIABLES',
  EMAIL_ERROR_FILE_SIZE_EXCEEDED = 'EMAIL_ERROR_FILE_SIZE_EXCEEDED',
  EMAIL_ERROR_TIMEOUT_REACHED = 'EMAIL_ERROR_TIMEOUT_REACHED',
  EMAIL_ERROR_DISCONNECTED = 'EMAIL_ERROR_DISCONNECTED',
  EMAIL_SENT_WARNINGS = 'EMAIL_SENT_WARNINGS',
  EMAIL_ERROR_GENERAL = 'EMAIL_ERROR_GENERAL',
  CLICK_ON_SCHEDULE_EMAIL = 'CLICK_ON_SCHEDULE_EMAIL',
  EMAIL_SCHEDULED_SUCCESSFULLY = 'EMAIL_SCHEDULED_SUCCESSFULLY',
  EMAIL_SCHEDULED_FAILED = 'EMAIL_SCHEDULED_FAILED',
  EMAIL_SCHEDULED_CANCELED_SUCCESSFULLY = 'EMAIL_SCHEDULED_CANCELED_SUCCESSFULLY',
  EMAIL_SCHEDULED_CANCELED_FAILED = 'EMAIL_SCHEDULED_CANCELED_FAILED',
  CLICK_ON_SMART_EMAIL_HELPER_TAB_ = 'CLICK_ON_SMART_EMAIL_HELPER_TAB_',
  OPEN_CALENDAR_SLOTS = 'OPEN_CALENDAR_SLOTS',
  ADD_CALENDAR_SLOTS = 'ADD_CALENDAR_SLOTS',
  EDIT_CALENDAR_SLOTS = 'EDIT_CALENDAR_SLOTS',
  REMOVE_CALENDAR_SLOT = 'REMOVE_CALENDAR_SLOT',
  CLICK_ON_CLOSE_EMAIL_MODAL = 'CLICK_ON_CLOSE_EMAIL_MODAL',
  CLICK_ON_REPLY_FROM_INBOX_OTO = 'CLICK_ON_REPLY_FROM_INBOX_OTO',
  CLICK_ON_REPLY_FROM_EMAIL_OTO = 'CLICK_ON_REPLY_FROM_EMAIL_OTO',

  // CC - Meeting
  CLICK_ON_CREATE_FROM_CALENDAR_MODAL = 'CLICK_ON_CREATE_FROM_CALENDAR_MODAL', // key-event
  CREATE_MEETING_SUCCESS = 'CREATE_MEETING_SUCCESS',
  CREATE_MEETING_ERROR = 'CREATE_MEETING_ERROR',
  CLICK_ON_UPDATE_FROM_CALENDAR_MODAL = 'CLICK_ON_UPDATE_FROM_CALENDAR_MODAL',
  CLICK_EDIT_MEETING_TYPE_FROM_TAB_OTO = 'CLICK_EDIT_MEETING_TYPE_FROM_TAB_OTO',
  CLICK_ON_CHANGE_MEETING_TYPE_FROM_TEAM_MEETINGS_KPI = 'CLICK_ON_CHANGE_MEETING_TYPE_FROM_TEAM_MEETINGS_KPI',
  CLICK_ON_CHANGE_MEETING_RESULT_FROM_TEAM_MEETINGS_KPI = 'CLICK_ON_CHANGE_MEETING_RESULT_FROM_TEAM_MEETINGS_KPI',
  CLICK_ON_CHANGE_MEETING_TYPE_FROM_MEETINGS_IN_PERIOD_KPI = 'CLICK_ON_CHANGE_MEETING_TYPE_FROM_MEETINGS_IN_PERIOD_KPI',

  // CC - LinkedIn
  LINKEDIN_THREAD_SYNC_MANUAL = 'LINKEDIN_THREAD_SYNC_MANUAL',
  LINKEDIN_THREAD_SYNC = 'LINKEDIN_THREAD_SYNC_AUTO', // key-event
  LINKEDIN_THREAD_SYNC_SUCCESS = 'LINKEDIN_THREAD_SYNC_SUCCESS',
  LINKEDIN_THREAD_SYNC_ERROR = 'LINKEDIN_THREAD_SYNC_ERROR',

  // CC - Whatsapp
  OPEN_WHATSAPP_FROM_EXTENSION = 'OPEN_WHATSAPP_FROM_EXTENSION',
  OPEN_WHATSAPP_FROM_WEBAPP = 'OPEN_WHATSAPP_FROM_WEBAPP',
  WHATSAPP_SYNC_MESSAGES = 'WHATSAPP_SYNC_MESSAGES', // key-event
  WHATSAPP_SYNC_MESSAGE_SUCCESS = 'WHATSAPP_SYNC_MESSAGE_SUCCESS',
  WHATSAPP_SYNC_MESSAGE_ERROR = 'WHATSAPP_SYNC_MESSAGE_ERROR',

  // CC - WA Business
  SEND_WHATSAPP_BUSINESS_MESSAGE = 'SEND_WHATSAPP_BUSINESS_MESSAGE', // key-event
  SEND_WHATSAPP_BUSINESS_FROM_BB_SUCCESS = 'SEND_WHATSAPP_BUSINESS_FROM_BB_SUCCESS',
  SEND_WHATSAPP_BUSINESS_FROM_BB_ERROR = 'SEND_WHATSAPP_BUSINESS_FROM_BB_ERROR',

  // CC - SMS
  SEND_SMS_MESSAGE = 'SEND_SMS_MESSAGE', // key-event
  SEND_SMS_FROM_BB_SUCCESS = 'SEND_SMS_FROM_BB_SUCCESS',
  SEND_SMS_FROM_BB_ERROR = 'SEND_SMS_FROM_BB_ERROR',

  // Task Management
  CLICK_ON_ADD_TASK_FROM_NEW_TASK_MODAL = 'CLICK_ON_ADD_TASK_FROM_NEW_TASK_MODAL',
  CLICK_ON_UPDATE_TASK_FROM_NEW_TASK_MODAL = 'CLICK_ON_UPDATE_TASK_FROM_NEW_TASK_MODAL',
  SKIP_TASK_FROM_CARD = 'SKIP_TASK_FROM_CARD',

  // Notes
  CLICK_ON_SAVE_ON_NOTE_MODAL = 'CLICK_ON_SAVE_ON_NOTE_MODAL',
  CLICK_ON_SAVE_ON_INTERNAL_NOTE_MODAL = 'CLICK_ON_SAVE_ON_INTERNAL_NOTE_MODAL',
  CLICK_ON_SAVE_ON_MAIN_NOTE_MODAL = 'CLICK_ON_SAVE_ON_MAIN_NOTE_MODAL',
  CLICK_ON_UPDATE_ON_NOTE_MODAL = 'CLICK_ON_UPDATE_ON_NOTE_MODAL',
  CLICK_ON_STAR_FROM_NOTE_MODAL = 'CLICK_ON_STAR_FROM_NOTE_MODAL',
  CLICK_ON_MAIN_NOTE_ICON_FROM_CONTACT_VIEW = 'CLICK_ON_MAIN_NOTE_ICON_FROM_CONTACT_VIEW',

  // Sidepeek
  CLICK_ON_LEAD_FROM_CONTACT_VIEW_MENU_OTO = 'CLICK_ON_LEAD_FROM_CONTACT_VIEW_MENU_OTO',
  COMPLETE_TASK_FROM_CONTACT_VIEW_OTO = 'COMPLETE_TASK_FROM_CONTACT_VIEW_OTO',
  START_TASKS_FROM_TASK_TAB_OTO = 'START_TASKS_FROM_TASK_TAB_OTO',
  CLICK_IN_ACTIVITY_FROM_FEED_OTO = 'CLICK_IN_ACTIVITY_FROM_FEED_OTO',

  // Playbook
  // CLICK_ON_{{templateType}}_SECTION_FROM_{{environment}}
  // CLICK_ON_{{templateType}}_CARD_FROM_{{environment}}
  // CLICK_ON_{{templateType}}_ADD_TEMPLATE_FROM_{{environment}}
  // CLICK_ON_USE_{{templateType}}_FROM_{{environment}}
  // CLICK_ON_EDIT_HTML_TEMPLATE_FROM_{{environment}}
  // CLICK_ON_EDIT_{{templateType}}_FROM_{{environment}}
  // TOGGLE_SEGMENTATION_FILTERS_FROM_{{environment}}
  CLICK_ON_USE_TEMPLATE_ON_SMART_EMAIL = 'CLICK_ON_USE_TEMPLATE_ON_SMART_EMAIL',
  CLICK_ON_USE_SNIPPET_ON_SMART_EMAIL = 'CLICK_ON_USE_SNIPPET_ON_SMART_EMAIL',
  CREATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL = 'CREATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL',
  CREATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_SUCCESS = 'CREATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_SUCCESS',
  CREATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_ERROR = 'CREATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_ERROR',
  UPDATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL = 'UPDATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL',
  UPDATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_SUCCESS = 'UPDATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_SUCCESS',
  UPDATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_ERROR = 'UPDATE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_ERROR',
  DELETE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL = 'DELETE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL',
  DELETE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_SUCCESS = 'DELETE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_SUCCESS',
  DELETE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_ERROR = 'DELETE_TEMPLATE_FROM_HANDLE_TEMPLATE_MODAL_ERROR',
  CLOSE_HANDLE_TEMPLATE_MODAL = 'CLOSE_HANDLE_TEMPLATE_MODAL',

  //General search bar
  GLOBAL_SEARCH_BAR_RESULT_CLICKED = 'GLOBAL_SEARCH_BAR_RESULT_CLICKED',
  GLOBAL_SEARCH_BAR_QUICK_ACTION_CLICKED = 'GLOBAL_SEARCH_BAR_QUICK_ACTION_CLICKED',
  GLOBAL_SEARCH_BAR_CHANGED_TYPE_FILTER = 'GLOBAL_SEARCH_BAR_CHANGED_TYPE_FILTER',
  GLOBAL_SEARCH_BAR_SEARCHED = 'GLOBAL_SEARCH_BAR_SEARCHED',
  GLOBAL_SEARCH_BAR_OPENED = 'GLOBAL_SEARCH_BAR_OPENED',

  //Change and open bubble/sidepeek menu
  CHANGE_TO_SIDEPEEK_VIEW = 'CHANGE_TO_SIDEPEEK_VIEW',
  CHANGE_TO_BUBBLE_VIEW = 'CHANGE_TO_BUBBLE_VIEW',
  MINIMIZE_BUBBLE_MENU = 'CLOSE_BUBBLE_MENU',
  MINIMIZE_SIDEPEEK_MENU = 'CLOSE_SIDEPEEK_MENU',
  MAXIMIZE_BUBBLE_VIEW = 'OPEN_BUBBLE_MENU',
  MAXIMIZE_SIDEPEEK_VIEW = 'OPEN_SIDEPEEK_MENU',

  // Tooltips
  ACTIVITY_TOOLTIP_BLOCK_MARKED_AS_HIDDEN = 'ACTIVITY_TOOLTIP_BLOCK_MARKED_AS_HIDDEN',
  ACTIVITY_EMAIL_TOOLTIP_BUTTON_CLICKED = 'ACTIVITY_EMAIL_TOOLTIP_BUTTON_CLICKED',
  ACTIVITY_LINKEDIN_TOOLTIP_BUTTON_CLICKED = 'ACTIVITY_LINKEDIN_TOOLTIP_BUTTON_CLICKED',
  ACTIVITY_TOOLTIP_TOP_BUTTON_CLICKED = 'ACTIVITY_TOOLTIP_TOP_BUTTON_CLICKED',
  EXTENSION_EMAIL_ACTION_TOOLTIP_INFO_CLICK = 'EXTENSION_EMAIL_ACTION_TOOLTIP_INFO_CLICK',
  EXTENSION_EMAIL_ACTION_TOOLTIP_CLOSE_CLICK = 'EXTENSION_EMAIL_ACTION_TOOLTIP_CLOSE_CLICK',
  EXTENSION_TASK_ACTION_TOOLTIP_INFO_CLICK = 'EXTENSION_TASK_ACTION_TOOLTIP_INFO_CLICK',
  EXTENSION_TASK_ACTION_TOOLTIP_CLOSE_CLICK = 'EXTENSION_TASK_ACTION_TOOLTIP_CLOSE_CLICK',
  EXTENSION_PLAYBOOK_SNIPPETS_TOOLTIP_INFO_CLICK = 'EXTENSION_PLAYBOOK_SNIPPETS_TOOLTIP_INFO_CLICK',
  EXTENSION_PLAYBOOK_SNIPPETS_TOOLTIP_CLOSE_CLICK = 'EXTENSION_PLAYBOOK_SNIPPETS_TOOLTIP_CLOSE_CLICK',
  EXTENSION_SEE_SLOTS_TOOLTIP_CLOSE_CLICK = 'EXTENSION_SEE_SLOTS_TOOLTIP_CLOSE_CLICK',
  EXTENSION_SEE_SLOTS_TOOLTIP_INFO_CLICK = 'EXTENSION_SEE_SLOTS_TOOLTIP_INFO_CLICK',
  EXTENSION_SIMILAR_DEALS_TOOLTIP_INFO_CLICK = 'EXTENSION_SIMILAR_DEALS_TOOLTIP_INFO_CLICK',
  EXTENSION_SIMILAR_DEALS_TOOLTIP_CLOSE_CLICK = 'EXTENSION_SIMILAR_DEALS_TOOLTIP_CLOSE_CLICK',
  CALENDAR_TIPS_SEE_CLICKED = 'CALENDAR_TIPS_SEE_CLICKED',
  SNIPPETS_BUTTON_TIP_CLICKED = 'SNIPPETS_BUTTON_TIP_CLICKED',
  SNIPPETS_TOOLTIP_BLOCK_MARKED_AS_HIDDEN = 'SNIPPETS_TOOLTIP_BLOCK_MARKED_AS_HIDDEN',
  SMART_EMAIL_SIMILAR_DEALS_LEARN_MORE = 'SMART_EMAIL_SIMILAR_DEALS_LEARN_MORE',
  SMART_EMAIL_SIMILAR_DEALS_DONT_SHOW_BANNER = 'SMART_EMAIL_SIMILAR_DEALS_DONT_SHOW_BANNER',

  // Cadences
  CLICK_START_CADENCE_FROM_CONTACT_VIEW_OTO = 'CLICK_START_CADENCE_FROM_CONTACT_VIEW_OTO',
  CLICK_START_CADENCE_FROM_MODAL = 'CLICK_START_CADENCE_FROM_MODAL',
  CLICK_ON_ADD_CADENCE_TAG = 'CLICK_ON_ADD_CADENCE_TAG',
  CLICK_ON_REMOVE_CADENCE_TAG = 'CLICK_ON_REMOVE_CADENCE_TAG',
  CLICK_ON_FILTER_CADENCES_BY_TAG = 'CLICK_ON_FILTER_CADENCES_BY_TAG',
  CLICK_ON_ENABLE_TEMPLATES_FROM_EDIT_STEP_CADENCE = 'CLICK_ON_ENABLE_TEMPLATES_FROM_EDIT_STEP_CADENCE',
  CLICK_ON_ADD_TEMPLATE_FROM_EDIT_STEP_CADENCE = 'CLICK_ON_ADD_TEMPLATE_FROM_EDIT_STEP_CADENCE',
  SET_CADENCE_IMPORT_BULK_ACTION_CLICKED_ON_ = 'SET_CADENCE_IMPORT_BULK_ACTION_CLICKED_ON_',
  SET_CADENCE_BASIC_BULK_ACTION_CLICKED_ON_ = 'SET_CADENCE_BASIC_BULK_ACTION_CLICKED_ON_',
  STOP_CADENCE_IMPORT_BULK_ACTION_CLICKED_ON_ = 'STOP_CADENCE_IMPORT_BULK_ACTION_CLICKED_ON_',
  STOP_CADENCE_BASIC_BULK_ACTION_CLICKED_ON_ = 'STOP_CADENCE_BASIC_BULK_ACTION_CLICKED_ON_',

  // Custom activity
  CLICK_ON_EDIT_CUSTOM_ACTIVITY = 'CLICK_ON_EDIT_CUSTOM_ACTIVITY',
  CLICK_ON_DELETE_CUSTOM_ACTIVITY = 'CLICK_ON_DELETE_CUSTOM_ACTIVITY',
  CLICK_ON_AUTOLOG_CUSTOM_ACTIVITY = 'CLICK_ON_AUTOLOG_CUSTOM_ACTIVITY',

  // B Links in SFDC
  CLICK_ON_SFDC_B_LINK_ = 'CLICK_ON_SFDC_B_LINK_',

  // Reporting
  // Reporting - Main events
  MOUNT_EXTENSION_WIZARD_STEP_ = 'MOUNT_EXTENSION_WIZARD_STEP_',
  UNMOUNT_EXTENSION_WIZARD_STEP_ = 'UNMOUNT_EXTENSION_WIZARD_STEP_',
  EXIT_WIZARD_MODALS = 'EXIT_WIZARD_MODALS',
  CALL_RESULT_ADDED_IN_WIZARD = 'CALL_RESULT_ADDED_IN_WIZARD',
  CLICK_EDIT_MEETING_RESULT_FROM_TAB_OTO = 'CLICK_EDIT_MEETING_RESULT_FROM_TAB_OTO',
  CLICK_ON_REPORT_MEETING_RESULT_FROM_TAB_OTO = 'CLICK_ON_REPORT_MEETING_RESULT_FROM_TAB_OTO',
  CLICK_ON_CHANGE_MEETING_RESULT = 'CLICK_ON_CHANGE_MEETING_RESULT',

  // Reporting - Status note actions
  STATUS_NOTE_ACTIONS_CHANGE_STATUS = 'STATUS_NOTE_ACTIONS_CHANGE_STATUS',
  STATUS_NOTE_ACTIONS_AUTO_SAVED_NOTE = 'STATUS_NOTE_ACTIONS_AUTO_SAVED_NOTE',
  STATUS_NOTE_ACTIONS_OPEN_WHATSAPP = 'STATUS_NOTE_ACTIONS_OPEN_WHATSAPP',
  STATUS_NOTE_ACTIONS_COMPLETE_TASK = 'STATUS_NOTE_ACTIONS_COMPLETE_TASK',
  STATUS_NOTE_ACTIONS_OPEN_EMAIL = 'STATUS_NOTE_ACTIONS_OPEN_EMAIL',
  STATUS_NOTE_ACTIONS_CREATE_TASK = 'STATUS_NOTE_ACTIONS_CREATE_TASK',
  STATUS_NOTE_ACTIONS_UPDATE_CRM_STATUS = 'STATUS_NOTE_ACTIONS_UPDATE_CRM_STATUS',
  STATUS_NOTE_ACTIONS_GENERAL_NOTES = 'STATUS_NOTE_ACTIONS_GENERAL_NOTES',
  STATUS_NOTE_ACTIONS_FINISH_REPORTING = 'STATUS_NOTE_ACTIONS_FINISH_REPORTING',
  STATUS_NOTE_ACTIONS_GO_TO_MANAGE_TASKS = 'STATUS_NOTE_ACTIONS_GO_TO_MANAGE_TASKS',

  // Reporting - Task management modal
  TASK_MANAGEMENT_STEP_CREATE_TASK = 'TASK_MANAGEMENT_MODAL_CREATE_TASK',
  TASK_MANAGEMENT_STEP_STOP_CADENCE = 'TASK_MANAGEMENT_MODAL_STOP_CADENCE',
  TASK_MANAGEMENT_STEP_RESCHEDULE_CADENCE = 'TASK_MANAGEMENT_STEP_RESCHEDULE_CADENCE',
  TASK_MANAGEMENT_STEP_START_CADENCE = 'TASK_MANAGEMENT_STEP_START_CADENCE',
  TASK_MANAGEMENT_STEP_MARK_TASK_AS_COMPLETED_FROM_CARD = 'TASK_MANAGEMENT_STEP_MARK_TASK_AS_COMPLETED_FROM_CARD',
  TASK_MANAGEMENT_STEP_RESCHEDULE_TASK_FROM_CARD = 'TASK_MANAGEMENT_STEP_RESCHEDULE_TASK_FROM_CARD',
  TASK_MANAGEMENT_STEP_EDIT_TASK_FROM_CARD = 'TASK_MANAGEMENT_STEP_EDIT_TASK_FROM_CARD',
  TASK_MANAGEMENT_STEP_CHANGE_TASK_PRIORITY_FROM_CARD = 'TASK_MANAGEMENT_STEP_CHANGE_TASK_PRIORITY_FROM_CARD',
  TASK_MANAGEMENT_STEP_SKIP_TASK_FROM_CARD = 'TASK_MANAGEMENT_STEP_SKIP_TASK_FROM_CARD',

  // Reporting - Other
  CLICK_ON_NEXT_IN_WIZARD_STEP_ = 'CLICK_ON_NEXT_IN_WIZARD_STEP_',
  CLICK_ON_SKIP_IN_WIZARD_STEP_ = 'CLICK_ON_SKIP_IN_WIZARD_STEP_',
  CLICK_ON_BACK_IN_WIZARD_STEP_ = 'CLICK_ON_BACK_IN_WIZARD_STEP_',
  CLICK_ON_CLOSE_IN_WIZARD_STEP_ = 'CLICK_ON_CLOSE_IN_WIZARD_STEP_',
  COMPLETE_TASK_FROM_CONTACT_FLOW = 'COMPLETE_TASK_FROM_CONTACT_FLOW',
  HOME_MARK_AS_DONE_ACTION_CLICKED_ON_SINGLE_CARD = 'HOME_MARK_AS_DONE_ACTION_CLICKED_ON_SINGLE_CARD',
  TASK_FEED_EDIT_TASK_ACTION_CHROME_EXTENSION = 'TASK_FEED_EDIT_TASK_ACTION_CHROME_EXTENSION',
  HOME_RESCHEDULE_ACTION_CLICKED_ON_SINGLE_CARD = 'HOME_RESCHEDULE_ACTION_CLICKED_ON_SINGLE_CARD',

  // IA in extension
  UNFOLD_SUMMARY_BLOCK_IN_ACTIVITY_OF_TYPE_ = 'UNFOLD_SUMMARY_BLOCK_IN_ACTIVITY_OF_TYPE_',
  OPEN_IA_INSIGHTS_IN_EXTENDED_SCREEN_FOR_TYPE_ = 'OPEN_IA_INSIGHTS_IN_EXTENDED_SCREEN_FOR_TYPE_',
  OPEN_IA_TRANSCRIPT_IN_EXTENDED_SCREEN_FOR_TYPE_ = 'OPEN_IA_TRANSCRIPT_IN_EXTENDED_SCREEN_FOR_TYPE_',
  OPEN_EDIT_TRANSCRIPT_MODAL = 'OPEN_EDIT_TRANSCRIPT_MODAL',
  UPDATE_TRANSCRIPT = 'UPDATE_TRANSCRIPT',
  UPDATE_TRANSCRIPT_SUCCESS = 'UPDATE_TRANSCRIPT_SUCCESS',
  UPDATE_TRANSCRIPT_ERROR = 'UPDATE_TRANSCRIPT_ERROR',
  CLICK_ON_REGENERATE_INSIGHTS = 'CLICK_ON_REGENERATE_INSIGHTS',
  CLICK_ON_OPEN_CRM_UPDATES = 'CLICK_ON_OPEN_CRM_UPDATES',
  CLICK_ON_REGENERATE_CRM_UPDATES = 'CLICK_ON_REGENERATE_CRM_UPDATES',
  CLICK_ON_ADD_NOTE_TO_INSIGHTS = 'CLICK_ON_ADD_NOTE_TO_INSIGHTS',

  // Other
  EXTENSION_UPDATE = 'EXTENSION_UPDATE',
  EXTENSION_UPDATE_FROM_LEFTBAR = 'EXTENSION_UPDATE_FROM_LEFTBAR',
  EDITOR_OPEN_CHANGE_SIZE = 'EDITOR_OPEN_CHANGE_SIZE',
  EDITOR_OPEN_COLOR_PICKER = 'EDITOR_OPEN_COLOR_PICKER',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  CLICK_ON_CHANGE_LANGUAGE = 'CLICK_ON_CHANGE_LANGUAGE',
  CLICK_ON_MINIMIZE_MODAL = 'CLICK_ON_MINIMIZE_MODAL',
  CLICK_ON_MAXIMIZE_MODAL = 'CLICK_ON_MAXIMIZE_MODAL',
  CLICK_ON_SYNC_ISSUE = 'CLICK_ON_SYNC_ISSUE',
  CLICK_ON_SEARCH_EMAIL_OPTIONS = 'CLICK_ON_SEARCH_EMAIL_OPTIONS',
  REASSIGN_IMPORT_BULK_ACTION_CLICKED_ON_ = 'REASSIGN_IMPORT_BULK_ACTION_CLICKED_ON_',
  REASSIGN_BASIC_BULK_ACTION_CLICKED_ON_ = 'REASSIGN_BASIC_BULK_ACTION_CLICKED_ON_',
  CLICK_ON_ADD_LEAD_OTO = 'CLICK_ON_ADD_LEAD_OTO',
}
