import { Bobject, RelatedObject } from './bobjects';

export type MinimizableModalType =
  | 'email'
  | 'meeting'
  | 'note'
  | 'internalNote'
  | 'mainNote'
  | 'task'
  | 'taskStatic'
  | 'calendarMeeting'
  | 'handleTemplate';

export type MinimizableModal<T> = {
  id: string;
  title?: string;
  open: boolean;
  data: T;
  bobject?: Bobject;
  relatedObject?: RelatedObject;
  type: MinimizableModalType;
  hasBeenMinimized: boolean;
  onSave?: () => void;
  onClose?: () => void;
};

export const MINIMIZABLE_MODALS = 'minimizableModals';
