import {
  ExtensionLead,
  MIXPANEL_EVENTS,
  PortName,
  SentryMessages,
  SentryTags,
  SyncSource,
  SyncThreadBackgroundMessage,
  TemplateSelectorPlaces,
} from '@bloobirds-it/types';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

import { isWeb } from '../../../api';
import { searchLeadByQuery } from '../../../leads';
import { isIdLinkedinUrl, normalizeUrl } from '../../../url.utils';
import scrapLinkedInMessages, { getLinkedInMessagesMetaObject } from '../linkedin/syncMessages';
import scrapLinkedInMiniMessages, {
  getLinkedInMiniWindowMetaObject,
} from '../linkedinMini/syncMessages';
import scrapSalesNavigatorMessages, {
  getSalesNavMessagingSenderMetaObject,
} from '../sales/syncMessages';
import scrapSalesNavigatorChatMessages from '../salesMini/syncMessages';

const reconnectInterval = 5000;
let port;

const connectPort = () => {
  if (chrome) {
    port = chrome?.runtime?.connect?.({ name: PortName.SyncThread });
    if (port) {
      port.onDisconnect.addListener(() => {
        console.log('Port disconnected');
        port = null; // Limpia la referencia al puerto
        // Intentar reconectar después de un intervalo
        setTimeout(connectPort, reconnectInterval);
      });
    }
  }
};

if (!isWeb()) connectPort();

const syncThread = ({ messages, source }: SyncThreadBackgroundMessage) => {
  if (port) {
    try {
      port.postMessage({ messages, source });
      mixpanel.track(MIXPANEL_EVENTS.LINKEDIN_THREAD_SYNC_SUCCESS, {
        Messages: messages,
        Source: source,
      });
    } catch (error) {
      console.log('Error sending message:', error);
      mixpanel.track(MIXPANEL_EVENTS.LINKEDIN_THREAD_SYNC_ERROR, {
        Error: JSON.stringify(error || {}),
        Messages: JSON.stringify(messages || {}),
        Source: source,
      });
      Sentry.captureException(new Error(SentryMessages.LinkedInSyncError), {
        tags: {
          module: SentryTags.LinkedInSyncError,
        },
        extra: {
          error: JSON.stringify(error || {}),
          messages: JSON.stringify(messages || {}),
          source: source,
        },
      });
    }
  } else {
    console.log('Port is disconnected, cannot send message');
    Sentry.captureException(new Error(`Port is disconnected, cannot send message`), {
      tags: {
        module: 'syncMessagesLinkedin',
      },
      extra: {
        messages,
        source,
      },
    });
  }
};

export const scrapAndSync = async (
  url: string,
  bubbleEl: Element,
  place: string,
  id: string,
  currentRef?: any,
  manual?: boolean,
) => {
  if (!manual) {
    mixpanel.track(MIXPANEL_EVENTS.LINKEDIN_THREAD_SYNC);
  } else {
    mixpanel.track(MIXPANEL_EVENTS.LINKEDIN_THREAD_SYNC_MANUAL);
  }

  if (place === TemplateSelectorPlaces.LinkedinChat) {
    const messages = await scrapLinkedInMiniMessages(bubbleEl, id);
    syncThread({ messages, source: SyncSource.LinkedInMini });
  } else if (place === TemplateSelectorPlaces.Linkedin) {
    const messages = await scrapLinkedInMessages(id, url, currentRef);
    syncThread({ messages, source: SyncSource.LinkedIn });
  } else if (place === TemplateSelectorPlaces.SalesNavigator) {
    const messages = await scrapSalesNavigatorMessages(id, url, currentRef);
    syncThread({ messages, source: SyncSource.SalesNavigator });
  } else if (place === TemplateSelectorPlaces.SalesNavigatorChat) {
    const messages = await scrapSalesNavigatorChatMessages(id);
    syncThread({ messages, source: SyncSource.SalesNavigatorChat });
  }
};

export const fetchLead = async (
  place: string,
  bubbleEl: Element,
  setData: ({
    lead,
    loading,
  }: {
    lead: ExtensionLead | { nameTo: string; jobTitle: string; companyName: string } | null;
    loading: boolean;
  }) => void,
  url: string,
  currentTemplateRef: any,
) => {
  const searchLead = (data: any, fieldToSearch: 'linkedInUrl' | 'salesNavigatorUrl') => {
    searchLeadByQuery({
      [fieldToSearch]: isIdLinkedinUrl(data?.meta?.messageTo)
        ? null
        : data?.meta?.messageTo || null,
      leadFullName: data?.meta?.nameTo,
      linkedInId: data?.meta?.leadId,
      autoMatching: place === TemplateSelectorPlaces.SalesNavigator,
    })
      .then(d => {
        if (currentTemplateRef.current === false) return;
        if (d?.leads?.[0] && d?.leads?.length === 1 && d?.exactMatch === true) {
          // When the lead is found, we set the lead data and scrap the messages
          const lead = {
            ...d?.leads[0],
            nameTo: data?.meta?.nameTo,
            jobTitle: d?.leads[0]?.jobTitle || data?.meta?.jobTitle,
            companyName: d?.leads[0]?.companyName || data?.meta?.companyName,
          };
          setData({ lead, loading: false });
          if (currentTemplateRef.current === false) return;
          scrapAndSync(url, bubbleEl, place, d?.leads?.[0]?.id?.value, currentTemplateRef);
        } else {
          // When the lead is not found, we set the lead from the meta object
          const lead = {
            nameTo: data?.meta?.nameTo,
            jobTitle: data?.meta?.jobTitle,
            companyName: data?.meta?.companyName,
          };
          setData({ lead, loading: false });
        }
      })
      .catch(() => {
        if (currentTemplateRef?.current === false) return;
        // When the lead is not found, we set the lead from the meta object
        const lead = {
          nameTo: data?.meta?.nameTo,
          jobTitle: data?.meta?.jobTitle,
          companyName: data?.meta?.companyName,
        };
        setData({ lead, loading: false });
      });
  };
  if (place === TemplateSelectorPlaces.LinkedinChat) {
    if (currentTemplateRef?.current === false) return;
    const data = await getLinkedInMiniWindowMetaObject(bubbleEl, true);
    if (currentTemplateRef?.current === false) return;
    if (data?.meta) {
      searchLead(data, 'linkedInUrl');
    }
  }

  if (place === TemplateSelectorPlaces.Linkedin) {
    if (currentTemplateRef?.current === false) return;
    const data = await getLinkedInMessagesMetaObject();
    if (currentTemplateRef?.current === false) return;
    if (data?.meta) {
      searchLead(data, 'linkedInUrl');
    }
  }

  if (
    place === TemplateSelectorPlaces.SalesNavigator ||
    place === TemplateSelectorPlaces.SalesNavigatorChat
  ) {
    if (currentTemplateRef.current === false) return;
    if (url !== normalizeUrl(window.location.href)) return;
    if (currentTemplateRef?.current === false) return;
    const data = await getSalesNavMessagingSenderMetaObject();
    if (currentTemplateRef?.current === false) return;
    if (data?.meta) {
      searchLead(data, 'salesNavigatorUrl');
    }
  }
};
