import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createToast } from '@bloobirds-it/flamingo-ui';
import { useCustomTasks, useIsShowWhatsappConversationHistory } from '@bloobirds-it/hooks';
import { getI18nSpacetimeLng } from '@bloobirds-it/internationalization';
import { useEventSubscription } from '@bloobirds-it/plover';
import { ACTIVITY_FIELDS_LOGIC_ROLE, BobjectId, BobjectTypes } from '@bloobirds-it/types';
import {
  api,
  getFieldByLogicRole,
  getUserTimeZone,
  getValueFromLogicRole,
} from '@bloobirds-it/utils';
import spacetime from 'spacetime';
import useSWR from 'swr';

export interface WhatsappMessage {
  caseId?: any;
  caseNumber?: any;
  id: string;
  wid: string;
  direction: 'INCOMING' | 'OUTGOING';
  body: string;
  isoDate: string;
  user: string;
  state: 'SENT' | 'PENDING' | 'FAILED';
  media?: any;
  hasTranscription?: boolean;
}

export interface GroupedMessages {
  formattedDate: string;
  messages: WhatsappMessage[];
}

export interface MessagesPerDay {
  [formattedDate: string]: GroupedMessages;
}

export interface WhatsappMessages {
  messages: MessagesPerDay;
  isLoading: boolean;
  totalMatching: number;
  mutate: any;
  saveNewMessage: (newMessage: MessagesPerDay) => void;
  updateMessageState: (
    messageId: string,
    state: 'SENT' | 'PENDING' | 'FAILED',
    wid?: string,
  ) => void;
}

function getISODate(date) {
  const sDate = spacetime(date);
  return sDate.format('{year}-{iso-month}-{date-pad}');
}

async function fetchWhatsappMessages({
  accountId,
  leadId,
  customTaskId,
  relatedObject,
  noIdsCallback,
  isShowWhatsappConversationHistory,
}: {
  accountId: string;
  leadId: BobjectId<BobjectTypes.Lead>['value'];
  customTaskId: string;
  relatedObject: string;
  noIdsCallback?: () => void;
  isShowWhatsappConversationHistory?: boolean;
}) {
  if (!leadId && !relatedObject) {
    noIdsCallback?.();
    return { contents: [], page: 0, totalMatching: 0 };
  } else {
    const { data } = await api.post(`/bobjects/${accountId}/Activity/search`, {
      query: {
        [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: ['ACTIVITY__TYPE__CUSTOM_TASK'],
        [ACTIVITY_FIELDS_LOGIC_ROLE.CUSTOM_TASK]: customTaskId,
        ...(isShowWhatsappConversationHistory
          ? {}
          : {
              ...(relatedObject
                ? {}
                : leadId
                  ? { [ACTIVITY_FIELDS_LOGIC_ROLE.LEAD]: [leadId] }
                  : {}),
              ...(relatedObject && {
                [ACTIVITY_FIELDS_LOGIC_ROLE.RELATED_OBJECT_ID]: relatedObject,
              }),
            }),
      },
      ...(isShowWhatsappConversationHistory && (leadId || relatedObject)
        ? {
            queries: [
              ...(leadId
                ? [
                    {
                      [ACTIVITY_FIELDS_LOGIC_ROLE.LEAD]: [leadId],
                    },
                  ]
                : []),
              ...(relatedObject
                ? [
                    {
                      [ACTIVITY_FIELDS_LOGIC_ROLE.RELATED_OBJECT_ID]: relatedObject,
                    },
                  ]
                : []),
            ],
          }
        : {}),
      formFields: true,
      pageSize: 200,
      injectReferences: true,
    });
    return data;
  }
}

export const orderByDate = (groupedMessages: MessagesPerDay): MessagesPerDay => {
  // Sort the messages by date
  for (const key of Object.keys(groupedMessages)) {
    const messages = groupedMessages[key].messages;
    groupedMessages[key].messages = messages.sort((a, b) => {
      const dateA = spacetime(a.isoDate);
      const dateB = spacetime(b.isoDate);
      return dateA.isBefore(dateB) ? 1 : -1;
    });
  }

  // Sort the keys by date
  return Object.fromEntries(
    Object.entries(groupedMessages).sort((a, b) => {
      const dateA = spacetime(a[0]);
      const dateB = spacetime(b[0]);
      return dateA.isBefore(dateB) ? 1 : -1;
    }),
  );
};

export function formatDateWs(date, lang, timezone, t): string {
  const now = getI18nSpacetimeLng(lang, new Date(), timezone);
  const sDate = getI18nSpacetimeLng(lang, date, timezone);

  if (sDate.isSame('day', now)) {
    return t('whatsapp.chat.today');
  } else if (sDate.isSame('day', now.subtract(1, 'day'))) {
    return t('whatsapp.chat.yesterday');
  } else if (sDate.isSame('year', now)) {
    const format = lang === 'es' ? '{date} {month-short}' : '{month-short} {date-ordinal}';
    return sDate.format(format);
  } else {
    const format =
      lang === 'es' ? '{date} {month-short}, {year}' : '{month-short} {date-ordinal}, {year}';
    return sDate.format(format);
  }
}

function parseMessages(whatsappMessages, lang, t): MessagesPerDay {
  // Diferenciar entre mensajes de actividad, sin parsear, y los ya parseados que llegan por optimistic update
  if (whatsappMessages?.contents) {
    const timeZone = getUserTimeZone();
    const parsedMessages =
      whatsappMessages?.contents.map(message => {
        const media = getValueFromLogicRole(message, 'ACTIVITY__ATTACHMENTS');
        return {
          id: message.id.value,
          wid: getValueFromLogicRole(message, ACTIVITY_FIELDS_LOGIC_ROLE.WHATSAPP_ID),
          direction:
            getFieldByLogicRole(message, ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION)?.valueLogicRole ===
            'ACTIVITY__DIRECTION__INCOMING'
              ? 'INCOMING'
              : 'OUTGOING',
          body: getValueFromLogicRole(message, ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_BODY),
          media: media ? JSON.parse(media) : null,
          isoDate: getValueFromLogicRole(message, ACTIVITY_FIELDS_LOGIC_ROLE.TIME),
          hasTranscription:
            getFieldByLogicRole(message, ACTIVITY_FIELDS_LOGIC_ROLE.TRANSCRIPTION)
              ?.valueLogicRole === `${ACTIVITY_FIELDS_LOGIC_ROLE.TRANSCRIPTION}__YES`,
          caseId: getValueFromLogicRole(message, 'ACTIVITY__CASE_ID'),
          caseNumber: getValueFromLogicRole(message, 'ACTIVITY__CASE_NUMBER'),
          user: getValueFromLogicRole(message, ACTIVITY_FIELDS_LOGIC_ROLE.USER, true),
        };
      }) || [];

    // Then, group them by day
    const groupedByDay: MessagesPerDay = {};
    for (const message of parsedMessages) {
      const isoKey = getISODate(message.isoDate);
      const formattedDateKey = formatDateWs(message.isoDate, lang, timeZone, t);

      if (!groupedByDay[isoKey]) {
        groupedByDay[isoKey] = {
          formattedDate: formattedDateKey,
          messages: [],
        };
      }
      groupedByDay[isoKey].messages.push(message);
    }

    // Order keys(date) and messages by date
    return orderByDate(groupedByDay);
  } else {
    return whatsappMessages ? orderByDate(whatsappMessages) : {};
  }
}

const countMessages = (messages: MessagesPerDay): number => {
  if (!messages) {
    return 0;
  }
  return Object.values(messages || {}).reduce((acc, day) => acc + day?.messages?.length, 0);
};

const mergeMessages = (messages, newParseMessage) => {
  const newMessages = orderByDate(newParseMessage);
  const currentMessages = { ...messages };
  const key = Object.keys(newMessages)[0];
  if (currentMessages[key]) {
    // Si el body es igual, no se añade
    for (const newMessage of newMessages[key].messages) {
      const existingMessages = currentMessages[key].messages;
      const existingMessage = existingMessages.find(message => message.wid === newMessage.wid);
      if (!existingMessage) {
        currentMessages[key].messages = [...existingMessages, newMessage];
      } else {
        // Si el mensaje ya existe, se le añade el id del nuevo mensaje para que no se note el cambio
        currentMessages[key].messages = existingMessages.map(message =>
          message.wid === newMessage.wid
            ? {
                ...message,
                id: newMessage.id,
              }
            : message,
        );
      }
    }
  } else {
    currentMessages[key] = newMessages[key];
  }
  return currentMessages;
};

export const useWhatsappMessages = (
  channel: 'WHATSAPP' | 'WHATSAPP_BUSINESS',
  leadId: BobjectId<BobjectTypes.Lead>['value'],
  accountId: string,
  subscribeListeners,
  relatedObjectId?: string,
): WhatsappMessages => {
  const { getCustomTaskByLogicRole } = useCustomTasks();
  const { i18n, t } = useTranslation();
  const whatsappCustomTask = getCustomTaskByLogicRole(channel);
  const lang = i18n.language;
  const [newMessages, setNewMessage] = useState<MessagesPerDay>({});
  const isShowWhatsappConversationHistory = useIsShowWhatsappConversationHistory();

  useEffect(() => {
    setNewMessage({});
  }, [accountId, leadId, whatsappCustomTask?.id]);
  //TODO: Handle next page
  const { data: whatsappMessages, mutate } = useSWR(
    (leadId || relatedObjectId) &&
      `${accountId}${leadId ? '-' + leadId : ''}${
        whatsappCustomTask?.id ? '-' + whatsappCustomTask?.id : ''
      }${relatedObjectId ? '-' + relatedObjectId : ''}`,
    () =>
      fetchWhatsappMessages({
        accountId,
        leadId,
        customTaskId: whatsappCustomTask?.id,
        relatedObject: relatedObjectId,
        noIdsCallback: () =>
          createToast({
            type: 'error',
            message: 'Conversation could not be loaded due to missing object reference',
          }),
        isShowWhatsappConversationHistory,
      }),
  );

  subscribeListeners?.(BobjectTypes.Activity, mutate);
  useEventSubscription('data-Activity', data => {
    if (
      (data?.operation === 'CREATE' || data?.operation === 'UPDATE') &&
      (data?.relatedLead === leadId || (!leadId && relatedObjectId))
    ) {
      mutate();
    }
  });
  let messages = parseMessages(whatsappMessages, lang, t);

  if (Object.keys(newMessages)?.length > 0) {
    messages = orderByDate(mergeMessages(messages, newMessages));
  }

  const isLoading = !whatsappMessages;
  const totalMatching = whatsappMessages?.totalMatching || countMessages(whatsappMessages);

  const saveNewMessage = newMessage => {
    setNewMessage(messages => {
      return mergeMessages(messages, newMessage);
    });
  };

  // change state of message to sent
  const updateMessageState = (messageId, state, wid) => {
    setNewMessage(messages => {
      // Find the message, delete and add it again with the new state
      const newMessages = { ...messages };
      for (const key of Object.keys(newMessages)) {
        const messages = newMessages[key].messages;
        const messageIndex = messages.findIndex(message => message.id === messageId);
        if (messageIndex !== -1) {
          const message = messages[messageIndex];
          message.state = state;
          if (wid && !message.wid) message.wid = wid;
          messages[messageIndex] = message;
          break;
        }
      }
      return newMessages;
    });
  };

  return {
    messages,
    isLoading,
    totalMatching,
    mutate,
    saveNewMessage,
    updateMessageState,
  };
};
