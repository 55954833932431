import React from 'react';

import { AiModel } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

interface IUseAiModelsProps {
  models: Map<string, AiModel>;
  modelsForSelect: { id: string; name: string; enabled: boolean }[];
  defaultModel: AiModel;
}

const AI_MODELS_BASE_PATH = '/utils/service/ai-models';
export const TOKEN_COST_MULTIPLIER = 1000000;

export const useAiModels = (): IUseAiModelsProps => {
  const models = new Map<string, AiModel>();

  const { data } = useSWR(AI_MODELS_BASE_PATH, key =>
    api.get<AiModel[]>(key).then(res => res.data),
  );
  let defaultModel: AiModel;
  if (data) {
    data.forEach(mod => {
      if (mod.default) {
        defaultModel = mod;
      }
      models.set(mod.apiName, mod);
    });
  }
  const modelsForSelect = [...models.values()].map(model => ({
    id: model.apiName,
    name: `${model.name} - (€ ${model.costPerToken * TOKEN_COST_MULTIPLIER})`,
    enabled: true,
  }));
  return { models, modelsForSelect, defaultModel };
};
