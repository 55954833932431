import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Icon, Text } from '@bloobirds-it/flamingo-ui';
import { useExtractSignatures, useUserHelpers } from '@bloobirds-it/hooks';
import { SignaturesKeys } from '@bloobirds-it/types';

import { QuickStartGuideBlock, useStepContext } from '../otoConnectionsGuide';
import styles from './otoQSGSteps.module.css';

type SignaturesMessageProps = {
  titleKey: string;
  subtitleKey: string;
  icon: React.ReactNode;
};

const SignaturesMessage: React.FC<SignaturesMessageProps> = ({ titleKey, subtitleKey, icon }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.signaturesMessage}>
      <Text color="peanut" size="m" weight="bold">
        {t(titleKey)} {icon}
      </Text>
      <Text color="peanut" size="s" weight="medium">
        {t(subtitleKey)}
      </Text>
    </div>
  );
};

const SignaturesSkipped = () => (
  <SignaturesMessage
    titleKey="quickStartGuide.oto.blocks.signatures.onSkippedTitle"
    subtitleKey="quickStartGuide.oto.blocks.signatures.onSkippedSubtitle"
    icon="✉️"
  />
);

const SignaturesCreating = () => (
  <SignaturesMessage
    titleKey="quickStartGuide.oto.blocks.signatures.onLoadingTitle"
    subtitleKey="quickStartGuide.oto.blocks.signatures.onLoadingSubtitle"
    icon="✨"
  />
);

const SignaturesFinished = () => (
  <SignaturesMessage
    titleKey="quickStartGuide.oto.blocks.signatures.onCompletedTitle"
    subtitleKey="quickStartGuide.oto.blocks.signatures.onCompletedSubtitle"
    icon="✏️"
  />
);

type Props = {
  setIsOpen: (value: boolean) => void;
  step: QuickStartGuideBlock;
};

export const BringSignatures = ({ step }: Props) => {
  const { t } = useTranslation();
  const { save } = useUserHelpers();
  const { extractSignatures } = useExtractSignatures();
  const { setStateSignatures, stateSignatures } = useStepContext();

  switch (stateSignatures) {
    case SignaturesKeys.DECLINED:
      return <SignaturesFinished />;
    case SignaturesKeys.ACCEPTED:
      return <SignaturesCreating />;
    case SignaturesKeys.SKIPPED:
      return <SignaturesSkipped />;
    default:
      break;
  }

  return (
    <div className={styles.container}>
      <div className={styles.emailHelper}>
        <Text color="peanut" size="s" weight="medium">
          <Trans i18nKey="quickStartGuide.oto.blocks.signatures.info" />
        </Text>
        <div className={styles.infoContainer}>
          <Icon name="required" color="verySoftPeanut" size={16} />
          <Text color="peanut" size="xs" weight="medium">
            <Trans i18nKey="quickStartGuide.oto.blocks.signatures.info2" />
          </Text>
        </div>
        <div className={styles.infoContainer}>
          <Icon name="required" color="verySoftPeanut" size={16} />
          <Text color="peanut" size="xs" weight="medium">
            <Trans i18nKey="quickStartGuide.oto.blocks.signatures.info3" />
          </Text>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          variant="secondary"
          onClick={() => {
            setStateSignatures(SignaturesKeys.DECLINED);
            save(step.key);
          }}
        >
          {t('quickStartGuide.oto.blocks.signatures.no')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setStateSignatures(SignaturesKeys.ACCEPTED);
            extractSignatures();
            save(step.key);
          }}
        >
          {t('quickStartGuide.oto.blocks.signatures.yes')}
        </Button>
      </div>
      <div className={styles.signaturesInfo}>
        <Icon name="info" size={24} />
        <Text size="s">
          <Trans
            components={{
              gmail: (
                <a
                  href="https://support.bloobirds.com/hc/en-us/articles/360015525720-How-can-I-copy-my-Gmail-signature-in-HTML"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Gmail
                </a>
              ),
              outlook: (
                <a
                  href="https://support.bloobirds.com/hc/en-us/articles/360015532860-How-can-I-copy-my-Outlook-signature-in-HTML"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Outlook
                </a>
              ),
            }}
            i18nKey="quickStartGuide.oto.blocks.knowledgeBaseLinks"
          />
        </Text>
        <div></div>
      </div>
    </div>
  );
};
