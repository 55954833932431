import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconButton, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import { NoteEditor } from '@bloobirds-it/notes';
import { ACTIVITY_FIELDS_LOGIC_ROLE, Bobject } from '@bloobirds-it/types';
import { getTextFromLogicRole, linkify, removeHtmlTags } from '@bloobirds-it/utils';

import styles from './note.module.css';

const Note = ({ activity }: { activity: Bobject }) => {
  const { t } = useTranslation();
  const [copyTooltip, setCopyTooltip] = useState(t('copyText.copyToClipboard'));

  const note = getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.NOTE);
  const [data, setData] = useState(note);

  useEffect(() => {
    setData(note);
  }, [note]);

  const onCopy = () => {
    navigator.clipboard.writeText(removeHtmlTags(note)).then(() => {
      setCopyTooltip(t('copyText.copied'));
      setTimeout(() => setCopyTooltip(t('copyText.copyToClipboard')), 1000);
    });
  };

  return (
    <div className={styles.note}>
      <div className={styles.noteTitle}>
        <div className={styles.noteSection}>
          <Icon name="note" color="purple" />
          <Text size="s" weight="heavy">
            {t('ai.aiAnalysis.note')}
          </Text>
        </div>
        {note && (
          <div className={styles.titleSection}>
            <Tooltip title={copyTooltip} position="top">
              <IconButton name="copy" color="purple" onClick={onCopy} size={16} />
            </Tooltip>
          </div>
        )}
      </div>
      <NoteEditor
        expand
        id={activity?.id.value}
        data={data}
        setData={setData}
        openFloatingNote={false}
      />
    </div>
  );
};

export default Note;
