import { useTranslation } from 'react-i18next';

import { IconType } from '@bloobirds-it/flamingo-ui';
import { PlaybookTab } from '@bloobirds-it/types';

export function getTabIcon(tabSelected: PlaybookTab): IconType {
  switch (tabSelected) {
    case PlaybookTab.SNIPPETS:
      return 'snippet';
    case PlaybookTab.PITCHES:
      return 'chat';
    case PlaybookTab.EMAILS:
      return 'mail';
    case PlaybookTab.LINKEDIN:
      return 'linkedin';
    case PlaybookTab.WHATSAPP:
      return 'whatsapp';
    case PlaybookTab.SMS:
      return 'chat';
    case PlaybookTab.QQS:
      return 'chatSupport';
    default:
      return 'questionCircle';
  }
}

export const getButtonProps = (
  tabSelected,
  disabled,
): { iconLeft: IconType; text: string; disabled?: boolean } => {
  const { t } = useTranslation();
  switch (tabSelected) {
    case PlaybookTab.PITCHES:
      return {
        iconLeft: 'eye',
        text: t('playbook.card.view'),
      };
    case PlaybookTab.EMAILS:
      return {
        iconLeft: 'send',
        text: t('playbook.card.send'),
        disabled,
      };
    case PlaybookTab.SNIPPETS:
      return {
        iconLeft: 'fileInsert',
        text: t('playbook.card.insert'),
        disabled,
      };
    case PlaybookTab.LINKEDIN:
      return {
        iconLeft: 'linkedin',
        text: t('playbook.card.send'),
        disabled,
      };
    case PlaybookTab.WHATSAPP:
      return {
        iconLeft: 'whatsapp',
        text: t('playbook.card.send'),
        disabled,
      };
    case PlaybookTab.SMS:
      return {
        iconLeft: 'chat',
        text: t('playbook.card.send'),
        disabled,
      };
  }
};
