import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormField, TextAreaField, NativeNumberField } from '@bloobirds-it/bobjects';
import {
  Button,
  Icon,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Spinner,
  Text,
  Tooltip,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import {
  CopilotInsight,
  ACTIVITY_TYPE_OPTIONS,
  INSIGHT_TYPE_OPTIONS,
  InsightType,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  INSIGHT_CATEGORY_OPTIONS,
} from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import clsx from 'clsx';
import { mutate } from 'swr';

import { useActivityFieldsInfo } from '../hooks/useActivityFieldsInfo';
import { useAiModels } from '../hooks/useAiModels';
import styles from './insightFormModal.module.css';

interface IInsightFormModal {
  onClose: () => void;
  mode?: 'edit' | 'create';
  currentInsight?: CopilotInsight;
  onSave?: (newValue: CopilotInsight) => void;
}
export const InsightFormModal = ({
  onClose,
  mode = 'create',
  currentInsight = new CopilotInsight({}),
  onSave,
}: IInsightFormModal) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.insightFormModal',
  });
  const { t: baseT } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const { createToast } = useToasts();
  const { modelsForSelect, defaultModel } = useAiModels();
  const methods = useForm({ defaultValues: currentInsight.getInsightForCreation() });
  const [goNext, setGoNext] = useState(false);
  const insightType = methods.watch('insightType');
  const { meetingTypes, bobjectFields = [] } = useActivityFieldsInfo(insightType);
  const defaultFormValues = {
    control: methods.control,
    hideLabel: true,
    visible: true,
    enabled: true,
    size: 'small' as const,
    withFormGroup: false,
  };
  const categoryOptions = [
    { id: null, name: '--', enabled: true },
    ...INSIGHT_CATEGORY_OPTIONS(baseT),
  ];
  const activityType = methods.watch('activityType');
  const category = methods.watch('category');

  // TODO: FE-806 Remove this retrocompatibility in new beta
  const subTypes = ['Meeting', ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING].includes(activityType)
    ? [...meetingTypes.values()]
    : [];
  const isCreate = mode === 'create';
  const onSubmit = (data: CopilotInsight) => {
    if (isCreate && !goNext) {
      setGoNext(true);
      return;
    }
    if (!data.modelApiName && defaultModel) {
      data.modelApiName = defaultModel.apiName;
    }
    setSubmitting(true);

    const endpoint = isCreate
      ? api.post<CopilotInsight>('/utils/service/copilot-insights', data)
      : api.patch<CopilotInsight>(`/utils/service/copilot-insights/${currentInsight.id}`, data);

    endpoint
      .then(payload => {
        createToast({
          message: t(isCreate ? 'successCreate' : 'successEdit'),
          type: 'success',
        });
        mutate('api/insights');
        mutate('api/insights/settings');
        if (!isCreate && onSave) {
          onSave(new CopilotInsight(payload?.data));
        }
        onClose();
      })
      .catch(() => {
        createToast({
          message: t(isCreate ? 'errorCreate' : 'errorUpdate'),
          type: 'error',
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const filteredCategories =
    insightType === 'GENERATION'
      ? categoryOptions
      : categoryOptions.filter(cat => cat.id !== 'FOLLOW_UP');

  if (category === 'FOLLOW_UP' && insightType !== 'GENERATION') {
    methods.setValue('category', null);
  }

  return (
    <FormProvider {...methods}>
      <Modal open onClose={!submitting ? onClose : null} width={400}>
        <ModalHeader color="white" size="small">
          <ModalTitle color="purple" icon={isCreate ? 'plus' : 'edit'} size="small">
            <Text size="l" weight="bold">
              {t(isCreate ? 'titleCreate' : 'titleEdit')}
            </Text>
          </ModalTitle>
          <ModalCloseIcon color="purple" size="small" onClick={onClose} />
        </ModalHeader>
        <ModalContent className={styles.contentContainer}>
          {!goNext && (
            <>
              <Text size="s" color="softPeanut">
                {t('generalDetails')}
              </Text>
              <div className={styles.formRow}>
                <Text size="s">{t('name.label')}*</Text>
                <FormField
                  type="TEXT"
                  id="title"
                  formKey="title"
                  name="title"
                  placeholder={t('name.placeholder')}
                  values={[]}
                  required
                  order={0}
                  {...defaultFormValues}
                />
              </div>
              {isCreate && (
                <div className={styles.formRow}>
                  <Text size="s">{t('insightType.label')}*</Text>
                  <FormField
                    type="PICKLIST"
                    id="insightType"
                    formKey="insightType"
                    name="insightType"
                    placeholder={t('insightType.placeholder')}
                    values={INSIGHT_TYPE_OPTIONS(baseT)}
                    required
                    order={1}
                    {...defaultFormValues}
                  />
                </div>
              )}
              <div className={styles.description}>
                <Text size="s">{t('description.label')}</Text>
                <TextAreaField
                  id="description"
                  formKey="description"
                  name="description"
                  placeholder={t('description.placeholder')}
                  rows={8}
                  order={2}
                  values={[]}
                  //@ts-ignore
                  maxLength={250}
                  maxLengthMessage={baseT('bobjects.bobjectForm.maxLengthMessage')}
                  width="100%"
                  {...defaultFormValues}
                />
              </div>
              <Text size="s" color="softPeanut">
                {t('insightDetails')}
              </Text>
              <div className={styles.formRow}>
                <Tooltip
                  position="top"
                  title={t('modelApiName.tooltip')}
                  className={styles.tooltip}
                >
                  <Text size="s">
                    {t('modelApiName.label')}
                    <Icon name="infoFilled" color="lightPurple" size={16} />
                  </Text>
                </Tooltip>
                <FormField
                  type="PICKLIST"
                  id="modelApiName"
                  formKey="modelApiName"
                  name="modelApiName"
                  placeholder={defaultModel?.name}
                  values={modelsForSelect}
                  order={5}
                  {...defaultFormValues}
                />
              </div>
              {isCreate && (
                <div className={styles.formRow}>
                  <Tooltip
                    position="top"
                    title={t('temperature.tooltip')}
                    className={styles.tooltip}
                  >
                    <Text size="s">
                      {t('temperature.label')}
                      <Icon name="infoFilled" color="lightPurple" size={16} />
                    </Text>
                  </Tooltip>
                  <NativeNumberField
                    id="temperature"
                    formKey="temperature"
                    name="temperature"
                    placeholder={t('temperature.placeholder')}
                    min={0}
                    max={1}
                    step={0.01}
                    {...defaultFormValues}
                  />
                </div>
              )}
              <div className={styles.formRow}>
                <Text size="s">
                  {t('activityField.label')}
                  {insightType === InsightType.DECISION && '*'}
                </Text>
                <FormField
                  type="PICKLIST"
                  id="activityField"
                  formKey="activityField"
                  name="activityField"
                  placeholder={t('activityField.placeholder')}
                  values={bobjectFields}
                  required={insightType === InsightType.DECISION}
                  order={3}
                  {...defaultFormValues}
                />
              </div>
              <div className={styles.formRow}>
                <Tooltip position="top" title={t('category.tooltip')} className={styles.tooltip}>
                  <Text size="s">
                    {t('category.label')}
                    <Icon name="infoFilled" color="lightPurple" size={16} />
                  </Text>
                </Tooltip>
                <FormField
                  type="PICKLIST"
                  id="category"
                  formKey="category"
                  name="category"
                  placeholder={t('category.placeholder')}
                  values={filteredCategories}
                  order={3}
                  {...defaultFormValues}
                />
              </div>
              {isCreate && (
                <div className={styles.formRow}>
                  <Text size="s">{t('activityType.label')}*</Text>
                  <FormField
                    type="PICKLIST"
                    id="activityType"
                    formKey="activityType"
                    name="activityType"
                    placeholder={t('activityType.placeholder')}
                    values={ACTIVITY_TYPE_OPTIONS(baseT)?.filter(v => v?.enabled)}
                    required
                    order={4}
                    {...defaultFormValues}
                  />
                </div>
              )}
              <div className={styles.formRow}>
                <Text size="s">{t('activitySubtype.label')}</Text>
                <FormField
                  type="PICKLIST"
                  id="activitySubtype"
                  formKey="activitySubtype"
                  name="activitySubtype"
                  placeholder={t('activitySubtype.placeholder')}
                  values={[ACTIVITY_TYPE_OPTIONS(baseT)[0], ...subTypes]}
                  order={5}
                  {...defaultFormValues}
                  enabled={!!activityType}
                />
              </div>
            </>
          )}
          {isCreate && goNext && (
            <>
              <Text size="s" color="softPeanut">
                {t('prompt.label')}
              </Text>
              <TextAreaField
                id="prompt"
                formKey="prompt"
                name="prompt"
                placeholder={t('prompt.placeholder')}
                rows={20}
                order={1}
                values={[]}
                width="100%"
                {...defaultFormValues}
              />
            </>
          )}
        </ModalContent>
        <ModalFooter className={styles.footer}>
          {goNext && (
            <Button
              uppercase={false}
              onClick={() => setGoNext(false)}
              size="small"
              variant="secondary"
              color="purple"
            >
              {t('back')}
            </Button>
          )}
          <Button
            uppercase={false}
            onClick={methods.handleSubmit(onSubmit)}
            size="small"
            color="purple"
            className={clsx({ [styles.loadingButton]: submitting })}
          >
            {submitting ? (
              <Spinner name="dots" size={16} color="white" />
            ) : (
              t(isCreate ? (goNext ? 'confirmCreate' : 'next') : 'confirmEdit')
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};
