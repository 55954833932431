// TERMS_EN.ts
export const TERMS_EN = `
# TERMS AND CONDITIONS
**Last Updated:** 1st January 2025

These Terms and Conditions (hereinafter, "**T&C**") govern the use of the Bloobirds/Harmonix platform (the "**Platform**"). By accessing or using the Platform, you acknowledge that you have read, understood, and agree to be bound by these T&C and by our [Privacy Policy](https://www.bloobirds.com/privacy-policy) (or [https://www.harmonix.ai/privacy-policy](https://www.harmonix.ai/privacy-policy)).

- If you are using the Platform on behalf of an organization, you confirm that you have the authority to bind that organization to these T&C.
- If you (or your organization) do not accept these T&C or the Privacy Policy, you must immediately **discontinue** use of the Platform.

We recommend printing a copy of these T&C for future reference.

---

## 1. WHO WE ARE AND HOW TO CONTACT US

1.1. **Entity**
The Platform (including all features under the Bloobirds and Harmonix brands) is owned and managed by **BLOOBIRDS, S.L.** (hereinafter, "**Bloobirds/Harmonix**" or "**we**"), a Spanish company registered under number B-67294611, with registered office at Carrer de Lluçà 28, 2º, 08028 Barcelona, Spain.

1.2. **Contact**
You can reach us at [info@bloobirds.com](mailto:info@bloobirds.com) (or the email address stated in your Order Form).

---

## 2. DESCRIPTION OF THE PLATFORM

2.1. **Purpose**
The Platform is a software tool designed to help companies better manage marketing and sales processes. It offers functionalities such as:

- **Call processing and call queues**
- **Artificial Intelligence (AI)** features for data insights (optional)
- **Multichannel communication** (email, WhatsApp, SMS)
- **Dialing phone numbers** and managing call logs
- **Saving notes, contact details, recordings, and other key commercial data**

2.2. **Access and Authorized Users**
- Access to the Platform is limited to those "**Authorized Users**" listed in your Order Form (e.g., employees or suppliers).
- Each User must register with a unique username and password, and **accept** these T&C and our Privacy Policy upon registration.

2.3. **Configuration Settings**
You can configure various features in the Platform to suit your needs, such as:
- **Call recordings**: You decide whether to enable or disable call recording. You assume sole responsibility for any required consent or legal compliance related to call recording.
- **Data protection obligations**: You can automatically include your own data protection information in email communications. Although we may provide template clauses, you remain responsible for verifying their adequacy under applicable law.
- **Phone numbers**: You must comply with all local regulations for acquiring and using phone numbers in your jurisdiction.

2.4. **ISO 27001 Certification**
We maintain **ISO 27001** certification, aligning with industry-standard security practices to protect the information you store on the Platform.

---

## 3. OUR SERVICES

Subject to these T&C and the Order Form, we provide you with:

1. **Access to the Platform** for the number of Authorized Users specified in the Order Form.
2. A **license** (see Clause 9) granting the necessary rights for your Authorized Users to use the Platform.
3. **Technical assistance and support** regarding usage of the Platform.
4. **Maintenance** and updates of the Platform, as outlined in Clause 11.

> We do **not** provide legal advice through the Platform. Any suggestions or guidelines within the Platform (including AI-driven recommendations) are for informational purposes only, and you bear sole responsibility for any resulting compliance or consequences.

---

## 4. ADDITIONAL / EXTRA SERVICES

You may request extra services (e.g., advanced AI analytics, specialized integrations). These extra services, once contracted, will be governed by these T&C, but you may need to accept additional clauses or an additional Order Form specific to each extra service.

---

## 5. REPRESENTATIONS AND WARRANTIES

We represent and warrant that:

1. The Platform will help you better manage your commercial and marketing actions (as reasonably expected from the functionalities described).
2. The Platform includes the features outlined in these T&C (e.g., call processing, multichannel communications, etc.).
3. The information you upload is protected by industry-standard security measures, consistent with our **ISO 27001** certification.
4. The Platform does not knowingly infringe any third-party intellectual property rights.
5. We will provide timely maintenance and support services.

We do not guarantee the Platform or its content will always be available or free from interruption. We may suspend, withdraw, or restrict availability for business or operational reasons.

---

## 6. YOUR OBLIGATIONS

You undertake to:

1. Use the Platform in accordance with these T&C, the applicable law, and **good faith**.
2. Comply with our [Privacy Policy](https://www.bloobirds.com/privacy-policy).
3. Ensure that your Authorized Users also comply with these T&C and the Privacy Policy.
4. Not exceed the number of Authorized Users specified in the Order Form.
5. Only authorize employees or suppliers from your company (or group) to access the Platform.
6. Pay all fees outlined in the Order Form.
7. Maintain confidentiality of passwords and the information on the Platform (see Clause 14).
8. Grant us a license to host and process your data (see Clause 10).

You agree to **hold us harmless** from any claim, fine, penalty, or sanction arising from your (or your Authorized Users’) breach of these T&C.

---

## 7. OBLIGATIONS AND PROHIBITIONS FOR AUTHORIZED USERS

### 7.1 Obligations

Authorized Users must:

1. Keep their username and password confidential and create a strong password.
2. Use the Platform in accordance with the law, these T&C, and the Privacy Policy.
3. Comply with applicable **data protection laws**, including obtaining required consents and respecting recipient opt-outs (e.g., local “Robinson Lists”).
4. Maintain confidentiality of the information stored in the Platform, respecting Clause 14.

### 7.2 Prohibitions

Users shall **not**:

- Use the Platform for any unlawful activity or to infringe the rights of third parties.
- Upload, transmit, or distribute content (text, audio, images, etc.) that is illegal, defamatory, harmful, or violates public order.
- Introduce viruses, malware, or malicious code into the Platform.
- Attempt to gain unauthorized access to the Platform, servers, or databases.
- Perform denial-of-service attacks or any activity that disrupts Platform functionality.
- Infringe upon data protection regulations or the rights and freedoms of any data subject.

---

## 8. REMOVAL OF “DISSOCIATED DATA” PROVISION

We do **not** share or distribute your data —either aggregated or disaggregated— with any third party not included in our subprocessors, including other customers. Any analytics or reporting features within the Platform are solely for your own internal use and benefit. Refer to our Privacy Policy for details on data handling.

---

## 9. PLATFORM LICENSE AGREEMENT

We grant you a **limited**, **non-exclusive**, **non-transferable**, **non-sublicensable**, and **revocable** license to use the Bloobirds/Harmonix Platform for the number of Authorized Users specified in the Order Form, solely for the purposes described in these T&C.

- **Scope**:
  - **Material**: You and your Users receive only the intellectual property rights necessary to use the Platform as intended.
  - **Territory**: Worldwide.
  - **Duration**: As indicated in the Order Form, typically renewed annually unless terminated.

This license does not constitute a transfer of intellectual property rights. You are **not** allowed to rent, lease, sublicense, redistribute, or create derivative works of the Platform. Nor may you **reverse-engineer** or **disassemble** the software.

We warrant we are the rightful copyright holders of the Platform and will hold you harmless from third-party IP claims directly related to the Platform itself.

---

## 10. LICENSE FROM YOU TO BLOOBIRDS/HARMONIX

10.1 **Hosting Your Data**
You grant us a worldwide, limited-term license to host, copy, transmit, and display the data you upload (the “Material”) **only** as necessary to provide the services under these T&C.

10.2 **Feedback**
You grant us a perpetual, irrevocable, and royalty-free license to incorporate into the Platform any suggestions or feedback you or your Users provide about improving functionality or user experience.

---

## 11. SUPPORT AND MAINTENANCE

We provide technical support and maintenance as stated in the Order Form. Maintenance may include periodic updates, bug fixes, or new feature releases to ensure stable operation of the Platform.

If you request direct support (e.g., for troubleshooting), you authorize our support personnel to access your account solely for diagnostic and issue-resolution purposes, consistent with Clause 15 (Security and Data Access Agreement).

---

## 12. FEES AND PAYMENT TERMS

Fees for the Platform and any additional or extra services are set forth in the Order Form. All payments must be made according to the schedule and conditions described therein. **No** refunds are provided for fees already charged unless otherwise stated in the Order Form.

---

## 13. TERM AND TERMINATION

13.1 **Duration**
These T&C remain in force for the period indicated in your Order Form. Unless otherwise specified, the Agreement typically renews automatically for additional one-year terms.

13.2 **Termination by You**
You may terminate by providing at least [X] months’ written notice (as specified in your Order Form).

13.3 **Termination by Us**
We may terminate if you breach these T&C or the Privacy Policy, or fail to remit due payment.

13.4 **Consequences**
Termination of these T&C ends all rights to use the Platform. No prepaid fees will be refunded unless explicitly agreed in writing.

---

## 14. CONFIDENTIALITY

14.1 **Definition**
“Confidential Information” means any information disclosed (in written, oral, or any form) that is clearly identified as confidential or that should reasonably be understood as confidential due to its nature.

14.2 **Obligations**
The Receiving Party shall:
1. Use the same care to protect the Disclosing Party’s Confidential Information as it uses for its own (not less than a reasonable standard).
2. Not use Confidential Information for purposes outside the scope of these T&C.
3. Limit access to employees or subcontractors bound by equivalent confidentiality obligations.

14.3 **Exceptions**
Confidential Information does not include information that (i) becomes public without breach, (ii) was known prior to disclosure, (iii) is lawfully received from a third party, or (iv) is independently developed without reference to Confidential Information.

14.4 **Legal Compulsion**
If compelled by law to disclose Confidential Information, the Receiving Party shall notify the Disclosing Party unless legally prohibited from doing so.

14.5 **Survival**
Confidentiality obligations remain in effect **indefinitely**, even after termination of these T&C.

---

## 15. SECURITY AND DATA ACCESS AGREEMENT

15.1 **ISO 27001 and Security Measures**
We employ advanced security measures (SSL encryption, firewalls, access logs, etc.) in line with our **ISO 27001** certification, ensuring data confidentiality, integrity, and availability.

15.2 **Support Access**
By accepting these T&C, you grant us permission to access your account solely for troubleshooting or providing support. We will never share your data with any unauthorized third party.

15.3 **User Responsibilities**
You are responsible for:
- Managing User accounts and ensuring usernames/passwords remain confidential.
- Terminating credentials of departing personnel to prevent unauthorized access.
- All actions performed under your or your Users’ credentials.

---

## 16. DISCLAIMERS AND LIABILITY

16.1 **Platform Availability**
We do not warrant the Platform or content will be uninterrupted or error-free. We are not liable for direct or indirect damages arising from downtime, system errors, or unauthorized access, except as mandated by applicable law.

16.2 **Limitation of Liability**
- **No Liability for Force Majeure**: We are not liable for events beyond our reasonable control (e.g., force majeure).
- **Aggregated Liability**: In no event does our total aggregate liability exceed the fees paid by you in the **12 months preceding** the incident. This limitation does not apply in cases of gross negligence or fraud.

16.3 **User’s Indemnification**
You shall indemnify and hold us harmless for claims arising from your or your Authorized Users’ unauthorized or unlawful use of the Platform.

---

## 17. ROLE AS SERVICE/ HOSTING PROVIDER

Pursuant to applicable legislation (e.g., Spanish Law 34/2002 on Information Society Services), we act as a **hosting provider** for any material you upload. We are not responsible for the content you or your Users store or share, unless we become aware of any unlawful content and fail to remove or disable access to it in due time.

When the Platform contains links to third-party websites (e.g., in email templates), these are offered for convenience only. We do not endorse or guarantee those external sites’ accuracy or security.

---

## 18. CHANGES TO THE PLATFORM OR T&C

We may modify these T&C or the Platform at any time to reflect new features, legal requirements, or business priorities.
- If we make significant changes, we will notify you (e.g., via email or an in-app message).
- Continued use of the Platform after such notice constitutes acceptance of the revised T&C.

---

## 19. GOVERNING LAW AND JURISDICTION

These T&C are governed by and construed in accordance with **Spanish law**. You and we irrevocably submit to the **exclusive jurisdiction of the courts of Barcelona**, Spain, to resolve any disputes.

---

## 20. MISCELLANEOUS

- **Severability**: If any provision is deemed invalid, the remaining provisions remain enforceable.
- **No Waiver**: Failure to enforce any right does not constitute waiver.
- **Entire Agreement**: These T&C, together with the Order Form and the Privacy Policy, constitute the entire agreement between you and Bloobirds/Harmonix regarding the Platform.
- **Assignments**: You shall not assign or transfer these T&C without our prior written consent.

---

**Last Updated:** 1st January 2025
© 2025 BLOOBIRDS, S.L. (Brands: Bloobirds & Harmonix). All rights reserved.`;


// TERMS_ES.ts
export const TERMS_ES = `
# TÉRMINOS Y CONDICIONES
**Última Actualización:** 1 de enero de 2025

Estos Términos y Condiciones (en adelante, "**T&C**") regulan el uso de la plataforma Bloobirds/Harmonix (la "**Plataforma**"). Al acceder o utilizar la Plataforma, reconoces que has leído, entendido y aceptas quedar vinculado por estos T&C, así como por nuestra [Política de Privacidad](https://www.bloobirds.com/privacy-policy) (o [https://www.harmonix.ai/privacy-policy](https://www.harmonix.ai/privacy-policy)).

- Si utilizas la Plataforma en nombre de una organización, confirmas que tienes la autoridad para obligar a dicha organización a cumplir estos T&C.
- Si tú (o tu organización) no aceptas estos T&C o la Política de Privacidad, debes **interrumpir** inmediatamente el uso de la Plataforma.

Recomendamos imprimir una copia de estos T&C para futura referencia.

---

## 1. QUIÉNES SOMOS Y CÓMO CONTACTAR

1.1. **Entidad**
La Plataforma (incluyendo todas las funcionalidades bajo las marcas Bloobirds y Harmonix) pertenece y es gestionada por **BLOOBIRDS, S.L.** (en adelante, "**Bloobirds/Harmonix**" o "**nosotros**"), una sociedad española inscrita con el número B-67294611 y con domicilio social en Carrer de Lluçà 28, 2º, 08028 Barcelona, España.

1.2. **Contacto**
Puedes escribirnos a [info@bloobirds.com](mailto:info@bloobirds.com) (o al correo indicado en tu Orden de Compra).

---

## 2. DESCRIPCIÓN DE LA PLATAFORMA

2.1. **Finalidad**
La Plataforma es una herramienta de software diseñada para ayudar a las empresas a gestionar mejor sus procesos de marketing y ventas. Ofrece funcionalidades como:

- **Procesamiento de llamadas y colas de llamadas**
- Funcionalidades de **Inteligencia Artificial (IA)** para análisis de datos (opcional)
- **Comunicación multicanal** (email, WhatsApp, SMS)
- **Marcación de números** y administración de registros de llamadas
- **Almacenamiento de notas, datos de contacto, grabaciones y otra información comercial clave**

2.2. **Acceso y Usuarios Autorizados**
- El acceso a la Plataforma se limita a los "**Usuarios Autorizados**" indicados en tu Orden de Compra (por ejemplo, empleados o proveedores).
- Cada Usuario debe registrarse con un nombre de usuario y contraseña únicos, y **aceptar** estos T&C y nuestra Política de Privacidad al registrarse.

2.3. **Ajustes de Configuración**
Puedes configurar varias funcionalidades de la Plataforma según tus necesidades, por ejemplo:
- **Grabaciones de llamadas**: Decides habilitar o deshabilitar la grabación de llamadas. Eres el único responsable de obtener los consentimientos o cumplir con los requisitos legales necesarios.
- **Obligaciones de protección de datos**: Puedes incluir automáticamente tu propia información de protección de datos en correos electrónicos. Aunque podamos proporcionar cláusulas modelo, eres responsable de verificar su adecuación a la ley aplicable.
- **Números de teléfono**: Debes cumplir con las regulaciones locales para adquirir y usar los números de teléfono en tu jurisdicción.

2.4. **Certificación ISO 27001**
Contamos con certificación **ISO 27001**, alineándonos con prácticas de seguridad estandarizadas para proteger la información que almacenas en la Plataforma.

---

## 3. NUESTROS SERVICIOS

Sujeto a estos T&C y la Orden de Compra, te proporcionamos:

1. **Acceso a la Plataforma** para el número de Usuarios Autorizados especificados en la Orden de Compra.
2. Una **licencia** (ver Cláusula 9) que otorga los derechos necesarios para que los Usuarios Autorizados utilicen la Plataforma.
3. **Asistencia y soporte técnico** sobre el uso de la Plataforma.
4. **Mantenimiento** y actualizaciones de la Plataforma, según la Cláusula 11.

> No ofrecemos asesoría legal a través de la Plataforma. Cualquier sugerencia o guía (incluyendo recomendaciones basadas en IA) es puramente informativa, y tú asumes la responsabilidad de las consecuencias derivadas de su cumplimiento o no.

---

## 4. SERVICIOS ADICIONALES / EXTRAS

Puedes solicitar servicios adicionales (por ejemplo, análisis avanzado con IA, integraciones especializadas). Dichos servicios se regirán por estos T&C, aunque podrías tener que aceptar cláusulas o una Orden de Compra adicionales específicas para cada servicio extra.

---

## 5. DECLARACIONES Y GARANTÍAS

Declaramos y garantizamos que:

1. La Plataforma te ayudará a gestionar de forma más eficiente tus acciones comerciales y de marketing (dentro de lo razonablemente esperado según sus funcionalidades).
2. La Plataforma incluye las características descritas en estos T&C (p.ej., procesamiento de llamadas, comunicaciones multicanal, etc.).
3. La información que subas estará protegida mediante medidas de seguridad acordes con nuestra certificación **ISO 27001**.
4. La Plataforma no infringe, a nuestro leal saber y entender, derechos de propiedad intelectual de terceros.
5. Prestaremos servicios de mantenimiento y soporte en tiempo y forma adecuados.

No garantizamos que la Plataforma o su contenido estén siempre disponibles o libres de interrupciones. Podemos suspender, retirar o restringir su disponibilidad por razones comerciales u operativas.

---

## 6. TUS OBLIGACIONES

Te comprometes a:

1. Usar la Plataforma conforme a estos T&C, la legislación aplicable y **la buena fe**.
2. Cumplir con nuestra [Política de Privacidad](https://www.bloobirds.com/privacy-policy).
3. Asegurar que tus Usuarios Autorizados también cumplen estos T&C y la Política de Privacidad.
4. No exceder el número de Usuarios Autorizados establecido en la Orden de Compra.
5. Autorizar solo a empleados o proveedores de tu empresa (o grupo) para acceder a la Plataforma.
6. Pagar las tarifas indicadas en la Orden de Compra.
7. Mantener la confidencialidad de las contraseñas y de la información incluida en la Plataforma (ver Cláusula 14).
8. Otorgarnos una licencia para alojar y procesar tus datos (ver Cláusula 10).

Aceptas **mantenernos indemnes** ante cualquier reclamación, multa, penalización o sanción derivada del incumplimiento, por parte tuya o de tus Usuarios Autorizados, de estas T&C.

---

## 7. OBLIGACIONES Y PROHIBICIONES PARA LOS USUARIOS AUTORIZADOS

### 7.1 Obligaciones

Los Usuarios Autorizados deben:

1. Mantener en confidencialidad su nombre de usuario y contraseña, creando una contraseña robusta.
2. Usar la Plataforma de conformidad con la ley, estos T&C y la Política de Privacidad.
3. Cumplir con las **leyes de protección de datos** aplicables, incluyendo la obtención de consentimientos necesarios y respeto a las listas de exclusión publicitaria (ej. “Listas Robinson”).
4. Mantener la confidencialidad de la información que se almacene en la Plataforma, respetando la Cláusula 14.

### 7.2 Prohibiciones

Los Usuarios no podrán:

- Utilizar la Plataforma para actividades ilícitas o que perjudiquen derechos de terceros.
- Subir, transmitir o difundir contenido (texto, audio, imágenes, etc.) ilegal, difamatorio, dañino o que atente contra el orden público.
- Introducir virus, malware o cualquier código malicioso en la Plataforma.
- Intentar obtener acceso no autorizado a la Plataforma, servidores o bases de datos.
- Realizar ataques de denegación de servicio o cualquier actividad que dañe la funcionalidad de la Plataforma.
- Infringir normativas de protección de datos o vulnerar derechos y libertades de terceros.

---

## 8. ELIMINACIÓN DE LA DISPOSICIÓN SOBRE “DATOS DISOCIADOS”

No compartimos ni distribuimos tus datos —ya sean agregados o desagregados— con terceros no inlcuidos en nuestros subprocessors, incluyendo otros clientes. Cualquier función de analítica o informes dentro de la Plataforma está destinada exclusivamente a tu uso y beneficio interno. Consulta nuestra Política de Privacidad para detalles sobre el tratamiento de datos.

---

## 9. ACUERDO DE LICENCIA DE LA PLATAFORMA

Te concedemos una licencia **limitada**, **no exclusiva**, **intransferible**, **no sublicenciable** y **revocable** para utilizar la Plataforma Bloobirds/Harmonix en el número de Usuarios Autorizados indicado en la Orden de Compra, únicamente para los fines descritos en estos T&C.

- **Alcance**:
  - **Material**: Tú y tus Usuarios recibís únicamente los derechos de propiedad intelectual necesarios para usar la Plataforma según lo previsto.
  - **Territorio**: Mundial.
  - **Duración**: La indicada en la Orden de Compra, generalmente renovable de forma anual si no se termina el contrato.

Esta licencia no supone la cesión de los derechos de propiedad intelectual. **No** se te permite alquilar, arrendar, sublicenciar, redistribuir ni crear obras derivadas de la Plataforma, ni **desensamblar** o **realizar ingeniería inversa** del software.

Garantizamos que somos titulares de los derechos de autor de la Plataforma y te mantendremos indemne ante reclamaciones de terceros relativas a la propiedad intelectual del software base de la Plataforma.

---

## 10. LICENCIA QUE TÚ OTORGAS A BLOOBIRDS/HARMONIX

10.1 **Alojamiento de tus Datos**
Nos otorgas una licencia mundial y de duración limitada para alojar, copiar, transmitir y mostrar los datos que subas (el “Material”), **exclusivamente** según sea necesario para brindar los servicios bajo estos T&C.

10.2 **Comentarios y Sugerencias**
Nos concedes una licencia perpetua, irrevocable y libre de regalías para incorporar a la Plataforma cualquier sugerencia o retroalimentación que tú o tus Usuarios provean sobre la mejora de la funcionalidad o la experiencia de usuario.

---

## 11. SOPORTE Y MANTENIMIENTO

Proveemos soporte técnico y servicios de mantenimiento según lo establecido en la Orden de Compra. El mantenimiento puede incluir actualizaciones periódicas, correcciones de errores o lanzamiento de nuevas funciones para asegurar el funcionamiento estable de la Plataforma.

Si solicitas soporte directo (ej. resolución de incidencias), autorizas a nuestro personal de soporte a acceder a tu cuenta exclusivamente con fines de diagnóstico y solución de problemas, de acuerdo con la Cláusula 15 (Acuerdo de Seguridad y Acceso a Datos).

---

## 12. TARIFAS Y TÉRMINOS DE PAGO

Las tarifas de la Plataforma y cualquier servicio adicional se establecen en la Orden de Compra. Todos los pagos deben realizarse conforme al calendario y condiciones descritas en dicha Orden. **No** se reembolsarán las cantidades ya facturadas, salvo que la Orden de Compra lo especifique expresamente.

---

## 13. DURACIÓN Y TERMINACIÓN

13.1 **Vigencia**
Estos T&C permanecen vigentes durante el período indicado en tu Orden de Compra. Salvo que se especifique lo contrario, el contrato suele renovarse automáticamente por períodos anuales.

13.2 **Terminación por tu parte**
Puedes resolver el contrato notificándonos con al menos [X] meses de antelación (según se indique en tu Orden de Compra).

13.3 **Terminación por nuestra parte**
Podemos terminar el contrato si incumples estos T&C o la Política de Privacidad, o si no abonas las tarifas correspondientes.

13.4 **Efectos**
La terminación de estos T&C supone la finalización de todos los derechos de uso de la Plataforma. No se reembolsarán tarifas ya abonadas, salvo acuerdo escrito en contrario.

---

## 14. CONFIDENCIALIDAD

14.1 **Definición**
“Información Confidencial” significa cualquier información divulgada (por escrito, oral o de cualquier forma) designada como confidencial o que razonablemente deba entenderse como tal dada su naturaleza.

14.2 **Obligaciones**
La Parte Receptora debe:
1. Utilizar el mismo cuidado para proteger la Información Confidencial que emplea con su propia información (no menor que un estándar razonable).
2. No usar dicha Información Confidencial para fines ajenos al alcance de estos T&C.
3. Limitar el acceso a empleados o subcontratistas sujetos a obligaciones de confidencialidad equivalentes.

14.3 **Excepciones**
No se considerará Información Confidencial aquella que (i) sea o pase a ser pública sin infracción, (ii) fuese conocida antes de su divulgación, (iii) se reciba de un tercero legítimamente, o (iv) se desarrolle independientemente sin referencia a la Información Confidencial.

14.4 **Obligación Legal**
Si la Parte Receptora se ve obligada por ley a divulgar Información Confidencial, deberá notificarlo a la Parte Divulgadora, salvo que la ley lo prohíba.

14.5 **Vigencia**
Las obligaciones de confidencialidad permanecen en vigor **indefinidamente**, incluso tras la terminación de estos T&C.

---

## 15. ACUERDO DE SEGURIDAD Y ACCESO A DATOS

15.1 **ISO 27001 y Medidas de Seguridad**
Empleamos medidas de seguridad avanzadas (cifrado SSL, firewalls, registros de acceso, etc.) en línea con nuestra certificación **ISO 27001**, asegurando la confidencialidad, integridad y disponibilidad de tus datos.

15.2 **Acceso para Soporte**
Al aceptar estos T&C, nos concedes permiso para acceder a tu cuenta exclusivamente con fines de soporte o resolución de incidencias. Nunca compartiremos tus datos con terceros no autorizados.

15.3 **Responsabilidades del Usuario**
Eres responsable de:
- Gestionar las cuentas de tus Usuarios y asegurar la confidencialidad de sus credenciales.
- Revocar las credenciales de personal que se desvincule de tu organización.
- Todas las acciones realizadas bajo tus credenciales o las de tus Usuarios.

---

## 16. EXENCIONES Y RESPONSABILIDAD

16.1 **Disponibilidad de la Plataforma**
No garantizamos que la Plataforma o su contenido estén libres de errores o interrupciones. No somos responsables de daños directos o indirectos derivados de caídas de servicio, errores del sistema o accesos no autorizados, salvo lo exigido por la ley aplicable.

16.2 **Limitación de Responsabilidad**
- **Fuerza Mayor**: No respondemos por eventos fuera de nuestro control razonable (p.ej., fuerza mayor).
- **Responsabilidad Máxima**: En ningún caso nuestra responsabilidad total excederá las tarifas que hayas pagado en los **12 meses anteriores** al incidente. Esta limitación no se aplica en casos de negligencia grave o fraude.

16.3 **Indemnización del Usuario**
Deberás indemnizarnos y mantenernos indemnes frente a reclamaciones derivadas de un uso no autorizado o ilícito de la Plataforma por tu parte o la de tus Usuarios.

---

## 17. ROL COMO PROVEEDOR / ALOJAMIENTO

De acuerdo con la legislación aplicable (p.ej., Ley 34/2002 de Servicios de la Sociedad de la Información en España), actuamos como **proveedor de alojamiento** del material que subes. No somos responsables del contenido que tú o tus Usuarios almacenéis o compartáis, salvo que tengamos conocimiento efectivo de su ilicitud y no lo retiremos a tiempo.

Si la Plataforma contiene enlaces a sitios web de terceros (p.ej., en plantillas de email), se ofrecen solo para tu conveniencia. No avalamos ni garantizamos la exactitud o seguridad de esos sitios externos.

---

## 18. CAMBIOS EN LA PLATAFORMA O EN LOS T&C

Podemos modificar estos T&C o la Plataforma en cualquier momento para reflejar nuevas funcionalidades, requisitos legales o prioridades de negocio.
- Si realizamos cambios relevantes, te notificaremos (p.ej., por email o mensaje en la app).
- El uso continuado de la Plataforma tras dicha notificación implica la aceptación de las T&C revisadas.

---

## 19. LEY APLICABLE Y JURISDICCIÓN

Estos T&C se rigen e interpretan de acuerdo con la **legislación española**. Tú y nosotros nos sometemos de forma irrevocable a la **jurisdicción exclusiva de los juzgados y tribunales de Barcelona**, España, para resolver cualquier controversia.

---

## 20. MISCELÁNEA

- **Divisibilidad**: Si alguna cláusula se declara inválida, el resto permanecerá en vigor.
- **No Renuncia**: La falta de ejercicio de un derecho no constituye renuncia.
- **Acuerdo Completo**: Estos T&C, junto con la Orden de Compra y la Política de Privacidad, constituyen el acuerdo íntegro entre Bloobirds/Harmonix y tú en relación con la Plataforma.
- **Cesión**: No podrás ceder estos T&C sin nuestro consentimiento escrito.

---

**Última Actualización:** 1 de enero de 2025
© 2025 BLOOBIRDS, S.L. (Marcas: Bloobirds & Harmonix). Todos los derechos reservados.

`;
