import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAiModels, TOKEN_COST_MULTIPLIER } from '@bloobirds-it/copilot';
import { Icon, Tooltip, IconButton, Select, Item, Spinner, Text } from '@bloobirds-it/flamingo-ui';
import { useLoadingPromise } from '@bloobirds-it/hooks';
import { AiModel, GeneralPrompt } from '@bloobirds-it/types';

import { useGeneralPrompts } from '../../hooks/useGeneralPrompts';
import styles from '../../playgroundPages.module.css';

interface AiModelFieldProps {
  model: AiModel;
  editModel: boolean;
  disableEdit: boolean;
  toggle: () => void;
  selectedPrompt: GeneralPrompt;
}
export const AiModelField = ({
  model,
  editModel,
  disableEdit,
  toggle,
  selectedPrompt,
}: AiModelFieldProps) => {
  const [selectedModel, setSelectedModel] = useState<string>(model?.apiName);
  const { t } = useTranslation();
  const { modelsForSelect } = useAiModels();

  const { updatePrompt, reloadAccountPrompts } = useGeneralPrompts();
  const { loading, promiseHandler } = useLoadingPromise();

  const handleSave = () => {
    promiseHandler(
      () =>
        updatePrompt({
          content: selectedPrompt.content,
          promptType: selectedPrompt.promptType,
          modelApiName: selectedModel,
        }),
      {
        onSuccess: () => {
          reloadAccountPrompts();
          toggle();
        },
        successToast: 'ai.playground.generalPromptsTab.updatePrompt.success',
        errorToast: 'ai.playground.generalPromptsTab.updatePrompt.error',
      },
    );
  };
  const handleCancel = () => {
    toggle();
    setSelectedModel(model?.apiName);
  };
  if (!model) return null;

  return (
    <div className={styles.headerField}>
      <Icon name="stars" size={16} color="softPeanut" />
      <Text size="s" weight="bold">
        {t('ai.insightCard.model')}:
      </Text>
      {!editModel ? (
        <>
          <Text size="s">{model.name}</Text>
          <Tooltip title={t('ai.insightCard.tokenPrice')} position="top">
            <Text size="xs" color="softPeanut">
              {t('ai.insightCard.perMillionToken', {
                price: model.costPerToken * TOKEN_COST_MULTIPLIER,
              })}
            </Text>
          </Tooltip>
          <IconButton
            onClick={toggle}
            name="edit"
            color="peanut"
            size={16}
            disabled={disableEdit}
          />
        </>
      ) : (
        <>
          <Select
            autocomplete
            value={selectedModel}
            onChange={setSelectedModel}
            borderless={false}
            width="100%"
            size="small"
          >
            {modelsForSelect.map(option => (
              <Item key={option.id} value={option.id} label={option.name}>
                {option.name}
              </Item>
            ))}
          </Select>
          {!loading ? (
            <>
              <IconButton
                onClick={handleSave}
                name="check"
                color="bloobirds"
                size={16}
                disabled={selectedModel === model?.apiName}
              />
              <IconButton onClick={handleCancel} name="cross" color="peanut" size={16} />
            </>
          ) : (
            <Spinner name="loadingCircle" size={16} />
          )}
        </>
      )}
    </div>
  );
};
