import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, IconButton, Input, Spinner, Text } from '@bloobirds-it/flamingo-ui';
import { usePlaybook, useUserHelpers } from '@bloobirds-it/hooks';
import {
  Environment,
  ExtensionHelperKeys,
  MIXPANEL_EVENTS,
  PlaybookTab,
  TemplateStage,
} from '@bloobirds-it/types';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import mixpanel from 'mixpanel-browser';

import { PlaybookTabs } from '../playbookTabs/playbookTabs';
import { TabContent } from './components/tabContent';
import styles from './playbookFeed.module.css';
import { PlaybookFeedProps } from './playbookFeed.types';
import { PlaybookFeedProvider, usePlaybookFeed } from './usePlaybookFeed';

interface TemplateSearchProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
}

const TemplateSearch = ({ searchValue, setSearchValue }: TemplateSearchProps) => {
  const { t } = useTranslation();
  const [localSearchValue, setLocalSearchValue] = useState(searchValue);

  const debouncedSetSearchValue = useCallback(
    debounce(value => {
      setSearchValue(value);
    }, 300),
    [],
  );

  useEffect(() => {
    debouncedSetSearchValue(localSearchValue);
    return () => {
      debouncedSetSearchValue.cancel();
    };
  }, [localSearchValue, debouncedSetSearchValue]);

  const handleInputChange = useCallback(e => {
    setLocalSearchValue(e);
  }, []);

  const handleClear = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    setLocalSearchValue('');
  }, []);

  return (
    <div className={styles.searchInputWrapper}>
      <Input
        icon="search"
        value={localSearchValue}
        onChange={handleInputChange}
        placeholder={t('playbook.searchTemplates')}
        className={styles.noBorder}
        width="100%"
        size="small"
      />
      {localSearchValue && localSearchValue.length > 0 && (
        <div className={styles.clearButtonWrapper}>
          <IconButton name="cross" onClick={handleClear} color="purple" size={14} />
        </div>
      )}
    </div>
  );
};

const withProvider = (Component: any) => (props: PlaybookFeedProps) => (
  <PlaybookFeedProvider
    props={{ isSmartEmail: props.environment === Environment.SMART_EMAIL, ...props }}
  >
    <Component />
  </PlaybookFeedProvider>
);

export const PlaybookFeedComponent = () => {
  const {
    activeBobject,
    isMainBobjectSalesStage,
    company,
    environment,
    selectedTab = PlaybookTab.PITCHES,
    setSelectedTab = () => {},
    isFilterViewOpen,
    toggleFilterView,
    stage,
    segmentationFields,
    segmentationValues,
    visibilityFilters,
    selectedTeams,
    setFiltersContext,
    sidePeekEnabled = false,
    searchValue,
    setSearchValue,
    headerComponent,
    isSmartEmail,
  } = usePlaybookFeed();
  const { has } = useUserHelpers();
  const { t } = useTranslation();
  const snippetsBlockHidden = has(ExtensionHelperKeys.SNIPPETS_TOOLTIP_BLOCK_HIDDEN);

  const getPlaybookStage: () => TemplateStage = () => {
    if (stage) {
      return stage;
    } else {
      return isMainBobjectSalesStage ? TemplateStage.Sales : TemplateStage.Prospecting;
    }
  };

  const { activeBobjectSegmentationValues } = usePlaybook({
    stage: getPlaybookStage(),
    bobjectData: { company, activeBobject },
  });

  const haveDefaultSegmentationLoaded =
    activeBobjectSegmentationValues && Object.keys(activeBobjectSegmentationValues).length > 0;

  const getHeaderText = () => {
    switch (environment) {
      case Environment.DIALER:
        return t('playbook.pitches');
      case Environment.WHATSAPP_TEMPLATE_SELECTOR:
        return t('playbook.whatsappTemplates');
      case Environment.META_TEMPLATE_SELECTOR:
        return t('playbook.metaTemplates');
      case Environment.LINKEDIN_TEMPLATE_SELECTOR:
        return t('playbook.linkedinTemplates');
      case Environment.SMART_EMAIL:
        return t('playbook.templatesAndSnippets');
      case Environment.SMS_TEMPLATE_SELECTOR:
        return t('playbook.smsTemplates');
      case Environment.EXTENSION:
      default:
        return t('playbook.playbook');
    }
  };

  const handleChangeTab = (tab: PlaybookTab) => {
    setSelectedTab(tab);
    setFiltersContext({
      visibilityFilters,
      segmentationValues,
      stage,
      shouldShowBattlecards: [PlaybookTab.PITCHES, PlaybookTab.SNIPPETS].includes(tab),
      shouldShowVisibilityFilters: tab !== PlaybookTab.QQS,
      selectedTeams,
    });
  };

  const headerText = getHeaderText();

  useEffect(() => {
    if (
      haveDefaultSegmentationLoaded &&
      isMainBobjectSalesStage === [TemplateStage.Sales, TemplateStage.All].includes(stage) &&
      !segmentationValues
    ) {
      setFiltersContext({
        segmentationData: activeBobjectSegmentationValues,
        stage,
        isFilterViewOpen,
        visibilityFilters,
        selectedTeams,
        shouldShowBattlecards: [PlaybookTab.PITCHES, PlaybookTab.SNIPPETS].includes(selectedTab),
        shouldShowVisibilityFilters: selectedTab !== PlaybookTab.QQS,
      });
    }
  }, [haveDefaultSegmentationLoaded]);

  const selectedValuesNames = useMemo(() => {
    if (!segmentationValues) {
      return [];
    }
    return Object.entries(segmentationValues).reduce(
      (acc: [string], [fieldId, itemValues]): Array<string> => {
        // @ts-ignore
        if (itemValues?.length === 0) return acc;

        const segmentationFieldsToSearch =
          stage === TemplateStage.All
            ? [
                ...segmentationFields[TemplateStage.Prospecting],
                ...segmentationFields[TemplateStage.Sales],
              ]
            : segmentationFields?.[stage];
        const segmentationField = segmentationFieldsToSearch?.find(
          segmentation => segmentation.id === fieldId,
        );

        if (!itemValues)
          return [...acc, t('playbook.allAssets', { segmentationName: segmentationField?.name })];
        // @ts-ignore
        const valuesNames = itemValues?.reduce(
          (accField: [string], itemValue: string): Array<string> => {
            const field = segmentationField?.values?.find(segValue => segValue.id === itemValue);
            if (field) return [...accField, field?.name];
            return accField;
          },
          [],
        );

        return [...acc, ...valuesNames];
      },
      [],
    );
  }, [segmentationValues]);

  return (
    <div className={styles.container}>
      {headerComponent}
      <div
        className={clsx(styles.header_container, {
          [styles.header_container_with_tabs]: [
            Environment.EXTENSION,
            Environment.SMART_EMAIL,
          ].includes(environment),
        })}
      >
        <div className={clsx(styles.headerTitle)}>
          <span className={styles.title_container}>
            <Icon name="magic" size={18} color="purple" />
            <Text size="s">{headerText}</Text>
          </span>
          {selectedTab !== PlaybookTab.QQS && (
            <TemplateSearch searchValue={searchValue} setSearchValue={setSearchValue} />
          )}
          {snippetsBlockHidden && selectedTab === PlaybookTab.SNIPPETS && isSmartEmail && (
            <IconButton
              onClick={() => {
                mixpanel.track(MIXPANEL_EVENTS.SNIPPETS_BUTTON_TIP_CLICKED);
                window.open(
                  'https://support.bloobirds.com/hc/en-us/articles/9198098513948-10-snippets-that-will-save-you-a-lot-of-time',
                  '_blank',
                );
              }}
              size={18}
              className={styles.suggestionsButton}
              name="suggestions"
              color="bloobirds"
            />
          )}
        </div>
        {/*extract this*/}
        <div className={styles.contentWrapper}>
          <Button
            size="small"
            color={
              isFilterViewOpen || selectedValuesNames?.length !== 0 ? 'softPurple' : 'lightPurple'
            }
            uppercase={false}
            onClick={() => {
              mixpanel.track(`TOGGLE_SEGMENTATION_FILTERS_FROM_${environment}`);
              toggleFilterView(!isFilterViewOpen, selectedTab);
            }}
          >
            <div className={styles.buttonContent}>
              <div className={styles.buttonText}>
                {selectedValuesNames?.length !== 0 ? (
                  <>
                    <Text size="xs" color="white" weight="bold" className={styles.segmentedFor}>
                      {t('playbook.selectedFilters', { count: selectedValuesNames?.length || 0 })}{' '}
                    </Text>
                    <Text size="xs" color="white" className={styles.selectedValuesNames}>
                      {selectedValuesNames?.join(', ')}
                    </Text>
                  </>
                ) : (
                  <Text size="xs" color="white" weight="bold" className={styles.segmentedFor}>
                    {t('playbook.selectSegmentationCriteria')}
                  </Text>
                )}
              </div>
              <div className={styles.buttonButtons}>
                <Icon name="sliders" color="white" size={16} />
                {Object.keys(segmentationValues || {})?.length > 0 && (
                  <IconButton
                    name="cross"
                    color="white"
                    size={16}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setFiltersContext({
                        segmentationData: {},
                        isFilterViewOpen,
                        stage,
                        visibilityFilters: {
                          onlyMine: false,
                          onlyOfficial: false,
                          onlyPrivate: false,
                          onlyBattlecards: false,
                          metaApproved: false,
                        },
                        selectedTeams: [],
                        shouldShowBattlecards: [PlaybookTab.PITCHES, PlaybookTab.SNIPPETS].includes(
                          selectedTab,
                        ),
                        shouldShowVisibilityFilters: selectedTab !== PlaybookTab.QQS,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </Button>
        </div>
        <PlaybookTabs
          environment={environment}
          tabSelected={selectedTab}
          handleChangeTab={handleChangeTab}
          sidePeekEnabled={sidePeekEnabled}
        />
      </div>
      {segmentationFields ? (
        <TabContent />
      ) : (
        <div className={styles.spinner}>
          <Spinner name="loadingCircle" color="purple" />
        </div>
      )}
    </div>
  );
};

export const PlaybookFeed = withProvider(PlaybookFeedComponent);
