import classNames from 'clsx';
import { useSelected } from 'slate-react';

import styles from './userMention.module.css';

const UserMention = props => {
  const { attributes, children, element } = props;
  const selected = useSelected();

  const classes = classNames(styles.container, {
    [styles.focused]: selected,
  });

  return (
    <span {...attributes}>
      <span
        contentEditable={false}
        className={classes}
        style={
          element.color ? { color: element.color, backgroundColor: 'var(--veryLightPeanut)' } : {}
        }
        // FIX: This onClick event is not working properly (https://linear.app/bloobirds/issue/FE-1067)
        /* onClick={() => {
          comboboxActions.open({
            text: '',
            targetRange: { anchor: editor.selection?.anchor, focus: editor.selection?.focus },
            activeId: ELEMENT_USER_MENTION,
          });
        }} */
      >
        {element.name}
      </span>
      {children}
    </span>
  );
};

export default UserMention;
