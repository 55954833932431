import React, { memo } from 'react';

import { SnippetsTooltip } from '@bloobirds-it/discovery-tooltips';
import { usePermissions, useUserSettings } from '@bloobirds-it/hooks';
import { Environment, PlaybookTab, UserPermission } from '@bloobirds-it/types';

import styles from '../playbookFeed/playbookFeed.module.css';
import { PlaybookTabElement } from './playbookTabElement';

interface PlaybookTabProps {
  environment: Environment;
  tabSelected: PlaybookTab;
  handleChangeTab: (tab: PlaybookTab) => void;
  sidePeekEnabled: boolean;
}

export const PlaybookTabs = memo(
  ({ environment, tabSelected, handleChangeTab, sidePeekEnabled }: PlaybookTabProps) => {
    const props = {
      onClick: handleChangeTab,
      selected: tabSelected,
      sidePeekEnabled,
      environment,
    };

    const settings = useUserSettings();
    const permissions = usePermissions(settings?.user?.permissions);
    switch (environment) {
      case Environment.SMART_EMAIL:
        return (
          <div className={styles.tabs_container}>
            <PlaybookTabElement
              name={PlaybookTab.EMAILS}
              icon="mail"
              dataTest="playbook-tab-emails-SEE"
              {...props}
            />
            <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
              <PlaybookTabElement
                name={PlaybookTab.SNIPPETS}
                icon="snippet"
                dataTest="playbook-tab-snippets-SEE"
                {...props}
              />
              <SnippetsTooltip />
            </div>
          </div>
        );
      case Environment.EXTENSION:
        return (
          <div className={styles.tabs_container}>
            {permissions.get(UserPermission.PHONE_CHANNEL) && (
              <PlaybookTabElement name={PlaybookTab.PITCHES} icon="chat" {...props} />
            )}
            {permissions.get(UserPermission.EMAIL_CHANNEL) && (
              <PlaybookTabElement name={PlaybookTab.SNIPPETS} icon="snippet" {...props} />
            )}
            {permissions.get(UserPermission.EMAIL_CHANNEL) && (
              <PlaybookTabElement name={PlaybookTab.EMAILS} icon="mail" {...props} />
            )}
            {permissions.get(UserPermission.LINKEDIN) && (
              <PlaybookTabElement name={PlaybookTab.LINKEDIN} icon="linkedin" {...props} />
            )}
            {permissions.get(UserPermission.SYNC_WHATSAPP_WEB_MESSAGES) && (
              <PlaybookTabElement name={PlaybookTab.WHATSAPP} icon="whatsapp" {...props} />
            )}
            <PlaybookTabElement name={PlaybookTab.QQS} icon="chatSupport" {...props} />
          </div>
        );
      case Environment.DIALER:
      case Environment.LINKEDIN_TEMPLATE_SELECTOR:
      case Environment.WHATSAPP_TEMPLATE_SELECTOR:
      case Environment.SMS_TEMPLATE_SELECTOR:
      case Environment.META_TEMPLATE_SELECTOR:
        return <></>;
    }
  },
  (prevProps, nextProps) => prevProps.tabSelected === nextProps.tabSelected,
);
