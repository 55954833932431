import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconType, Tooltip } from '@bloobirds-it/flamingo-ui';
import { Environment, PlaybookTab, templateTypes } from '@bloobirds-it/types';
import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';

import styles from '../playbookFeed/playbookFeed.module.css';

export interface PlaybookTabProps {
  selected: PlaybookTab;
  name: PlaybookTab;
  icon: IconType;
  onClick: (tab: PlaybookTab) => void;
  sidePeekEnabled?: boolean;
  dataTest?: string;
  environment?: Environment;
}

export const PlaybookTabElement = (props: PlaybookTabProps) => {
  const { name, icon, onClick, selected, sidePeekEnabled, dataTest, environment } = props;
  const isSelected = selected === name;
  const { t } = useTranslation();

  const isSmallScreen = window.innerWidth < 1440 || window.innerHeight < 840;

  const largeView = useMemo(() => {
    return sidePeekEnabled && !isSmallScreen;
  }, [sidePeekEnabled, isSmallScreen]);

  return (
    <Tooltip
      title={t(`playbook.${name.toLowerCase()}`)}
      position="bottom"
      className={styles.tooltip}
    >
      <div
        className={clsx(styles.tab_container, {
          [styles.tab_container_selected]: isSelected,
        })}
        onClick={() => {
          if (onClick) {
            onClick(name);
            mixpanel.track(`CLICK_ON_${templateTypes[name]}_SECTION_FROM_${environment}`);
          }
        }}
        {...(dataTest ? { 'data-test': dataTest } : {})}
      >
        <Icon name={icon} color={isSelected ? 'white' : 'softPeanut'} size={largeView ? 24 : 20} />
      </div>
    </Tooltip>
  );
};
