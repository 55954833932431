export const PRIVACY_ES = `
# POLÍTICA DE PRIVACIDAD
**Última Actualización:** 1 de enero de 2025

La presente Política de Privacidad describe cómo **BLOOBIRDS, S.L.** (en adelante, “**Bloobirds/Harmonix**” o “**nosotros**”), que opera bajo las marcas Bloobirds y Harmonix, recopila, utiliza y protege los datos personales de los usuarios que acceden y utilizan nuestros servicios. Esta política es aplicable a:

- Nuestras **aplicaciones web** (Web App, Scheduler, Auth y Booking Apps).
- La **extensión de Google Chrome**.
- Nuestras **aplicaciones móviles** (iOS y Android).
- Nuestros **sitios web** de marketing: [www.bloobirds.com](https://www.bloobirds.com).

Para más información sobre nuestra seguridad y cumplimiento, visita nuestros portales de **Trust**:
- [trust.bloobirds.com](https://trust.bloobirds.com)

Al utilizar cualquiera de nuestros servicios, aceptas los términos de esta Política de Privacidad. Si no estás de acuerdo con alguno de los términos, te rogamos que no accedas o utilices nuestros servicios.

---

## 1. IDENTIDAD DEL RESPONSABLE DEL TRATAMIENTO

- **Razón social**: BLOOBIRDS, S.L. (Operando bajo las marcas Bloobirds y Harmonix)
- **NIF**: B67294611
- **Domicilio Social**: Carrer de Lluçà 28, 2º, 08028, Barcelona, España
- **Correo Electrónico**: [privacy@bloobirds.com](mailto:privacy@bloobirds.com)

**BLOOBIRDS, S.L.** se compromete a proteger tu privacidad de conformidad con el **Reglamento (UE) 2016/679 (GDPR)**, la normativa española en materia de protección de datos y cualquier otra legislación local aplicable (incluyendo las de la región LATAM). Asimismo, en el caso del Reino Unido, cumplimos con la normativa equivalente (“**UK GDPR**”), implementada tras el Brexit.

---

## 2. DELEGADO DE PROTECCIÓN DE DATOS (DPO) Y REPRESENTANTE EN EL REINO UNIDO

### 2.1 Delegado de Protección de Datos (DPO)

Nuestro Delegado de Protección de Datos (DPO) es responsable de supervisar el cumplimiento interno de la normativa GDPR/UK GDPR en Bloobirds/Harmonix, así como de asesorar sobre cuestiones relacionadas con la protección de datos y la privacidad.

- **Contacto del DPO**: [privacy@bloobirds.com](mailto:privacy@bloobirds.com)
- **Dirección Postal**: Delegado de Protección de Datos, Bloobirds/Harmonix, Carrer de Lluçà 28, 2º, 08028, Barcelona, España

### 2.2 Representante en el Reino Unido

En cumplimiento de las disposiciones del UK GDPR, contamos con un representante local para aquellas actividades de tratamiento sujetas a la normativa del Reino Unido:

- **Representante en el Reino Unido**: Pridatect UK
- **Email de contacto**: eurepresentation@pridatect.com

Este representante sirve como punto de contacto ante cualquier cuestión relativa al tratamiento de datos personales bajo la ley del Reino Unido y colabora con la autoridad de supervisión competente (ICO) si así fuera necesario.

---

## 3. DATOS PERSONALES QUE RECOPILAMOS

Dependiendo de tu interacción con nosotros, podemos recopilar las siguientes categorías de datos personales:

1. **Datos de contacto y cuenta**: nombre, dirección de correo electrónico, número de teléfono, puesto de trabajo, etc.
2. **Información técnica**: dirección IP, tipo de navegador, sistema operativo, zona horaria, identificadores de dispositivo, URLs visitadas antes y después de utilizar nuestros servicios, etc.
3. **Datos de ubicación**: cuando habilitas servicios de geolocalización en nuestras apps.
4. **Información de uso**: registros de actividad en la web, la extensión de Chrome o las apps móviles.
5. **Contenido facilitado voluntariamente**: comentarios, archivos adjuntos, grabaciones de audio o cualquier otra información que nos proporciones.
6. **Datos de pago (si aplica)**: si adquieres uno de nuestros planes o servicios de pago (p.ej., método de pago, fecha de transacción, etc.).
7. **Integraciones de terceros** (p.ej., Google): en caso de que conectes tu cuenta de Gmail o Google Calendar, únicamente recopilamos la información necesaria para prestar nuestras funcionalidades (ver Sección 10.3).

> **Menores de 14 años**: Nuestros servicios no están dirigidos a menores de 14 años. Si detectamos datos de menores sin el consentimiento paterno/tutor, los eliminaremos de inmediato.

---

## 4. FINALIDADES DEL TRATAMIENTO

En Bloobirds/Harmonix tratamos tus datos personales para:

1. **Prestación de Servicios**:
   - Gestionar tu registro y cuenta de usuario.
   - Ofrecerte las funcionalidades de nuestras aplicaciones (web, móvil, extensión de Chrome).

2. **Mejorar la Experiencia de Usuario**:
   - Analizar patrones de uso, optimizar nuestras herramientas y corregir errores.

3. **Comunicación y Atención al Cliente**:
   - Atender tus solicitudes, responder consultas y notificarte cambios o mejoras relevantes.

4. **Adquisición de Clientes y Marketing**:
   - Envío de newsletters, promociones y otra información comercial, siempre y cuando exista un consentimiento expreso o un interés legítimo.

5. **Cumplimiento de Obligaciones Legales**:
   - Gestionar facturas, contratos y responder a requerimientos de autoridades o tribunales.

6. **Otras Finalidades** que se indiquen en el momento de la recogida de los datos, solicitando tu consentimiento en caso necesario.

---

## 5. BASE LEGAL PARA EL TRATAMIENTO

Las bases jurídicas que permiten el tratamiento de tus datos son:

- **Consentimiento**: cuando aceptas recibir comunicaciones comerciales o te registras en nuestras plataformas.
- **Ejecución de un contrato**: para la prestación de los servicios solicitados.
- **Cumplimiento de obligaciones legales**: cuando la ley nos exige conservar o comunicar datos (p.ej., ante autoridades).
- **Interés legítimo**: para prevenir fraudes, mejorar nuestros servicios o defender derechos legales, garantizando siempre que no se vean menoscabados tus derechos y libertades.

---

## 6. CONSERVACIÓN DE LOS DATOS

Conservaremos tus datos únicamente durante el tiempo necesario para cumplir las finalidades descritas. Posteriormente, se mantendrán bloqueados mientras exista alguna obligación legal de conservación (p.ej., temas fiscales), y transcurrido ese plazo, los eliminaremos de forma segura.

---

## 7. COMUNICACIÓN DE DATOS A TERCEROS

- **Proveedores de Servicios**: Podríamos compartir datos con terceros (por ejemplo, hosting, pasarelas de pago, analítica) bajo contratos que garanticen la confidencialidad y cumplan con GDPR/UK GDPR e ISO 27001.
- **Transferencias Internacionales**:
  - Almacenamos principalmente en la UE, pero utilizamos servicios globales (p.ej., OpenAI en EE. UU.) amparados en [Cláusulas Contractuales Tipo (SCCs)](https://ec.europa.eu/info/law/law-topic/data-protection_en) u otros mecanismos de conformidad con GDPR/UK GDPR.
  - Más detalles disponibles en nuestras [páginas de Trust](https://trust.bloobirds.com).
- **Obligaciones Legales**: Podríamos revelar datos personales a autoridades públicas, cuando la ley lo exija.
- **Grupo Empresarial**: Compartiremos datos dentro de nuestro grupo para fines administrativos internos o para mejorar nuestros productos y servicios.

En ningún caso vendemos tus datos a terceros.

---

## 8. TRANSFERENCIAS DE DATOS FUERA DE LA UE Y DEL REINO UNIDO

Tal como detallamos en nuestras secciones de [Trust Bloobirds](https://trust.bloobirds.com), es posible que procesemos datos con proveedores ubicados fuera del Espacio Económico Europeo o del Reino Unido (p.ej., EE. UU.). En tales casos, aplicamos **Salvaguardas** aprobadas por la Comisión Europea (SCCs) o mecanismos equivalentes reconocidos por la ICO en el Reino Unido.

---

## 9. DERECHOS DE LOS USUARIOS

Tienes derecho a:

1. **Acceder** a tus datos personales.
2. **Rectificar** datos inexactos.
3. **Solicitar la supresión** (derecho al olvido).
4. **Limitar** el tratamiento de tus datos en determinadas circunstancias.
5. **Portar** tus datos a otro responsable (portabilidad).
6. **Oponerte** al tratamiento basado en interés legítimo.
7. **Retirar tu consentimiento** en cualquier momento (sin efecto retroactivo).

Para ejercer estos derechos, escríbenos a:
- **Email**: [privacy@bloobirds.com](mailto:privacy@bloobirds.com)
- **Dirección Postal**: Delegado de Protección de Datos, Bloobirds/Harmonix, Carrer de Lluçà 28, 2º, 08028, Barcelona, España

Si consideras que no hemos atendido adecuadamente tu solicitud, puedes dirigir una reclamación a la **Autoridad de Control** local (p.ej., la AEPD en España, la ICO en Reino Unido, etc.).

---

## 10. POLÍTICAS ESPECÍFICAS PARA DISPOSITIVOS MÓVILES Y USO DE MICRÓFONO

### 10.1 Uso del Micrófono (iOS / Android)
Nuestra app puede solicitar acceso al micrófono para **grabar notas de voz** o **enviar mensajes de audio** dentro de la plataforma:

- **Finalidad**: almacenar esas grabaciones en tu CRM o enviar un mensaje de voz a tus contactos.
- **Ejemplo**: “Registrar interacciones comerciales con notas de audio en la aplicación.”
- **Control**: puedes revocar este permiso en cualquier momento en los ajustes de tu dispositivo.
- **Privacidad**: no accedemos al micrófono sin tu consentimiento ni grabamos audio de manera continua.

### 10.2 Política de Privacidad en Google Play y en la App
Nuestra app en Google Play incluye un enlace a esta Política de Privacidad tanto en su descripción como en el apartado “Ajustes” o “Acerca de” de la propia app.
Enlace: [https://app.bloobirds.com/privacy-policy](https://app.bloobirds.com/privacy-policy)

### 10.3 Integraciones de Terceros (Google APIs)
Si conectas tu cuenta de Gmail o Google Calendar:

- Accedemos a tu correo o calendario **solo** para las funcionalidades de sincronización (p.ej., un “web email client” dentro de Bloobirds/Harmonix).
- No transferimos estos datos a terceros, salvo obligación legal.
- No permitimos que humanos lean tus contenidos sin tu consentimiento explícito, excepto para soporte técnico puntual o por requerimiento legal.
- Cumplimos con las **Google’s Limited Use Requirements**.

---

## 11. REDES SOCIALES

Podemos tener perfiles en redes como Twitter, Instagram, Facebook, YouTube o LinkedIn. El tratamiento de datos de usuarios que interactúen con dichos perfiles (comentarios, me gusta, etc.) se rige por esta política y por las condiciones de cada plataforma. No recopilamos información adicional de tus redes sin tu consentimiento.

---

## 12. MEDIDAS DE SEGURIDAD E ISO 27001

- **ISO 27001**: contamos con certificación que avala nuestros controles de seguridad.
- **Controles de acceso**: cifrado en tránsito y reposo, autenticación, registros de actividad (logs), backups, etc.
- **Auditorías y análisis de vulnerabilidades**: internos y externos periódicos.
- **Formación**: continua a nuestro personal en materia de protección de datos y seguridad.

Para más información sobre nuestras prácticas, visita:
- [trust.bloobirds.com](https://trust.bloobirds.com)

---

## 13. MODIFICACIONES A ESTA POLÍTICA

Podremos actualizar esta Política de Privacidad cuando sea necesario para reflejar cambios normativos o de nuestros servicios. La versión vigente estará siempre disponible en nuestros sitios web. Te recomendamos revisarla periódicamente.

---

## 14. CONTACTO

Para cualquier duda, sugerencia o ejercicio de derechos relativo a esta política, escríbenos a:
- **Correo electrónico**: [privacy@bloobirds.com](mailto:privacy@bloobirds.com)
- **Dirección Postal**: BLOOBIRDS, S.L. – Delegado de Protección de Datos, Carrer de Lluçà 28, 2º, 08028, Barcelona, España

---

## 15. ACEPTACIÓN

Al usar nuestros servicios (aplicaciones web, móviles y la extensión de Chrome o visitar nuestros sitios web), declaras haber leído y entendido los términos de esta Política de Privacidad, consintiendo el tratamiento de tus datos según lo descrito.

---`;

export const PRIVACY_EN = `
# PRIVACY POLICY
**Last Updated:** January 1, 2025

This Privacy Policy describes how **BLOOBIRDS, S.L.** (hereinafter, "**Bloobirds/Harmonix**" or "**we**"), operating under the Bloobirds and Harmonix brands, collects, uses, and protects the personal data of users who access and use our services. This policy applies to:

- Our **web applications** (Web App, Scheduler, Auth, and Booking Apps).
- Our **Google Chrome extension**.
- Our **mobile apps** (iOS and Android).
- Our **marketing websites**: [www.bloobirds.com](https://www.bloobirds.com).

For more information about our security and compliance, please visit our **Trust** portals:
- [trust.bloobirds.com](https://trust.bloobirds.com)

By using any of our services, you agree to the terms of this Privacy Policy. If you do not agree with any part of these terms, please refrain from accessing or using our services.

---

## 1. IDENTITY OF THE DATA CONTROLLER

- **Company Name**: BLOOBIRDS, S.L. (Operating under the Bloobirds and Harmonix brands)
- **NIF**: B67294611
- **Registered Address**: Carrer de Lluçà 28, 2º, 08028, Barcelona, Spain
- **Email**: [privacy@bloobirds.com](mailto:privacy@bloobirds.com)

**BLOOBIRDS, S.L.** is committed to protecting your privacy in accordance with **Regulation (EU) 2016/679 (GDPR)**, Spanish data protection regulations, and any other applicable local legislation (including LATAM). In addition, for the United Kingdom, we comply with the equivalent regulations (“**UK GDPR**”), established after Brexit.

---

## 2. DATA PROTECTION OFFICER (DPO) AND UK REPRESENTATIVE

### 2.1 Data Protection Officer (DPO)

Our Data Protection Officer (DPO) oversees internal compliance with GDPR/UK GDPR at Bloobirds/Harmonix and provides guidance on data protection and privacy matters.

- **DPO Contact**: [privacy@bloobirds.com](mailto:privacy@bloobirds.com)
- **Postal Address**: Data Protection Officer, Bloobirds/Harmonix, Carrer de Lluçà 28, 2º, 08028, Barcelona, Spain

### 2.2 UK Representative

In compliance with the UK GDPR provisions, we have a local representative for those data processing activities subject to UK regulations:

- **UK Representative**: Pridatect UK
- **Email**: eurepresentation@pridatect.com

This representative serves as a point of contact for any questions related to data processing under UK law and may work with the ICO (Information Commissioner’s Office) if needed.

---

## 3. PERSONAL DATA WE COLLECT

Depending on your interaction with us, we may collect the following categories of personal data:

1. **Contact and Account Data**: name, email address, phone number, job title, etc.
2. **Technical Information**: IP address, browser type, operating system, time zone, device identifiers, websites visited before or after using our services, etc.
3. **Location Data**: when you enable location services in our apps.
4. **Usage Information**: activity logs in the web apps, Chrome extension, or mobile apps.
5. **Content You Provide**: comments, attachments, audio recordings, or any other information you choose to share with us.
6. **Payment Data (if applicable)**: if you purchase any of our plans or paid services (e.g., payment method, transaction date, etc.).
7. **Third-Party Integrations** (e.g., Google): if you connect your Gmail or Google Calendar account, we only collect the data needed to provide our features (see Section 10.3).

> **Minors under 14**: Our services are not directed at minors under 14. If we detect personal data of minors without parental/guardian consent, we will delete such data immediately.

---

## 4. PURPOSES OF DATA PROCESSING

At Bloobirds/Harmonix, we process your personal data for the following purposes:

1. **Service Provision**:
   - Manage your registration and user account.
   - Offer the functionalities of our web, mobile apps, and Chrome extension.

2. **Improving User Experience**:
   - Analyze usage patterns, optimize our tools, and fix any bugs.

3. **Communications and Customer Support**:
   - Respond to your inquiries, requests, and notify you about relevant updates or improvements.

4. **Customer Acquisition and Marketing**:
   - Send newsletters, promotions, and commercial information, provided we have your express consent or a legitimate interest.

5. **Compliance with Legal Obligations**:
   - Manage invoices, contracts, and respond to requests from authorities or courts.

6. **Other Purposes** as may be indicated at the time of data collection, requesting your consent if required.

---

## 5. LEGAL BASIS FOR PROCESSING

Our legal bases for processing your data are:

- **Consent**: when you opt in to receive marketing communications or create an account on our platforms.
- **Performance of a Contract**: to provide the requested services.
- **Compliance with Legal Obligations**: when required by law to retain or disclose data (e.g., to authorities).
- **Legitimate Interest**: for fraud prevention, service improvements, or defense of legal claims, ensuring your fundamental rights and freedoms are not overridden.

---

## 6. DATA RETENTION

We retain your data only for as long as necessary to fulfill the purposes described herein. Subsequently, data will be blocked while there is any legal obligation to keep it (e.g., for tax purposes), and after that period, it will be securely deleted.

---

## 7. DATA DISCLOSURE TO THIRD PARTIES

- **Service Providers**: We may share data with third-party providers (e.g., hosting, payment gateways, analytics) under contracts that ensure confidentiality and comply with GDPR/UK GDPR and ISO 27001.
- **International Transfers**:
  - We primarily store data in the EU, but we utilize certain global services (e.g., OpenAI in the US) under [Standard Contractual Clauses (SCCs)](https://ec.europa.eu/info/law/law-topic/data-protection_en) or other legal safeguards recognized by GDPR/UK GDPR.
  - More details can be found on our [Bloobirds Trust page](https://trust.bloobirds.com).
- **Legal Obligations**: We may disclose personal data to public authorities if required by law.
- **Corporate Group**: We may share data within our group of companies for internal administrative purposes or to enhance our products and services.

We never sell your data to third parties.

---

## 8. DATA TRANSFERS OUTSIDE THE EU AND THE UK

As detailed on our [Bloobirds Trust](https://trust.bloobirds.com) pages, we may process personal data with providers located outside the European Economic Area or the United Kingdom (e.g., in the US). In such cases, we rely on **appropriate safeguards** approved by the European Commission (SCCs) or equivalent mechanisms recognized by the UK’s ICO.

---

## 9. USERS’ RIGHTS

You have the following rights concerning your personal data:

1. **Access** your personal data.
2. **Rectify** inaccurate data.
3. **Request Erasure** (right to be forgotten).
4. **Restrict** processing under certain circumstances.
5. **Data Portability** to another controller.
6. **Object** to processing based on legitimate interest.
7. **Withdraw Consent** at any time (without retroactive effect).

To exercise these rights, please contact us at:
- **Email**: [privacy@bloobirds.com](mailto:privacy@bloobirds.com)
- **Postal Address**: Data Protection Officer, Bloobirds/Harmonix, Carrer de Lluçà 28, 2º, 08028, Barcelona, Spain

If you believe we have not adequately addressed your request, you may lodge a complaint with the relevant **Supervisory Authority** (e.g., the AEPD in Spain, the ICO in the UK, etc.).

---

## 10. SPECIFIC POLICIES FOR MOBILE DEVICES AND MICROPHONE USAGE

### 10.1 Microphone Usage (iOS / Android)
Our app may request microphone access to **record voice notes** or **send audio messages** within the platform:

- **Purpose**: to store these recordings in your CRM or send a voice message to your contacts.
- **Example**: “Record commercial interactions with audio notes in the app.”
- **Control**: you can revoke this permission at any time in your device settings.
- **Privacy**: we do not access the microphone without your consent, nor do we record audio continuously.

### 10.2 Privacy Policy on Google Play and Within the App
Our app on Google Play includes a link to this Privacy Policy in its store listing and in an “About” or “Settings” section within the app.
Link: [https://app.bloobirds.com/privacy-policy](https://app.bloobirds.com/privacy-policy)

### 10.3 Third-Party Integrations (Google APIs)
If you connect your Gmail or Google Calendar account:

- We only read, write, or modify your data (emails, metadata, calendars) for the agreed-upon features (e.g., a “web email client”).
- We do not transfer this data to third parties except under legal obligation.
- We do not allow human access to your content without your explicit consent, except for limited technical support or legal requirements.
- We comply with **Google’s Limited Use Requirements**.

---

## 11. SOCIAL MEDIA

We maintain profiles on certain social networks (e.g., Twitter, Instagram, Facebook, YouTube, LinkedIn). The processing of data for users interacting with our profiles (likes, comments, etc.) is governed by both this policy and the terms of each social network. We do not gather additional data from your profiles unless you give us explicit consent.

---

## 12. SECURITY MEASURES AND ISO 27001

- **ISO 27001**: we hold certification confirming our security controls.
- **Access Controls**: data encryption in transit and at rest, secure authentication, logging, backups, etc.
- **Audits and Vulnerability Assessments**: conducted regularly, both internally and externally.
- **Training**: we provide ongoing data protection and security training for our personnel.

For more details on our practices, visit:
- [trust.bloobirds.com](https://trust.bloobirds.com)

---

## 13. POLICY CHANGES

We may update this Privacy Policy when necessary to reflect legal or service changes. The current version will always be available on our websites. We recommend reviewing it periodically.

---

## 14. CONTACT

If you have any questions, suggestions, or would like to exercise your rights relating to this policy, please contact us at:
- **Email**: [privacy@bloobirds.com](mailto:privacy@bloobirds.com)
- **Postal Address**: BLOOBIRDS, S.L. – Data Protection Officer, Carrer de Lluçà 28, 2º, 08028, Barcelona, Spain

---

## 15. ACCEPTANCE

By using our services (web applications, mobile apps, and the Chrome extension) or visiting our websites, you acknowledge having read and understood this Privacy Policy and consent to the processing of your data as described.

---
`;
