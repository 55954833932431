/* jscpd:ignore-start */
import React, { useState } from 'react';

import { useDocumentTitle } from '../../hooks';
import { IconButton } from '@bloobirds-it/flamingo-ui';
import { PRIVACY_EN, PRIVACY_ES } from './policies';
import { MarkdownReader } from '@bloobirds-it/copilot';
import { HarmonixLogo, NewBloobirdsLogo } from '../../../assets/svg';


export const PrivacyPolicy = () => {
  useDocumentTitle('Privacy Policy');
  const [language, setLanguage] = useState<'es' | 'en'>('es');

  const handleToggleLanguage = () => {
    setLanguage((prev) => (prev === 'es' ? 'en' : 'es'));
  };

  const markdownContent = language === 'es' ? PRIVACY_ES : PRIVACY_EN;

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.leftSection}>
          <NewBloobirdsLogo width={160} height={40} />
          <HarmonixLogo height={20} width={140} />
        </div>
        <div style={styles.rightSection}>
          {language === 'es' ? <div style={styles.languageTag}>Español</div> : <div style={styles.languageTag}>English</div>}
          <IconButton
            name="language"
            size={40}
            color="bloobirds"
            ariaLabel="Toggle Language"
            onClick={handleToggleLanguage}
          />
        </div>
      </div>

      <div style={styles.content}>
        <div style={styles.scrollableContent}>
          <MarkdownReader text={markdownContent}></MarkdownReader>
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    width: '100%',
    minHeight: '100vh',
    margin: '0 auto',
    padding: '24px',
    fontFamily: 'Inter, sans-serif',
    background: 'radial-gradient(48.12% 102.5% at 7.38% 59.1%, #D7E6FC 0%, #FFF 100%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  header: {
    width: '100%',
    maxWidth: '80vw',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    boxSizing: 'border-box',
  },
  leftSection: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center'
  },
  rightSection: {
    cursor: 'pointer',
    display: 'flex',
    gap: '8px',
    fontSize: '14px',
    alignItems: 'center'
  },
  content: {
    width: '100%',
    maxWidth: '80vw',
    marginTop: '24px',
    lineHeight: '1.7',
    color: '#333',
    fontSize: '16px',
    padding: '20px',
    boxSizing: 'border-box'
  },
  scrollableContent: {
    maxHeight: '80vh',
    overflowY: 'auto',
    paddingRight: '12px',
    paddingLeft: '12px',
    paddingBottom: '12px'
  },
  languageTag: {
    marginTop: '-14px',
  }
};
/* jscpd:ignore-end */
