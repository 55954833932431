import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useToasts } from '@bloobirds-it/flamingo-ui';
import { useBaseEmailVariableValue } from '@bloobirds-it/hooks';
import {
  AnyObject,
  ELEMENT_PARAGRAPH,
  PlatePlugin,
  PlateProvider,
  TComboboxItem,
} from '@udecode/plate';
import hash from 'object-hash';

import components from './components';
import { createMyPlugins, MyEditor, MyValue } from './config/typescript';
import { PlateWrapper } from './plateWrapper';

let isPasteListenerRegistered = false;

export const initialValue = [
  {
    type: ELEMENT_PARAGRAPH,
    children: [
      {
        text: '',
      },
    ],
  },
];

interface RichTextEditorProps {
  id?: string;
  value?: any;
  defaultValue?: any;
  style?: CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  plugins?: any[];
  children?: (editor: JSX.Element) => React.ReactNode;
  setEditor?: (editor: MyEditor) => void;
  onChange?: (value: any) => void;
  snippets?: TComboboxItem[];
  users?: TComboboxItem[];
  saveSnippetCallback?: (snippet: string) => void;
  registerProps?: any;
  isMarkdown?: boolean;
}

const RichTextEditor = ({
  id,
  value,
  defaultValue = initialValue,
  style,
  onChange,
  placeholder,
  disabled,
  plugins,
  children,
  setEditor,
  snippets,
  users,
  saveSnippetCallback,
  registerProps,
}: RichTextEditorProps) => {
  const isUsingRegister = !!registerProps;
  const [plateId, setPlateId] = useState<string>();
  const oldPlateId = useRef(null);
  const { createToast } = useToasts();
  const { i18n } = useTranslation();

  const emailVariablesValues = useBaseEmailVariableValue();

  const pluginsToUse = useMemo<PlatePlugin<AnyObject, MyValue, MyEditor>[]>(
    () => createMyPlugins(plugins, { components }),
    [hash(plugins)],
  );

  const templateVariableEnabled = pluginsToUse.some(plugin => plugin.key === 'variable');
  const markBalloonEnabled = pluginsToUse.some(plugin => plugin.key === 'basicMarks');

  const handlePaste = (e: ClipboardEvent) => {
    if (
      (e?.target as HTMLElement)
        ?.getAttributeNames()
        ?.some((attr: string) => attr?.includes('slate'))
    ) {
      createToast({
        type: 'warning',
        duration: 15000,
        position: 'bottom-center',
        message: (
          <Trans
            i18nKey="richTextEditor.toasts.carefulOnPaste"
            components={[
              <a
                key="0"
                style={{ cursor: 'pointer' }}
                target="_blank"
                href={
                  i18n?.language === 'es'
                    ? 'https://support.bloobirds.com/hc/es-es/articles/9361828394908-La-gu%C3%ADa-completa-para-solucionar-problemas-de-formato'
                    : 'https://support.bloobirds.com/hc/en-us/articles/9361828394908-A-complete-guide-to-fix-formatting-issues'
                }
                rel="noreferrer"
              ></a>,
            ]}
            values={{
              guide:
                i18n?.language === 'es'
                  ? 'La guía completa para solucionar problemas de formato'
                  : 'A complete guide to fix formatting issues',
            }}
          ></Trans>
        ) as any as string,
      });
    }
  };

  useEffect(() => {
    if (!isPasteListenerRegistered) {
      isPasteListenerRegistered = true;
      document.addEventListener('paste', handlePaste);
    }
    return () => {
      document.removeEventListener('paste', handlePaste);
      isPasteListenerRegistered = false;
    };
  }, []);

  useEffect(() => {
    if (
      templateVariableEnabled &&
      emailVariablesValues &&
      Object.keys(emailVariablesValues).length > 0
    ) {
      if (id) {
        id = `${id}_${hash(emailVariablesValues)}`;
      } else {
        id = hash(emailVariablesValues);
      }
    } else {
      id = id || 'plateEditor';
    }

    setPlateId(id);
  }, [id, templateVariableEnabled, emailVariablesValues]);

  useEffect(() => {
    window.addEventListener('keydown', e => {
      e.stopImmediatePropagation();
    });
    return () => {
      window.removeEventListener('keydown', e => {
        e.stopImmediatePropagation();
      });
    };
  }, []);

  useEffect(() => {
    if (oldPlateId.current !== plateId) {
      oldPlateId.current = plateId;
    }
  }, [plateId]);

  if (!plateId) {
    return null;
  }

  if (plateId !== id && oldPlateId.current && oldPlateId.current !== plateId) {
    return null;
  }

  const PlateEditor = (
    <PlateWrapper
      id={plateId}
      disabled={disabled}
      placeholder={placeholder}
      markBallonEnabled={markBalloonEnabled}
      setEditor={setEditor}
      snippets={snippets}
      users={users}
      style={style}
      saveSnippetCallback={saveSnippetCallback}
    ></PlateWrapper>
  );

  return (
    <PlateProvider<MyValue>
      id={plateId}
      initialValue={defaultValue}
      plugins={pluginsToUse}
      value={value}
      {...(isUsingRegister ? registerProps : {})}
      onChange={
        isUsingRegister
          ? value => {
              const event = { target: { value, name: registerProps?.name } };
              registerProps.onChange(event);
            }
          : onChange
      }
    >
      {children?.(PlateEditor) ?? PlateEditor}
    </PlateProvider>
  );
};

export default RichTextEditor;
