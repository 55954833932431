import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_MAIN_NOTE_VALUES_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  CALL_RESULTS_LOGIC_ROLE,
  DIRECTION_VALUES_LOGIC_ROLE,
} from './activity';
import { BobjectType, BobjectTypes } from './bobjects';

export const magicFilterORs = (bobjectType: BobjectType) => {
  return [
    {
      [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: [
        ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING,
        ACTIVITY_TYPES_VALUES_LOGIC_ROLE.INBOUND,
      ],
    },
    {
      [ACTIVITY_FIELDS_LOGIC_ROLE.ATTACHED_FILES]: '__MATCH_FULL_ROWS__',
      [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL,
    },
    {
      [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: 'ACTIVITY__TYPE__STATUS',
      ...(bobjectType !== BobjectTypes.Opportunity
        ? { [ACTIVITY_FIELDS_LOGIC_ROLE.OPPORTUNITY]: '__MATCH_FULL_ROWS__' }
        : {}),
    },
    {
      [ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION]: DIRECTION_VALUES_LOGIC_ROLE.INCOMING,
      [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL,
    },
    {
      [ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION]: DIRECTION_VALUES_LOGIC_ROLE.OUTGOING,
      [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL,
      [ACTIVITY_FIELDS_LOGIC_ROLE.CALL_RESULT]: CALL_RESULTS_LOGIC_ROLE.CORRECT_CONTACT,
    },
    {
      [ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION]: DIRECTION_VALUES_LOGIC_ROLE.OUTGOING,
      [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL,
      [ACTIVITY_FIELDS_LOGIC_ROLE.CALL_DURATION]: {
        query: {
          gte: 60,
        },
        searchMode: 'RANGE__SEARCH',
      },
    },
    {
      [ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION]: DIRECTION_VALUES_LOGIC_ROLE.INCOMING,
      [ACTIVITY_FIELDS_LOGIC_ROLE.CALL_DURATION]: {
        query: {
          gte: 60,
        },
        searchMode: 'RANGE__SEARCH',
      },
    },
    {
      [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: ACTIVITY_TYPES_VALUES_LOGIC_ROLE.NOTE,
      [ACTIVITY_FIELDS_LOGIC_ROLE.MAIN_NOTE]: ACTIVITY_MAIN_NOTE_VALUES_LOGIC_ROLE.NO,
    },
  ].filter(Boolean);
};

export enum BulkActions {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  RESYNC = 'RESYNC',
  START_CADENCE = 'START_CADENCE',
  STOP_CADENCE = 'STOP_CADENCE',
}

export const enum TypeSearch {
  SEARCH = 'search',
  AGGREGATION = 'aggregation',
}
