import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Callout,
  Icon,
  Input,
  Label,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Switch,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { kebabToCapitalized } from '@bloobirds-it/utils';

import { Checks } from '../playbookFilters/components/sendToMetaBlock';
import styles from './sendToMetaModal.module.css';
import { useSendToMetaModalVisibility } from './useSendToMetaModalVisibility';

enum TemplateVariables {
  companyName = 'company-name',
  leadName = 'lead-name',
  leadStatus = 'lead-status',
  leadFullName = 'lead-full-name',
  leadSurname = 'lead-surname',
  leadJobTitle = 'lead-job-title',
  userName = 'user-name',
  opportunityName = 'opportunity-name',
}

const SuggestedVariablesExamples = {
  [TemplateVariables.companyName]: 'Bloobirds',
  [TemplateVariables.leadName]: 'John',
  [TemplateVariables.leadStatus]: 'Interested',
  [TemplateVariables.leadFullName]: 'John Doe',
  [TemplateVariables.leadSurname]: 'Doe',
  [TemplateVariables.leadJobTitle]: 'Software Engineer',
  [TemplateVariables.userName]: 'Jane Smith',
  [TemplateVariables.opportunityName]: 'Enterprise Plan Subscription',
};

const DefaultVariables = {
  [TemplateVariables.companyName]: '',
  [TemplateVariables.leadName]: '',
  [TemplateVariables.leadStatus]: '',
  [TemplateVariables.leadFullName]: '',
  [TemplateVariables.leadSurname]: '',
  [TemplateVariables.leadJobTitle]: '',
  [TemplateVariables.userName]: '',
  [TemplateVariables.opportunityName]: '',
};

const extractVariablesFromContent = (unSerializedContent: Array<any>): Array<TemplateVariables> => {
  const variableNames: Array<TemplateVariables> = [];

  if (!unSerializedContent && typeof unSerializedContent === 'string') {
    return null;
  }

  unSerializedContent?.forEach(node => {
    if (node?.type === 'variable' && node?.name) {
      const variableName = (
        ['company', 'lead', 'opportunity'].includes(node?.group)
          ? node?.group + '-' + node?.name
          : 'user-' + node.name
      ) as TemplateVariables;
      variableNames.push(variableName);
    }

    if (node?.children && Array.isArray(node.children)) {
      variableNames.push(...extractVariablesFromContent(node.children));
    }
  });

  // Remove duplicates and normalize variable names
  return variableNames.map(name => name.replace(/\s+/g, '-').toLowerCase() as TemplateVariables);
};

export const SendToMetaModal = ({
  data,
  onClose,
  onSubmit,
  unSerializedContent,
}: {
  data: Record<string, any>;
  onClose?: () => void;
  onSubmit: (data: Record<string, any>) => void;
  unSerializedContent: Array<any>;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'playbook.segmentationFilter.sendToMetaModal',
  });
  const { t: checksT } = useTranslation('translation', {
    keyPrefix: 'playbook.segmentationFilter.sendToMetaBlock.checks',
  });
  const { isOpen, closeSendToMetaModal } = useSendToMetaModalVisibility();
  const [exampleVariables, setExampleVariables] = useState<Record<TemplateVariables, string>>();
  const [suggestedInUse, setSuggestedInUse] = useState<boolean>(false);
  const contentVariables = isOpen ? extractVariablesFromContent(unSerializedContent) : [];
  const submitDisabled = !contentVariables?.every(v => exampleVariables?.[v]);

  const applySuggestedVariables = (checked: boolean, usedVariables: Array<TemplateVariables>) => {
    const valuesToApply: Record<TemplateVariables, string> = checked
      ? usedVariables.reduce(
          (acc, variable) => {
            if (SuggestedVariablesExamples[variable]) {
              acc[variable] = SuggestedVariablesExamples[variable];
            }
            return acc;
          },
          {} as Record<TemplateVariables, string>,
        )
      : DefaultVariables;

    setExampleVariables(valuesToApply);
    setSuggestedInUse(checked);
  };

  const submitToMeta = () => {
    const variablesToSent = contentVariables.reduce(
      (acc, variable, idx) => {
        if (exampleVariables[variable]) {
          acc[idx] = exampleVariables[variable];
        }
        return acc;
      },
      {} as Record<TemplateVariables, string>,
    );

    onSubmit?.({
      ...data,
      whatsappMetaValidation: true,
      whatsappTemplateVariables: variablesToSent,
    });

    onClose?.();
    closeSendToMetaModal();
  };

  return isOpen ? (
    <Modal onClose={closeSendToMetaModal} open={isOpen} width={500}>
      <ModalHeader color="white" size="small">
        <ModalTitle color="purple" icon="meta" size="small">
          <Text size="l" weight="bold">
            {t('title')}
          </Text>
        </ModalTitle>
        <ModalCloseIcon color="purple" size="small" onClick={closeSendToMetaModal} />
      </ModalHeader>
      <ModalContent className={styles.content_wrapper}>
        <Text size="s" color="softPeanut">
          {t('subtitle')}
        </Text>
        <div className={styles.sendToMetaCheckWrapper}>
          {Checks.map(({ key, check }) => (
            <div key={key} className={styles.sendToMetaCheck}>
              {check ? (
                <Icon size={18} name="check" color="melon" />
              ) : (
                <Icon size={18} name="cross" color="tomato" />
              )}
              <Text size="s" color="softPeanut">
                {checksT(key)}
              </Text>
            </div>
          ))}
        </div>
        <VariablesBlock
          suggestedInUse={suggestedInUse}
          exampleVariables={exampleVariables}
          addVariableExample={(variableName, variableExample) =>
            setExampleVariables(v => ({ ...v, [variableName]: variableExample }))
          }
          uniqueContentVariables={Array.from(new Set(contentVariables))}
          applySuggestedVariables={applySuggestedVariables}
        />
      </ModalContent>
      <ModalFooter className={styles.footer}>
        <Button
          uppercase={false}
          size="small"
          color={submitDisabled ? 'veryLightPeanut' : 'purple'}
          disabled={submitDisabled}
          onClick={submitToMeta}
        >
          {t('button')}
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;
};

const VariablesBlock = ({
  suggestedInUse,
  uniqueContentVariables,
  exampleVariables,
  addVariableExample,
  applySuggestedVariables,
}: {
  suggestedInUse: boolean;
  exampleVariables: Record<TemplateVariables, string>;
  uniqueContentVariables: Array<TemplateVariables>;
  addVariableExample: (variableName: TemplateVariables, variableExample: string) => void;
  applySuggestedVariables: (checked: boolean, usedVariables: Array<TemplateVariables>) => void;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'playbook.segmentationFilter.sendToMetaModal',
  });

  return (
    <div>
      {uniqueContentVariables?.length > 0 && (
        <div className={styles.variablesWrapper}>
          <Callout icon="info" withoutIcon variant="neutral" width="100%">
            <div className={styles.callout}>
              <Icon name="info" color="softPeanut" size={32} />{' '}
              <Text size="xs">{t('callout')}</Text>
            </div>
          </Callout>
          {uniqueContentVariables.every(v =>
            Object.keys(SuggestedVariablesExamples)?.includes(v),
          ) && (
            <div className={styles.suggestedValues}>
              <Text size="s" color="softPeanut">
                {t('switchText')}
              </Text>
              <div>
                <Switch
                  size="small"
                  color="purple"
                  checked={suggestedInUse}
                  onChange={checked => applySuggestedVariables(checked, uniqueContentVariables)}
                />
              </div>
            </div>
          )}
          <div className={styles.variablesContainer}>
            <div className={styles.variableRow}>
              <Text size="m" weight="bold">
                {t('variablesNameTitle')}
              </Text>
              <Text size="m" weight="bold">
                {t('variablesValueTitle')}
              </Text>
            </div>
            {uniqueContentVariables?.map(variableName => {
              return (
                <VariableRow
                  key={variableName}
                  variableName={variableName}
                  externalValue={exampleVariables?.[variableName]}
                  addVariableExample={addVariableExample}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const VariableRow = ({
  externalValue,
  variableName,
  addVariableExample,
}: {
  externalValue: string;
  variableName: TemplateVariables;
  addVariableExample: (variableName: TemplateVariables, variableExample: string) => void;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'playbook.segmentationFilter.sendToMetaModal',
  });

  return (
    <div className={styles.variableRow}>
      <div>
        <Label
          color="lightBloobirds"
          size="small"
          uppercase={false}
          textColor="bloobirds"
          overrideStyle={{ width: 'fit-content' }}
        >
          {kebabToCapitalized(variableName)}
        </Label>
      </div>
      <Input
        size="small"
        placeholder={t('inputPlaceholder')}
        width="50%"
        value={externalValue}
        onChange={v => {
          addVariableExample(variableName, v);
        }}
      />
    </div>
  );
};
